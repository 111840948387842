import IconSeeMore from "../../../assets/img/connectIcons/see-more.png";
const NoMatchedDataCard = () => {
  return (
    <div
      className="connect-user-card-wrapper font_os"
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "295px",
          flexDirection: "column",
        }}
      >
        <img src={IconSeeMore} alt="see more" />
        <div
          style={{
            marginTop: "12px",
            color: "#25272D",
            textAlign: "center",
            fontSize: "18px",
            fontWeight: "500",
          }}
        >
          No Matched data found!
        </div>
        <div
          style={{
            marginTop: "6px",
            color: "#8F939A",
            textAlign: "center",
            fontSize: "12px",
            lineHeight: "16px",
            fontWeight: "400",
          }}
        >
          Please try different keywords
        </div>
        <div
          style={{
            color: "#8F939A",
            textAlign: "center",
            fontSize: "12px",
            lineHeight: "16px",
            fontWeight: "400",
          }}
        >
          Good luck!
        </div>
      </div>
    </div>
  );
};

export default NoMatchedDataCard;
