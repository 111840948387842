import React, { useState } from "react";
import { LISTING_TYPE, PACKAGE_TYPE } from "../../../types";
import { apiConstants } from "../../../utils/Constants";
import CustomRadio from "../../CustomRadio";
import InfoMessage from "../InfoMessage";

interface PackageTypeSectionProps {
  watch: (name: string) => any;
  setValue: (name: string, value: any) => void;
  disableRadio: any;
}

const PackageTypeSection: React.FC<PackageTypeSectionProps> = ({
  watch,
  setValue,
  disableRadio,
}) => {
  const [isPackageInfoOpen, setIsPackageInfoOpen] = useState(false);

  return (
    watch(apiConstants.listingType) === LISTING_TYPE.M && (
      <div className="mt-4">
        <div className="main-heaidng align-items-start">
          <h6 className="listing_type_heading">
            Add Event
          </h6>
          <img
            src="/static/info.svg"
            alt="info icon"
            className="info_icon"
            onClick={() => setIsPackageInfoOpen(!isPackageInfoOpen)}
          />
        </div>
        <div>
          <div style={{
            fontSize:'14px',
            fontWeight: '400',
            lineHeight: '20px',
            marginBottom:'9px',
          }}>
            Please select if you want to proceed the package creation manually
          </div>
        </div>
        <CustomRadio
          row={false}
          radio_wrap_class="custom_radio_gap mb-2"
          radioData={[
            { label: "Yes", value: PACKAGE_TYPE.M },
            { label: "No", value: PACKAGE_TYPE.S },
          ]}
          name={apiConstants.package_type}
          watch={watch}
          handleSetValue={setValue}
          isDisabled={disableRadio}
        />

        {isPackageInfoOpen && (
          <InfoMessage message="Select 'Yes' if you want to create different events with unique locations or names. Select 'No' if you want to create multiple recurring instances of the same event." />
        )}
      </div>
    )
  );
};

export default PackageTypeSection;
