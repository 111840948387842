import {useEffect, useRef, useState} from "react";
import {scrollToTop, showToast} from "../../../utils";
import {apiConstants} from "../../../utils/Constants";
import {makeGetCall, makeNextPageCall} from "../../../utils/Requests";
import SearchFilter from "../ConnectComponents/SearchFilter";
import NoFlagged from "./NoFlagged";
import ConnectMapContainer from "../ConnectComponents/ConnectMapContainer";
import {CONNECTION_TYPE} from "../ConnectConstants";
import FlaggedUserCard from "../ConnectComponents/FlaggedUserCard";
import useCheckDeviceScreen from "../../../hooks/useCheckScreen";
import NoSearchedFilter from "../ConnectComponents/NoSearchedFilter";
import ConnectFlaggedUserCardShimmer from "../../Reusable/Shimmer/ConnectFlaggedUserCardShimmer";
import LoadOnScroll from "../../Reusable/LoadOnScroll";

const ConnectFlagged = () => {
  const [flaggedConnectionsList, setFlaggedConnectionsList] = useState([]);
  const [search, setSearch] = useState("");
  const [showMap, setShowMap] = useState(false);
  const [serachData, setSearchData] = useState<any>();
  const [isSearchMode, setIsSearchMode] = useState(false);
  const [count, setCount] = useState(0);
  const [totalFlagged, setTotalFlagged] = useState(0);
  const [userCurrentLocation, setUserCurrentLocation] = useState({
    lat: 0,
    long: 0,
  });
  const [limit] = useState<number>(12);
  const [offset, setOffset] = useState<number>(0);
  const [isLoading, setIsLoading] = useState(true);
  const {isMobile} = useCheckDeviceScreen();
  const [nextUrl, setNextUrl] = useState<any>(null);
  const [fetchingMoreData, setFetchingMoreData] = useState(false);
  const lastFetchedUrl = useRef(null);
  const handleClearClick = () => {
    setIsSearchMode(false);
    setSearch("");
    setIsLoading(true);
    setNextUrl(null);
  };
  const handleSuggestionSearch = (value: any) => {
    setSearch(value);
  };

  const handleSearchedSeeAllClick = () => {
    setIsSearchMode(true);
    setIsLoading(true);
    getFlaggedConnections();
  };
  const handleViewToggle = () => {
    setShowMap((prev) => !prev);
  };
  const renderConnectFlaggedUserCardShimmer = (length = 12) =>
    Array.from({length: length}).map((_, index) => (
      <ConnectFlaggedUserCardShimmer key={"000" + index} />
    ));

  const handleLoadMore = () => {
    if (nextUrl) {
      if (nextUrl === lastFetchedUrl.current) {
        // If the nextUrl is the same as the last fetched one, return early
        return;
      }
      // Update the last fetched URL
      lastFetchedUrl.current = nextUrl;

      setFetchingMoreData(true);
      makeNextPageCall({url: nextUrl})
        .then((res) => {
          if (res?.next) {
            setNextUrl(res.next);
          } else {
            setNextUrl(null);
          }
          /*  if(isSearchMode){
              setSearchData((prev) => [...prev, ...res.results]);
            }else{ */
          setFlaggedConnectionsList((prev) => [...prev, ...res.results]);
          // }
          setFetchingMoreData(false);
        })
        .catch((err) => {
          showToast(err, "error");
        });
    }
  };

  const getFlaggedConnections = (
    url = apiConstants.get_flagged_connections
  ) => {
    const params = {limit, offset , version:"v2" };
    makeGetCall({
      url,
      params: search ? {search: search, ...params} : params,
    })
      .then((res) => {
        const data = res.results;
        if (data) {
          setFlaggedConnectionsList(data);
        }
        if (!isSearchMode) {
          setTotalFlagged(res.count);
        } else {
          setCount(res.count);
        }
        if (res?.next) {
          setNextUrl(res.next);
        } else {
          setNextUrl(null);
        }

        setIsLoading(false);
      })
      .catch((err: any) => {
        showToast(err, "error");
      });
  };

  const getSearchedSuggestion = () => {
    const params = {
      point: `${userCurrentLocation.long},${userCurrentLocation.lat}`,
      limit: 8,
      search: search,
      version:"v2"
    };
    makeGetCall({
      url: apiConstants.get_flagged_connections,
      params,
    })
      .then((res) => {
        if (res.status.code === 200) {
          const data = res.results;
          setSearchData(data);
          setNextUrl(null);
          lastFetchedUrl.current = null;
        }
      })
      .catch((err: any) => {
        showToast(err, "error");
      });
  };
  useEffect(() => {
    scrollToTop();
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setUserCurrentLocation({
            lat: position.coords.latitude,
            long: position.coords.longitude,
          });
          // getFlaggedConnections();
        },
        (err) => {
          showToast(
            "We kindly ask for your location to enhance your app experience; however, we regret to inform you that without this information, we are unable to proceed further.",
            "error",
            "connect"
          );
        }
      );
    } else {
      showToast(
        "It looks like geolocation is not available in your browser.",
        "error"
      );
    }
  }, []);

  useEffect(() => {
    if (search !== "") {
      setIsSearchMode(true);
      getSearchedSuggestion();
    }
    if (search === "") {
      setIsSearchMode(false);
      getFlaggedConnections();
    }
  }, [search]);

  return (
    <div>
      {!isMobile && (
        <div className="connect-page-heading font_f">{`Flagged (${totalFlagged})`}</div>
      )}
      {(flaggedConnectionsList.length !== 0 || isSearchMode) && (
        <SearchFilter
          showMap={showMap}
          handleViewToggle={handleViewToggle}
          handleSearchChange={handleSuggestionSearch}
          serachData={serachData}
          handleSeeAll={handleSearchedSeeAllClick}
          handleClearSearch={handleClearClick}
        />
      )}
      {isSearchMode && (
        <div
          style={{
            fontSize: "14px",
            lineHeight: "24px",
            fontWeight: "500",
            margin: "21px 0px 12px 0px",
          }}
        >{`Search results (${count})`}</div>
      )}
      { !showMap ? (
        flaggedConnectionsList?.length === 0 && !isLoading ? (
          isSearchMode ? (
            <NoSearchedFilter searchTerm={search} />
          ) : (
            <NoFlagged />
          )
        ) : (
          <div>
            <div className="content-title"></div>
            <div className="content-style">
              {" "}
              {!isLoading && flaggedConnectionsList.map((connection) => (
                <FlaggedUserCard
                  connectionData={connection}
                  key={connection.id}
                />
              ))}
              {fetchingMoreData &&
                nextUrl &&
                renderConnectFlaggedUserCardShimmer()}
              {isLoading && renderConnectFlaggedUserCardShimmer(8)}
              {!isLoading && !fetchingMoreData && nextUrl && (
                <LoadOnScroll
                  nextUrl={nextUrl}
                  onLoadMore={handleLoadMore}
                  loaderComponent={false}
                />
              )}
            </div>
          </div>
        )
      ) : (
        <div className="inner-contet">
          <div className="content-title"></div>
          <ConnectMapContainer
            eventMap={flaggedConnectionsList}
            latitude={userCurrentLocation.lat}
            longitude={userCurrentLocation.long}
            mode={CONNECTION_TYPE.Flagged}
          />
        </div>
      )}
    </div>
  );
};

export default ConnectFlagged;
