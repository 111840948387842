import { useNavigate } from "react-router-dom";
import IconSeeMore from "../../../assets/img/connectIcons/see-more.png";

const FavSeeMoreCard = () => {
  const navigate = useNavigate();

  return (
    <div className="fav-card-wrap">
      <div
        style={{
          borderRadius: "100%",
          objectFit: "cover",
          border: "4px solid white",
          height: "100px",
          width: "100px",
          boxShadow: "0px 4px 20px 0px #0000001f",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "#FEFEFE",
          color: "white",
          cursor: "pointer",
        }}
        onClick={() => navigate("/connect/favourite")}
      >
        <img src={IconSeeMore} alt="see more" />
      </div>
      <div className="fav-text font_f" onClick={() => navigate("/connect/favourite")}>
        See all
      </div>
    </div>
  );
};

export default FavSeeMoreCard;