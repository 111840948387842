import React, { useState, useEffect } from "react";
import { apiConstants, FILTER_DEFAULT_VALUES, FILTER_PRICE_RANGE, GENDER_TYPE, SORT_FIELD_DATA } from "../../../utils/Constants";
import { useForm } from "react-hook-form";
import SelectInterestButton from "../../AddListingComponent/SelectInterestButton";
import SelectInterestComponentFilter from "../../AddListingComponent/SelectInterestComponent/SelectInterestComponentFilter";
import ApiLoader from "../../../global/ApiLoader";
import useDebounce from "../../../utils/useDebounce";
import Button from "../../../global/Button";
import MultiRangeSlider, { ChangeResult } from "multi-range-slider-react";
import InfiniteScroll from "react-infinite-scroll-component";
import { STRING_CONSTANTS } from "../../../utils/StringConstant";
import GooglePlacesSearchBarFilterNew from "../../../global/GooglePlacesSearchBarFilterNew";
import {
  fetchMoreIntrestList,
  getIntrestList,
  getItemFromStore,
  handleLocation,
  showToast,
} from "../../../utils";
import SingleDatePicker from "../../DatePicker/SingleDatePicker/index.tsx";
import dayjs from "dayjs";
import moment from "moment";
import { DATE_FORMAT } from "../../../constants/helperScheduleDates";

const FilterPopup = (props) => {
  const {
    handleSubmit,
    control,
    watch,
    setValue,
    getValues,
    formState: { errors, dirtyFields },
    reset,
  } = useForm({});
  const {eventFilterParams , setEventFilterParams } = props ;
  const userData = getItemFromStore("userData")?.interest || [];
  const [filterData, setFilterData] = useState({...eventFilterParams});
  const [sortSelected, setSortSelected] = useState(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [searchTerm, setSearchTerm] = useState("");
  const debouncedSearchTerm = useDebounce(searchTerm, 500);
  const [intrestList, setIntrestList] = useState([]);
  const popularity = "popularity"
  const [selectedInterest, setSelectedInterest] = useState<any>(userData);
  const [nextUrl, setNextUrl] = useState(null);
  const [allAgesSelected, setAllAgesSelected] = useState(
    (!eventFilterParams?.minAge && !eventFilterParams?.maxAge) ? false : true
  );
  const [tempInterestIds, setTempInterestIds] = useState([]);
  const [isInterestTabOpen, setIsInterestTabOpen] = useState(false);
  const [locationData, setLocationData] = useState(props?.address || null);

  const handleSortTypeChange = (sortType: string) => {
    setFilterData((prev: any) => {
      if (prev.chipType !== sortType) {
        return { ...prev, chipType: sortType };
      }
      return prev;
    });
  };

  useEffect(() => {
    getIntrestList(
      { search: searchTerm || "", sort: popularity },
      setIntrestList,
      setNextUrl,
      setIsLoading
    );
  }, [debouncedSearchTerm]);

  const handleSetValue = (field: string, value: any) => {
    setValue(field, value, { shouldDirty: true });
  };

  const handleIntrest = (intrest: any) => {
    const tempList = watch(apiConstants.selectedInterest) || [];
    const tempListIds = [...tempInterestIds];
    let index = tempList.findIndex((intr: any) => intr.id === intrest.id);
    if (index > -1) {
      tempList.splice(index, 1);
      tempListIds.splice(index, 1);
    } else {
      tempList.push(intrest);
      tempListIds.push(intrest.id);
    }
    setFilterData((prev: any) => ({
      ...prev,
      interests: [...tempList],
    }));
    handleSetValue(apiConstants.selectedInterest, [...tempList]);
    setTempInterestIds([...tempListIds]);
  };

  const handleIntrestTwo = (levelArray = []) => {
    const tempList = [...levelArray];
    const tempListIds = [];
    tempList?.map((intr, index) => {
      tempListIds.push(intr.id);
    });
    setFilterData((prev: any) => ({
      ...prev,
      interests: [...tempList],
    }));
    handleSetValue(apiConstants.selectedInterest, [...tempList]);
    setTempInterestIds([...tempListIds]);
  };
  const handleAgeSelectionChange = (event: any) => {
    if (event.target.value === "all") {
      setAllAgesSelected(false);
      setFilterData((prev: any) => ({
        ...prev,
        minAge: "",
        maxAge: "",
      }));
    } else if (event.target.value === "specific") {
      setAllAgesSelected(true);
    }
  };
  const handleAgeSelectionChangeOnDiv = (event) => {
    if (event === "all") {
      setAllAgesSelected(false);
      setFilterData((prev: any) => ({
        ...prev,
        minAge: "",
        maxAge: "",
      }));
    } else if (event === "specific") {
      setAllAgesSelected(true);
    }
  };


  
  return (
    <div className={"modal show filter-popup-container"}>
      <div
        className="modal-content v-modal new-schedule_modal handle overflow_web_schedule   max-width-model-set"
        style={{ gap: "30px" }}
      >
        <div className="modal-body">
          {isInterestTabOpen ? (
            <SelectInterestComponentFilter
              interestPicked={filterData?.interests || []}
              handleBackClick={() => setIsInterestTabOpen(false)}
              handleAdd={(value: any) => {
                handleIntrestTwo(value);
                setIsInterestTabOpen(false);
              }}
            />
          ) : (
            <>
              <div
                className="cross-btn"
                onClick={() => {
                  props.onHide();
                  setLocationData(null);
                }}
              >
                <img src="/static/cross_circle.png" alt="" />
              </div>
              <div className="heading font_f">Search by Filter & Sort</div>
              <div className="options-container">
                <div className="options-wrapper">
                  <div
                    className={`${
                      sortSelected ? "" : "active"
                    } selected-option font_f`}
                    onClick={() => {
                      sortSelected && setSortSelected(false);
                    }}
                  >
                    Filter
                  </div>
                  <div
                    className={`${
                      sortSelected ? "active" : ""
                    } selected-option font_f`}
                    onClick={() => {
                      !sortSelected && setSortSelected(true);
                    }}
                  >
                    Sort
                  </div>
                </div>
              </div>
              {sortSelected ? (
                <div className="sort-container">
                  {SORT_FIELD_DATA?.map((field: any) => {
                    return (
                      <div
                        key={field.label}
                        className="sort-option font_os"
                        onClick={() => handleSortTypeChange(field.sortBy)}
                        style={{ cursor: "pointer" }}
                      >
                        <input
                          type="radio"
                          value={field.value}
                          name="event"
                          defaultChecked={field.defaultChecked}
                          checked={
                            filterData?.chipType == field.sortBy ? true : false
                          }
                          onClick={() => handleSortTypeChange(field.sortBy)}
                        />
                        {field.label}
                      </div>
                    );
                  })}
                </div>
              ) : (
                <div className="filter-container">
                  <div className="f-location">
                    <div className="f-loc-title f-title font_f">
                      Filter by location
                    </div>
                    <GooglePlacesSearchBarFilterNew
                      onChangeLocation={(
                        lat: string,
                        lng: string,
                        address: string,
                        address_components: any,
                        e
                      ) => {
                        // eslint-disable-next-line @typescript-eslint/no-unused-vars
                        props.setAddress(address);
                        setLocationData(address);
                        props.setLat(lat);
                        props.setLong(lng);
                        setValue("address", address);
                        setFilterData((prev: any) => ({
                          ...prev,
                          address: address,
                        }));
                      }}
                      setValue={setValue}
                      handleLocation={handleLocation}
                      control={control}
                      errors={errors}
                      name={apiConstants.address}
                      label={"Search location"}
                      placeholder="Include a location"
                      handleFilter={() => {
                        // handleFilter();
                        // setSideListLocation(false);
                      }}
                      onRadiusChange={(radius) => {
                        // setDistance(radius);
                        // Handle the radius value, e.g., update state or trigger other actions
                      }}
                      address={props.address}
                      hidePopup={() => {
                        // setSideListLocation(false)
                      }}
                      handleManualClear={() => {
                        props.setAddress("");
                        setLocationData("");
                        props.setLat(null);
                        props.setLong(null);
                        setFilterData((prev: any) => ({
                          ...prev,
                          address: "",
                        }));
                      }}
                    />
                    {/* </div> */}
                  </div>
                  <div className="f-interest">
                    <SelectInterestButton
                      isOutside={true}
                      handleClick={() => setIsInterestTabOpen(true)}
                      list={filterData?.interests || []}
                      handleRemoveInterest={(id) => {
                        let tempData = filterData?.interests?.filter(
                          (item) => item?.id !== id
                        );
                        setFilterData((prev: any) => ({
                          ...prev,
                          interests: tempData,
                        }));
                        handleSetValue(apiConstants.selectedInterest, tempData);
                        handleIntrestTwo(tempData);
                      }}
                    />
                    <div className="wth-dorpwodn ">
                      {searchTerm && (
                        <div className="data-setup">
                          <div>
                            <div>
                              {" "}
                              <span></span>
                            </div>
                          </div>
                          {isLoading ? (
                            <ApiLoader />
                          ) : intrestList?.length ? (
                            <div>
                              <InfiniteScroll
                                className="infinite_scroll_styles"
                                dataLength={intrestList?.length}
                                next={() =>
                                  fetchMoreIntrestList(
                                    nextUrl,
                                    intrestList,
                                    setNextUrl,
                                    setIntrestList,
                                    setIsLoading
                                  )
                                }
                                hasMore={nextUrl ? true : false}
                                loader={<ApiLoader />}
                              >
                                {intrestList?.map(
                                  (item: any, index: number) => {
                                    return (
                                      <span key={index}>
                                        <label
                                          htmlFor={item.title}
                                          onClick={() => {
                                            handleIntrest(item);
                                          }}
                                          className={
                                            selectedInterest.some(
                                              (intr: any) => intr.id === item.id
                                            )
                                              ? "active"
                                              : ""
                                          }
                                        >
                                          <img
                                            src={
                                              item?.image || "/static/dance.png"
                                            }
                                            alt=""
                                            width={160}
                                            height={140}
                                          />

                                          <h4
                                            className=""
                                            style={{
                                              whiteSpace: "nowrap",
                                              textOverflow: "ellipsis",
                                              width: "140px",
                                              overflow: "hidden",
                                            }}
                                          >
                                            {item.title}{" "}
                                            {watch(
                                              apiConstants.selectedInterest
                                            )?.some(
                                              (intr: any) => intr.id === item.id
                                            ) ? (
                                              <img
                                                className="tick"
                                                src="/static/checked.svg"
                                                alt=""
                                              />
                                            ) : (
                                              ""
                                            )}{" "}
                                          </h4>
                                        </label>
                                      </span>
                                    );
                                  }
                                )}
                              </InfiniteScroll>
                            </div>
                          ) : (
                            <div className="no_data">
                              <img src="/static/Search.svg" alt="" />
                              <h3>{STRING_CONSTANTS.no_result_found}</h3>
                              <p>{STRING_CONSTANTS.please_try_another_term}</p>
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="f-price-range mt-1 ">
                    <div className="f-title font_f">Price Range</div>
                    <div className="f-price-container">
                      <MultiRangeSlider
                        min={FILTER_PRICE_RANGE.MIN}
                        max={FILTER_PRICE_RANGE.MAX}
                        canMinMaxValueSame={true}
                        minValue={filterData?.minPrice}
                        maxValue={
                          !filterData?.maxPrice
                            ? FILTER_PRICE_RANGE.MAX
                            : filterData?.maxPrice
                        }
                        onInput={(e: ChangeResult) => {
                          // Only update if values are different to prevent re-renders
                          if (
                            Number(e?.minValue) !==
                              Number(filterData?.minPrice) ||
                            Number(e?.maxValue) !== Number(filterData?.maxPrice)
                          ) {
                            setFilterData((prev: any) => ({
                              ...prev,
                              minPrice: e?.minValue,
                              maxPrice: e?.maxValue,
                            }));
                          }
                        }}
                        onChange={(e: ChangeResult) => {}}
                        label={false}
                        ruler={false}
                        style={{
                          border: "none",
                          boxShadow: "none",
                          padding: "15px 10px",
                        }}
                        barLeftColor="#DCDCDC"
                        barInnerColor="#EE762F"
                        barRightColor="#DCDCDC"
                        thumbLeftColor="#EE762F"
                        thumbRightColor="#EE762F"
                      />

                      <div className="range-value-container font_os">
                        <div>{`AUD ${filterData?.minPrice}`}</div>
                        <div>
                          <span>{"AUD "}</span>
                          <span>
                            {!filterData?.maxPrice
                              ? FILTER_PRICE_RANGE.MAX
                              : filterData?.maxPrice}
                          </span>
                          <span>
                            {filterData?.maxPrice === FILTER_PRICE_RANGE.MAX
                              ? "+"
                              : ""}
                          </span>
                        </div>
                      </div>
                    </div>
                    {/* commenting below mentioned input as in new requirement told to remove this and keep slider only */}
                    {/* <div className="minmax-price-container">
                      <div className="min-price price-box">
                        <label>Minimum</label>
                        <input
                          placeholder="0"
                          value={filterData?.minPrice}
                          style={{ width: "125px" }}
                          onChange={(e) => {
                            console.log("e?.target.value: ", e?.target.value);
                            console.log(
                              "filterData?.maxPrice: ",
                              filterData?.maxPrice
                            );
                            if (
                              Number(e?.target.value) <
                                Number(filterData?.maxPrice) &&
                              Number(e?.target.value) < 500
                            ) {
                              setFilterData((prev: any) => ({
                                ...prev,
                                minPrice: e?.target.value,
                              }));
                            }
                          }}
                        />
                      </div>
                      <div className="max-price price-box">
                        <label>Maximum</label>
                        <input
                          placeholder="200"
                          value={
                            !filterData?.maxPrice
                              ? FILTER_PRICE_RANGE.MAX
                              : filterData?.maxPrice
                          }
                          onChange={(e) => {
                            if (
                              Number(e?.target.value) >
                                Number(filterData?.minPrice) &&
                              Number(e?.target.value) <= 500
                            ) {
                              setFilterData((prev: any) => ({
                                ...prev,
                                maxPrice: e?.target.value,
                              }));
                            }
                          }}
                          onBlur={(e) => {
                            console.log("blured");
                          }}
                          style={{ width: "125px" }}
                        />
                      </div>
                    </div> */}
                  </div>
                  <div className="f-date mt-1 ">
                    <div
                      className="f-title font_f"
                      style={{
                        marginBottom: "16px",
                      }}
                    >
                      Date
                    </div>
                    <div className="date-container">
                      <SingleDatePicker
                        name={"filter_start_date"}
                        value={filterData?.startDate}
                        label={"Start Date"}
                        onChange={(val) => {
                          console.log("val: ", val);
                          setFilterData((prev: any) => ({
                            ...prev,
                            startDate: val,
                          }));
                          if (filterData?.endDate) {
                            setFilterData((prev: any) => ({
                              ...prev,
                              endDate: null,
                            }));
                          }
                        }}
                        customMinDate={dayjs()}
                      />
                      <SingleDatePicker
                        name={"filter_end_date"}
                        disabled={!filterData?.startDate}
                        value={filterData?.endDate}
                        label={"End Date"}
                        onChange={(val) => {
                          console.log("val: ", val);
                          setFilterData((prev: any) => ({
                            ...prev,
                            endDate: val,
                          }));
                        }}
                        customMinDate={
                          filterData?.startDate
                            ? moment(
                                filterData?.startDate,
                                DATE_FORMAT.DDMMYYYY_FORMAT
                              ).add(1, "day")
                            : moment()
                        }
                      />
                    </div>
                  </div>
                  <div className="f-age mt-1 ">
                    <div className="f-title font_f">Age </div>
                    <div className="age-options-container font_os">
                      <div
                        className="select-option"
                        style={{ cursor: "pointer" }}
                        onClick={() => handleAgeSelectionChangeOnDiv("all")}
                      >
                        <input
                          type="radio"
                          value={"all"}
                          name="age"
                          checked={!allAgesSelected}
                          onChange={handleAgeSelectionChange}
                        />
                        All ages
                      </div>
                      <div
                        className="select-option"
                        style={{ cursor: "pointer" }}
                        onClick={() =>
                          handleAgeSelectionChangeOnDiv("specific")
                        }
                      >
                        <input
                          type="radio"
                          value={"specific"}
                          name="age"
                          checked={allAgesSelected}
                          onChange={handleAgeSelectionChange}
                        />
                        Specific age group
                      </div>
                    </div>

                    {allAgesSelected && (
                      <div className="minmax-age-container font_os">
                        <div className="min-age age-box">
                          <label>Minimum age</label>
                          <input
                            placeholder="Enter min age"
                            className="font_os"
                            value={filterData?.minAge}
                            onChange={(e) => {
                              const value = Number(e.target.value); // Convert the value to a number
                              if (value === 0) {
                                setFilterData((prev: any) => ({
                                  ...prev,
                                  minAge: "", // Assign the numeric value
                                }));
                              } else if (!isNaN(value)) {
                                // Check if it's a valid number
                                setFilterData((prev: any) => ({
                                  ...prev,
                                  minAge: value, // Assign the numeric value
                                }));
                              }
                            }}
                          />
                        </div>
                        <div className="max-age age-box font_os">
                          <label>Maximum age</label>
                          <input
                            placeholder="Enter max age"
                            className="font_os"
                            value={filterData?.maxAge}
                            onChange={(e) => {
                              const value = Number(e.target.value); // Convert the value to a number
                              if (value === 0) {
                                setFilterData((prev: any) => ({
                                  ...prev,
                                  maxAge: "", // Assign the numeric value
                                }));
                              } else if (!isNaN(value)) {
                                // Check if it's a valid number
                                setFilterData((prev: any) => ({
                                  ...prev,
                                  maxAge: value, // Assign the numeric value
                                }));
                              }
                            }}
                          />
                        </div>
                      </div>
                    )}
                    <div></div>
                  </div>
                  <div className="f-gender">
                    <div className="f-title mt-1 mb-2 font_f">Gender</div>
                    <div className="gender-options-container">
                      <div
                        className="select-option font_os"
                        onClick={() =>
                          setFilterData((prev: any) => ({
                            ...prev,
                            gender: GENDER_TYPE.MALE,
                          }))
                        }
                        style={{ cursor: "pointer" }}
                      >
                        <input
                          type="radio"
                          value={"male"}
                          name="gender"
                          checked={
                            filterData?.gender == GENDER_TYPE.MALE
                              ? true
                              : false
                          }
                          onChange={() =>
                            setFilterData((prev: any) => ({
                              ...prev,
                              gender: GENDER_TYPE.MALE,
                            }))
                          }
                        />
                        Male
                      </div>
                      <div
                        className="select-option"
                        onClick={() =>
                          setFilterData((prev: any) => ({
                            ...prev,
                            gender: GENDER_TYPE.FEMALE,
                          }))
                        }
                        style={{ cursor: "pointer" }}
                      >
                        <input
                          type="radio"
                          value={"Female"}
                          name="gender"
                          checked={
                            filterData?.gender == GENDER_TYPE.FEMALE
                              ? true
                              : false
                          }
                          onChange={() =>
                            setFilterData((prev: any) => ({
                              ...prev,
                              gender: GENDER_TYPE.FEMALE,
                            }))
                          }
                        />
                        Female
                      </div>
                      <div
                        className="select-option"
                        onClick={() =>
                          setFilterData((prev: any) => ({
                            ...prev,
                            gender: null,
                          }))
                        }
                        style={{ cursor: "pointer" }}
                      >
                        <input
                          type="radio"
                          value={""}
                          name="gender"
                          checked={!filterData?.gender}
                          onChange={() =>
                            setFilterData((prev: any) => ({
                              ...prev,
                              gender: null,
                            }))
                          }
                        />
                        All Genders
                      </div>
                    </div>
                  </div>
                </div>
              )}
              <div className="apply-btn-container">
                <Button
                  btnTxt="Apply"
                  className="apply-btn font_f"
                  onClick={() => {
                    if (watch("address") && watch("address") !== locationData) {
                      showToast(
                        "Please select location from the suggestion.",
                        "error"
                      );
                      return;
                    } else if (
                      filterData.minAge &&
                      filterData.maxAge &&
                      filterData.minAge > filterData.maxAge
                    ) {
                      showToast(
                        "Maximum age cannot be less than minimum age",
                        "error",
                        "ageValid"
                      );
                      return;
                    } else {
                      props.setCurrentPage(0);
                      props.setInterests([...tempInterestIds]);
                      props.onHide();
                      // props.setIsFilterActive(true);
                      setEventFilterParams(() => filterData);
                      // props.setApplyCount(filterData);
                    }
                  }}
                />
                <div
                  className="clear-filter font_f"
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    props.setCurrentPage(0);
                    props.setInterests([]);
                    props.onHide();
                    // props.setIsFilterActive(false);
                    props.setAddress("");
                    setLocationData(null);
                    setEventFilterParams(() => FILTER_DEFAULT_VALUES);
                    // props.setApplyCount(FILTER_DEFAULT_VALUES);
                  }}
                >
                  Clear Filter
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};
export default React.memo(FilterPopup);
