import { useNavigate } from "react-router-dom";
import IconSeeMore from "../../../assets/img/connectIcons/see-more.png"
const SeeMoreDataCard = ({ path }) => {
  const navigate = useNavigate();
  return (
    <div
      className="connect-user-card-wrapper"
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
      onClick={() => navigate(path)}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "295px",
          flexDirection:"column"
        }}
      >
        <img src={IconSeeMore} alt="see more"/>
        <div
          style={{
            marginTop:"12px",
            color: "#25272D",
            textAlign: "center",
            fontSize: "18px",
            fontWeight: "500",
          }}
          className="font_f"
        >
          Discover More Fitness Mates!
        </div>
        <div
          style={{
            marginTop:"6px",
            color: "#8F939A",
            textAlign: "center",
            fontSize: "12px",
            lineHeight:"16px",
            fontWeight: "400",
          }}
          className="font_os"
        >
          Tap here to explore and 
        </div>
        <div
          style={{
            color: "#8F939A",
            textAlign: "center",
            fontSize: "12px",
            lineHeight:"16px",
            fontWeight: "400",
          }}
          className="font_os"
        >
          view all fitness mates.
        </div>
      </div>
    </div>
  );
};

export default SeeMoreDataCard;
