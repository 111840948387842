/* eslint-disable react-hooks/exhaustive-deps */
import { Fragment, useEffect, useRef, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import ApiLoader from "../../global/ApiLoader";
import FlagUserModal from "../../global/FlagUserModal";
import UserFlaggedModal from "../../global/UserFlaggedModal";
import { USER_TYPE } from "../../types";
import endpoints from "../../utils/endpoints";
import {
  makeGetCall,
  makeNextPageCall,
  makePatchCall,
  makePostCall,
} from "../../utils/Requests";
import { STRING_CONSTANTS } from "../../utils/StringConstant";
import useDebounce from "../../utils/useDebounce";
import GlobalHeader from "../Dashboard/GlobalHeader";
import { ChatListCard } from "./ChatListCard";
import CommonOutlineButton from "../../global/CommonOutlineButton";
import useCheckDeviceScreen from "../../hooks/useCheckScreen";
import GroupDetails from "../Chat/GroupDetails";
import DeleteChatModal from "../../global/DeleteChatModal";
import ExitGroupModal from "../../global/ExitGroupModal";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { getItemFromStore, getTypeOfUser, showToast } from "../../utils";
import CommonCheckboxTwo from "../../global/CommonCheckboxTwo";
import { RightArrowIcon } from "../../utils/Icons/svgs";
import moment from "moment";
import { Room_type } from "../../utils/Constants";
import Button from "../../global/Button";
import { ROUTE_CONSTANTS } from "../../utils/RouteConstants";
import Loader from "../../global/Loader";
import GlobalHeader2 from "../Dashboard/GlobalHeader2";
import LoadOnScroll from "../Reusable/LoadOnScroll";
import ChatCardShimmer from "../Reusable/Shimmer/ChatCardShimmer";

const ChatManagement = () => {
  const [activeIndex, setActiveIndex] = useState<any>(-1);
  const [openMore, setOpenMore] = useState<boolean>(false);
  const [users, setUsers] = useState<any>([]);
  const [searchTerm, setSearchTerm] = useState<any>("");
  const [hasSerachData, setHasSearchData] = useState<boolean>(true);
  const [nextUrl, setNextUrl] = useState<any>(null);
  const [, setIsLoading] = useState<boolean>(false);
  const debouncedSearchTerm = useDebounce(searchTerm, 300);
  const [showConnction, setShowConnction] = useState<boolean>(false);
  const [flagModalOpen, setFlagModalOpen] = useState<boolean>(false);
  const [flaggedOpen, setFlaggedOpen] = useState<boolean>(false);
  const [newChatOpen, setNewChatOpen] = useState<boolean>(false);
  const [showCreateGroup, setShowCreateGroup] = useState<boolean>(false);
  const [groupName, setGroupName] = useState<any>("");
  const [groupMembers, setGroupMembers] = useState<any>([]);
  const [openChat, setOpenChat] = useState<boolean>(false);
  const { isMobile } = useCheckDeviceScreen();
  const [showGroupDetails, setShowGroupDetails] = useState<boolean>(false);
  const [showDeleteChatModal, setShowDeleteChatModal] =
    useState<boolean>(false);
  const [showExitGroupModal, setShowExitGroupModal] = useState<boolean>(false);
  const [chatRooms, setChatRooms] = useState<any>([]);
  const [nextChatRoomUrl, setNextChatRoomUrl] = useState<any>(null);
  const [chats, setChats] = useState<any>([]);
  const [chatsLoading, setChatsLoading] = useState(false);
  const [connectionReq, setConnectionReqs] = useState<any>([]);
  const [searchConnection, setSearchConnection] = useState<any>("");
  const [connectionCount, setConnectionCount] = useState<any>(0);
  const [, setConnectionId] = useState<any>(null);
  const [, setConnectionsList] = useState<any>(null);
  const [searchMyConnection, setSearchMyConnection] = useState([]);
  const [searchMyConnectionParam, setSearchMyConnectionParam] =
    useState<any>("");
  const [nextUrlForMyConnection, setNextUrlForMyConnection] = useState<any>("");
  const [selectedChatDetails, setSelectedChatDetails] = useState<any>(null);

  const [chatRoomId, setChatRoomId] = useState<any>("");
  const [flagUserData, setFlagUserData] = useState<any>("");
  const [chatStatus, setChatStatus] = useState<any>("");
  const [message, setMessage] = useState("");
  const [liveChats, setLiveChats] = useState([]);
  const [nextMsgUrl, setNextMsgUrl] = useState<any>(null);
  const [newchatorGroupId, setNewChatOrGroupId] = useState<any>(null);
  const navigate = useNavigate();
  const ws = useRef<any>();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const listingId = queryParams.get("id");
  const listingName = queryParams.get("name");
  const showChat = queryParams.get("showChat");

  const [isFlaggedByUser, SetIsFlaggedByUser] = useState(false);
  const [isFlagLoading, setIsFlagLoading] = useState(true);
  const lastFetchedUrl = useRef(null);
  const [fetchingMoreData, setFetchingMoreData] = useState(false);
  const [chatRoomsLoading, setChatRoomsLoading] = useState(false);

  useEffect(() => {
    if (showChat) {
      setChatRoomId(showChat);
    }
  }, [showChat]);

  useEffect(() => {
    if (chatRoomId || listingId) {
      ws.current = new WebSocket(
        `${endpoints.webSocketUrl}ws/chat/${chatRoomId}/?user=${JSON.parse(
          localStorage.getItem("access_token")
        )}`
      );
      // Connection opened
      ws.current.onopen = () => {};

      ws.current.onclose = () => {
        setLiveChats([]);
      };

      ws.current.onmessage = (e) => {
        const data = JSON.parse(e.data);
        let newMessage;
        if (data.user === getItemFromStore("userProfile").name) {
          newMessage = { ...data, sentByMe: true };
        } else {
          newMessage = { ...data, sentByMe: false };
        }
        setLiveChats((prev) => [...prev, newMessage]);
      };
    }

    return () => {
      if (ws.current) {
        ws.current.close();
        setLiveChats([]);
      }
    };
  }, [chatRoomId, listingId]);

  const sendMessage = () => {
    if (message.length < 1) {
      return;
    }
    ws.current.send(
      JSON.stringify({
        message: message?.trim(),
      })
    );
    setMessage("");
  };

  const fetchMoreMsg = () => {
    if (!nextMsgUrl) return;

    makeNextPageCall({ url: nextMsgUrl }).then((res) => {
      const tempData = [...chats, ...res.results];
      setNextMsgUrl(res?.next);
      setChats(tempData as never);
    });
  };

  const fetchMoreData = () => {
    if (!nextUrl) return;
    makeNextPageCall({ url: nextUrl }).then((res) => {
      const tempData = [...users, ...res.results];
      setNextUrl(res?.next);
      setUsers(tempData as never);
      if (tempData.length > 0) {
        setHasSearchData(true);
      } else {
        setHasSearchData(false);
      }
    });
  };
  const fetchMoreMyConnection = () => {
    if (!nextUrlForMyConnection) return;
    makeNextPageCall({ url: nextUrlForMyConnection }).then((res) => {
      const tempData = [...searchMyConnection, ...res.results];
      setNextUrlForMyConnection(res?.next);
      setSearchMyConnection(tempData as never);
    });
  };

  const handleSearchInMyConnections = () => {
    let params = { search: searchMyConnectionParam || "" };
    makeGetCall({ url: endpoints.search_connections, params }).then((res) => {
      setSearchMyConnection(res?.results);
      setNextUrlForMyConnection(res?.next || null);
    });
  };

  const handleCreateGroup = () => {
    if (!groupName) {
      showToast("Please enter group name", "error");
      return;
    } else if (groupMembers?.length < 1) {
      showToast("Please select at least one user", "error");
      return;
    } else {
      let apiPayload = {
        room_type: "G",
        users: groupMembers,
        name: groupName,
      };
      makePostCall({ url: endpoints.chat_room, apiPayload })
        .then((res) => {
          if (res.status.code === 200) {
            showToast(res.status.message, "success");
            setChatRoomId(res?.data?.id);
            setNewChatOrGroupId(res?.data?.id);
            getAllChatRooms();
            setNewChatOpen(false);
            setGroupMembers([]);
            setGroupName("");
            setShowCreateGroup(false);
          }
        })
        .catch((err) => showToast(err, "error"));
    }
  };

  const handleCreateGroupListing = (listingId) => {
    let apiPayload = {
      room_type: "L",
      listing: listingId,
    };
    makePostCall({ url: endpoints.chat_room, apiPayload })
      .then((res) => {
        if (res.status.code === 200) {
          setChatRoomId(res?.data?.id);
          setNewChatOrGroupId(res?.data?.id);
          getAllChatRooms();
        }
      })
      .catch((err) => showToast(err, "error"));
  };

  const handleCreateGroupSession = (listingId) => {
    let apiPayload = {
      room_type: "E",
      event: listingId,
    };
    makePostCall({ url: endpoints.chat_room, apiPayload })
      .then((res) => {
        if (res.status.code === 200) {
          setChatRoomId(res?.data?.id);
          setNewChatOrGroupId(res?.data?.id);
          getAllChatRooms();
        }
      })
      .catch((err) => showToast(err, "error"));
  };

  const handleInitiateChat = (usersId, toast = true) => {
    let apiPayload = {
      room_type: "D",
      users: [usersId],
    };

    makePostCall({ url: endpoints.chat_room, apiPayload })
      .then((res) => {
        if (res.status.code === 200) {
          setChatRoomId(res?.data?.id);
          if (toast) {
            showToast(res.status.message, "success");
          }
          getAllChatRooms();
          setNewChatOpen(false);
          navigate("./");

          setNewChatOrGroupId(res?.data?.id);
        } else {
          showToast(res.status.message, "error");
        }
      })
      .catch((err) => showToast(err, "error"));
  };

  const handleExitGroup = (exitRoomId) => {
    makePatchCall({
      url: `${endpoints.exit_chat_room}/${exitRoomId}`,
      apiPayload: { status: "CCM" },
    })
      .then((res) => {
        if (res.status.code === 200) {
          showToast(res?.status?.message, "success");
          setShowExitGroupModal(false);

          setChatStatus(res?.data?.status);
        } else {
          showToast(res?.status?.message, "error");
          setShowExitGroupModal(false);
        }
      })
      .catch((err) => {
        showToast(err, "error");
        setShowExitGroupModal(false);
      });
  };

  useEffect(() => {
    handleSearchInMyConnections();
  }, [searchMyConnectionParam]);

  const fetchMoreChatRoomData = () => {
    if (!nextChatRoomUrl) return;
    makeNextPageCall({ url: nextChatRoomUrl }).then((res) => {
      const tempData = [...chatRooms, ...res.results];
      setChatRooms(tempData);
      setNextChatRoomUrl(res?.next || null);
    });
  };

  const handleChatMessages = (roomId) => {
    setChatsLoading(true);
    makeGetCall({ url: endpoints.chat_room_messages + `${roomId}/` })
      .then((res) => {
        if (res?.status?.code === 200) {
          setChats(res?.results);
          handleMarkAsRead(roomId, res?.results);
          setNextMsgUrl(res?.next || null);
        }
        setChatsLoading(false);
      })
      .catch((err) => {
        setChatsLoading(false);
      });
  };

  const handleMarkAsRead = (roomId, chatData) => {
    let lastMessage = chatData?.length > 0 && chatData[0];
    const messages = lastMessage?.isRead ? null : lastMessage?.id;
    if (messages) {
      makePatchCall({
        url: `${endpoints.chat_room_messages}/${roomId}/`,
        apiPayload: { message: messages },
      })
        .then((res) => {
          if (res.status.code === 200) {
          } else {
            showToast(res?.status?.message, "error");
          }
        })
        .catch((err) => {
          showToast(err, "error");
        });
    }
  };

  const getData = () => {
    let params = { search: searchTerm || "" };
    setIsLoading(true);
    makeGetCall({ url: endpoints.search_connections, params }).then((res) => {
      setUsers(res?.results);
      if (res?.results.length > 0) {
        setHasSearchData(true);
      } else {
        setHasSearchData(false);
      }
      setNextUrl(res?.next || null);
      setIsLoading(false);
    });
  };

  useEffect(() => {
    if (searchTerm) {
      getData();
    } else {
      setUsers([]);
    }
  }, [debouncedSearchTerm]);

  useEffect(() => {
    if (newchatorGroupId) {
      let index = chatRooms.findIndex((p) => p.id === newchatorGroupId);
      if (index >= 0) {
        let item = chatRooms[index];
        handleChatMessages(item?.id);
        setChatRoomId(item?.id);
        setSelectedChatDetails(item);
        setFlagUserData(item?.roomImageDetails?.[0]);
        setChatStatus(item?.status);
        if (isMobile) {
          setOpenChat(true);
        }
      }
    }
  }, [newchatorGroupId, chatRooms]);

  const getAllChatRooms = () => {
    setChatRoomsLoading(true);
    makeGetCall({ url: endpoints.chat_rooms })
      .then((res) => {
        if (res?.status?.code === 200) {
          setChatRooms(res?.results);
          setNextChatRoomUrl(res?.next);
        }
        setChatRoomsLoading(false);
      })
      .catch((err) => {
        showToast(err, "error");
        setChatRoomsLoading(false);
      });
  };

  useEffect(() => {
    getAllChatRooms();
  }, []);

  const getConnectionReq = () => {
    let params = { search: searchConnection || "" };
    makeGetCall({ url: endpoints.pending_connection, params }).then((res) => {
      setConnectionReqs(res?.results);
      setConnectionCount(res.count);
      if (res?.results.length > 0) {
        setHasSearchData(true);
      } else {
        setHasSearchData(false);
      }
      setNextUrl(res?.next || null);
      setIsLoading(false);
    });
  };

  const getUserDetails = (selectedProfile) => {
    if (
      selectedProfile?.roomType === Room_type.D &&
      selectedProfile?.status === "CCM"
    ) {
      setIsFlagLoading(true);
      makeGetCall({
        url: `${endpoints.user_by_id}${selectedProfile?.roomImageDetails?.[0]?.id}`,
      })
        .then((res) => {
          SetIsFlaggedByUser(res.data[0]?.isFlagged);
          setIsFlagLoading(false);
        })
        .catch((err) => {
          setIsFlagLoading(false);
        });
    }
  };

  useEffect(() => {
    getConnectionReq();
  }, [searchConnection]);

  useEffect(() => {
    const showChat = queryParams.get("showChat");
    const urlChatId = queryParams.get("chatId");
    if (showChat) {
      let index = chatRooms.findIndex((p) => p.id === showChat);
      if (index >= 0) {
        let item = chatRooms[index];
        handleChatMessages(item?.id);
        setChatRoomId(item?.id);
        setSelectedChatDetails(item);
        setFlagUserData(item?.roomImageDetails?.[0]);
        setChatStatus(item?.status);
        if (isMobile) {
          setOpenChat(true);
        }
      }
    }
    if (urlChatId) {
      let index = chatRooms.findIndex((p) => p.id === urlChatId);
      if (index >= 0) {
        let item = chatRooms[index];
        handleChatMessages(item?.id);
        setChatRoomId(item?.id);
        setSelectedChatDetails(item);
        setFlagUserData(item?.roomImageDetails?.[0]);
        setChatStatus(item?.status);
        if (isMobile) {
          setOpenChat(true);
        }
      }
    }
  }, [chatRooms]);

  useEffect(() => {
    const urlChatId = queryParams.get("chatId");
    const newUserChat = queryParams.get("userId");
    const newListingId = queryParams.get("id");
    const newSessionId = queryParams.get("sessionId");
    if (urlChatId) {
      let index = chatRooms.findIndex((p) => p.id === urlChatId);
      if (index >= 0) {
        let item = chatRooms[index];
        handleChatMessages(item?.id);
        setChatRoomId(item?.id);
        setSelectedChatDetails(item);
        setFlagUserData(item?.roomImageDetails?.[0]);
        setChatStatus(item?.status);
        if (isMobile) {
          setOpenChat(true);
        }
      }
    } else if (newUserChat) {
      handleInitiateChat(newUserChat, false);
    } else if (newListingId) {
      handleCreateGroupListing(newListingId);
    } else if (newSessionId) {
      handleCreateGroupSession(newSessionId);
    }
  }, []);

  const acceptRequest = (id) => {
    makePatchCall({
      url: `${endpoints.accept_connection_request}/${id}`,
      apiPayload: { status: "A" },
    })
      .then((res) => {
        if (res.status.code === 200) {
          setConnectionId(null);
          getConnectionReq();
          showToast(res?.status?.message, "success");
        } else {
          showToast(res?.status?.message, "error");
        }
      })
      .catch((err) => {
        showToast(err, "error");
      });
  };
  const rejectRequest = (id) => {
    makePatchCall({
      url: `${endpoints.accept_connection_request}/${id}`,
      apiPayload: { status: "R" },
    })
      .then((res) => {
        if (res.status.code === 200) {
          setConnectionId(null);
          getConnectionReq();
          showToast(res?.status?.message, "success");
        } else {
          showToast(res?.status?.message, "error");
        }
      })
      .catch((err) => {
        showToast(err, "error");
      });
  };

  useEffect(() => {
    const getAllConnection = () => {
      makeGetCall({
        url: `${endpoints.chat_connections}`,
      })
        .then((res) => {
          if (res.status.code === 200) {
            setConnectionsList(res.results);
          } else {
            showToast(res?.status?.message, "error");
          }
        })
        .catch((err) => {
          showToast(err, "error");
        });
    };
    getAllConnection();
  }, []);

  function isVideo(data) {
    return /\.(mp4|mov|avi)$/.test(data?.toLowerCase());
  }

  function isValidURL(string) {
    const pattern =
      /^(https?:\/\/)?([\w-]+(\.[\w-]+)+)([\w.,@?^=%&:/~+#-]*[\w@?^=%&/~+#-])?$/;
    return pattern.test(string);
  }
  const handleLoadMore = () => {
    if (nextChatRoomUrl) {
      if (nextChatRoomUrl === lastFetchedUrl.current) {
        // If the nextChatRoomUrl is the same as the last fetched one, return early
        return;
      }
      // Update the last fetched URL
      lastFetchedUrl.current = nextChatRoomUrl;

      setFetchingMoreData(true);
      makeNextPageCall({ url: nextChatRoomUrl })
        .then((res) => {
          if (res?.next) {
            setNextChatRoomUrl(res.next);
          } else {
            setNextChatRoomUrl(null);
          }
          setChatRooms((prev) => [...prev, ...res.results]);
          setFetchingMoreData(false);
        })
        .catch((err) => {
          showToast(err, "error");
        });
    }
  };

  const renderEventsShimmer = () =>
    Array.from({ length: 6 }).map((_, index) => <ChatCardShimmer />);

  return (
    <>
      <GlobalHeader2 />
      <div className="chat-managment-wrap">
        <div className="chat-main-section">
          {showGroupDetails ? (
            <GroupDetails
              openChat={openChat}
              isMobile={isMobile}
              roomId={chatRoomId}
              setShowGroupDetails={setShowGroupDetails}
              setFlagModalOpen={setFlagModalOpen}
              setShowDeleteChatModal={setShowDeleteChatModal}
              setShowExitGroupModal={setShowExitGroupModal}
              flagUserData={flagUserData}
              setFlagUserData={setFlagUserData}
              chatStatus={chatStatus}
            />
          ) : (
            <div
              className={`main-section-left${
                openChat && isMobile ? "-hidden" : ""
              }`}
            >
              <div
                className={
                  !showConnction && !newChatOpen ? "top-section" : "d-none"
                }
              >
                <div className="search-box-wrap">
                  {searchTerm && !showConnction && !newChatOpen ? (
                    <div>
                      <img
                        alt=""
                        src="/static/arrow-back.svg"
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          setSearchTerm("");
                          setUsers([]);
                          setHasSearchData(true);
                        }}
                      />
                    </div>
                  ) : null}
                  {!showConnction && !newChatOpen ? (
                    <div className="form-group">
                      <input
                        type="text"
                        id="search_input"
                        placeholder="Search user"
                        value={searchTerm}
                        onChange={(e) => {
                          setSearchTerm(e.target.value);
                        }}
                      />
                      <label htmlFor="search_input">
                        <img
                          src="/static/search-gray.svg"
                          className="gray-search"
                          alt=""
                        />
                        <img
                          src="/static/Search.svg"
                          className="black-search"
                          alt=""
                        />
                      </label>
                    </div>
                  ) : null}
                  {!searchTerm && !showConnction && !newChatOpen ? (
                    <div>
                      <img
                        alt=""
                        src="/static/edit-chat.svg"
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          setNewChatOpen(true);
                        }}
                      />
                    </div>
                  ) : null}
                </div>

                {!searchTerm && !showConnction && !newChatOpen ? (
                  <>
                    <div className="connection-req-box">
                      <div className="left">New Connection Requests</div>
                      <div
                        className="right"
                        onClick={() => setShowConnction(true)}
                      >
                        View all ({connectionCount})
                      </div>
                    </div>
                    {connectionReq?.length !== 0 ? (
                      <div className="users-img-box-wrap">
                        <div className="content">
                          {connectionReq?.map(({ profilePicture, id }) => {
                            return (
                              <div key={id}>
                                <Link to={`/our-mates/${id}`}>
                                  <img
                                    src={
                                      profilePicture ||
                                      "/static/connect/avatar.svg"
                                    }
                                    alt=""
                                    className="user-img "
                                  />
                                </Link>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    ) : (
                      <div style={{ textAlign: "center" }}>
                        No Connections Request
                      </div>
                    )}
                    <div className="divider"></div>
                  </>
                ) : (
                  <div className="search-list-user">
                    {users.length > 0 ? (
                      <InfiniteScroll
                        dataLength={users?.length}
                        next={fetchMoreData}
                        hasMore={nextUrl ? true : false}
                        loader={<ApiLoader />}
                      >
                        {users.map((item: any, index: number) => {
                          return (
                            <div
                              key={index}
                              className="on-list-user"
                              onClick={() => {
                                handleInitiateChat(item?.id, false);
                                setSearchTerm("");
                                setUsers([]);
                                setHasSearchData(true);
                              }}
                            >
                              <div className="top-part">
                                <div className="img-box">
                                  {item?.profilePictureExtension?.includes(
                                    "video"
                                  ) ? (
                                    <video
                                      className={`user-img`}
                                      autoPlay
                                      playsInline
                                      loop
                                      muted
                                      src={item?.profilePicture}
                                    >
                                      <source src={"" || ""} type="video/mp4" />
                                    </video>
                                  ) : (
                                    <img
                                      alt=""
                                      src={
                                        item?.profilePicture
                                          ? item?.profilePicture
                                          : "/static/connect/avatar.svg"
                                      }
                                      className="user-img"
                                    />
                                  )}
                                </div>
                                <div>
                                  <h6>{item.name}</h6>
                                  <p>
                                    <img
                                      src={
                                        item.userType === USER_TYPE.GUEST
                                          ? "/static/guesticon.svg"
                                          : item.userType ===
                                              USER_TYPE.MEMBER &&
                                            item?.isTrainer
                                          ? "/static/membericon.svg"
                                          : "/static/briefcase.svg"
                                      }
                                      alt=""
                                    />
                                    {/* {item.userType === USER_TYPE.MEMBER &&
                                    item.isTrainer
                                      ? "Trainer"
                                      : item.userType} */}
                                      {getTypeOfUser(item?.userType,item?.isIndividual,item?.isTrainer,item?.isBusiness)}
                                  </p>
                                </div>
                              </div>

                              <img src="/static/arrow-right.svg" alt="" />
                            </div>
                          );
                        })}
                      </InfiniteScroll>
                    ) : !hasSerachData && searchTerm ? (
                      <div style={{ textAlign: "center", marginTop: "60px" }}>
                        <img src="/static/no_search_found.svg" alt="" />
                        <p>
                          {STRING_CONSTANTS.we_apologise_no_search_result}
                          <b style={{ color: "#25272D" }}>"{searchTerm}"</b>.
                        </p>
                      </div>
                    ) : null}
                  </div>
                )}
              </div>

              {showConnction ? (
                <div className="connection-req-popup">
                  <div className="top-section">
                    <div className="back-arrow">
                      <img
                        alt=""
                        src="/static/arrow-back.svg"
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          setShowConnction(false);
                          setShowCreateGroup(false);
                          setSearchConnection("");
                        }}
                      />
                    </div>
                    <div>Connection Requests ({connectionCount})</div>
                  </div>

                  <div className="search-box">
                    <div className="form-group">
                      <input
                        type="text"
                        id="search_input"
                        placeholder="Search from your participants"
                        value={searchConnection}
                        onChange={(e) => {
                          setSearchConnection(e.target.value);
                        }}
                      />
                      <label htmlFor="search_input">
                        <img
                          src="/static/search-gray.svg"
                          className="gray-search"
                          alt=""
                        />
                        <img
                          src="/static/Search.svg"
                          className="black-search"
                          alt=""
                        />
                      </label>
                    </div>
                  </div>
                  <div className="request-list-box">
                    {connectionReq.length > 0 ? (
                      <InfiniteScroll
                        dataLength={users?.length}
                        next={fetchMoreData}
                        hasMore={nextUrl ? true : false}
                        loader={<ApiLoader />}
                      >
                        {connectionReq?.map(
                          ({ profilePicture, id, name, connection }) => {
                            return (
                              <div className="request-card-wrap">
                                <div className="info-box" key={id}>
                                  <Link to={`/our-mates/${id}`}>
                                    <img
                                      src={
                                        profilePicture ||
                                        "/static/connect/avatar.svg"
                                      }
                                      alt=""
                                      className="user-img "
                                    />
                                  </Link>
                                  <div className="name">{name}</div>
                                </div>

                                <div className="button-box">
                                  <span
                                    onClick={() => {
                                      setConnectionId(connection);
                                      rejectRequest(connection);
                                    }}
                                  >
                                    <img
                                      src="/static/cross-btn.svg"
                                      alt=""
                                      className="card-btn"
                                    />
                                  </span>
                                  <span
                                    onClick={() => {
                                      setConnectionId(connection);
                                      acceptRequest(connection);
                                    }}
                                  >
                                    <img
                                      src="/static/tick-btn.svg"
                                      alt=""
                                      className="card-btn"
                                    />
                                  </span>
                                </div>
                              </div>
                            );
                          }
                        )}
                      </InfiniteScroll>
                    ) : (
                      <div style={{ textAlign: "center" }}>
                        No Connections Request
                      </div>
                    )}
                  </div>
                </div>
              ) : null}
              {!searchTerm && !showConnction && !newChatOpen ? (
                <div className="users-chat-list-box">
                  {chatRooms?.length === 0 && !chatRoomsLoading ? (
                    <div style={{ textAlign: "center" }}>
                      No Participants Yet
                    </div>
                  ) : chatRooms?.length === 0 && chatRoomsLoading ? (
                    <div className="d-flex flex-column gap-4 py-3">
                      {renderEventsShimmer()}
                    </div>
                  ) : (
                    <>
                      {chatRooms?.map((item, index) => {
                        return (
                          <ChatListCard
                            index={index}
                            item={item}
                            id={item.id}
                            selectedChatId={chatRoomId}
                            activeIndex={activeIndex}
                            onClick={() => {
                              setActiveIndex(index);
                              handleChatMessages(item?.id);
                              setChatRoomId(item?.id);
                              setSelectedChatDetails(item);
                              setFlagUserData(item?.roomImageDetails?.[0]);
                              setChatStatus(item?.status);
                              if (isMobile) {
                                setOpenChat(true);
                              }
                              setOpenMore(false);
                              setLiveChats([]);

                              getUserDetails(item);
                            }}
                            isActive={true}
                          />
                        );
                      })}
                      {nextChatRoomUrl && !fetchingMoreData && (
                        <LoadOnScroll
                          nextUrl={nextChatRoomUrl}
                          onLoadMore={handleLoadMore}
                          loaderComponent={false}
                        />
                      )}
                      {fetchingMoreData && (
                        <div className="d-flex flex-column gap-4 py-3">
                          {renderEventsShimmer()}
                        </div>
                      )}
                    </>
                  )}
                </div>
              ) : null}

              {/* new chat section */}
              {newChatOpen ? (
                <div className="new-chat-wrap">
                  <div className="top-section">
                    <div className="back-arrow">
                      <img
                        alt=""
                        src="/static/arrow-back.svg"
                        style={{ cursor: "pointer", marginLeft: 10 }}
                        onClick={() => {
                          if (showCreateGroup) {
                            setShowCreateGroup(false);
                          } else {
                            setNewChatOpen(false);
                          }
                        }}
                      />
                    </div>
                    <div>
                      {showCreateGroup ? "Creating New Group" : "New Chat"}
                    </div>
                  </div>
                  {showCreateGroup && (
                    <div className="group-name-field">
                      <img src="/static/GrayPencil.svg" alt="" />
                      <input
                        placeholder="Group Name*"
                        value={groupName}
                        onChange={(e) => {
                          setGroupName(e.target.value);
                        }}
                      />
                    </div>
                  )}

                  {/* --------> for GROUP  CHAT ----------------->*/}
                  {showCreateGroup && (
                    <div className="search-box">
                      <div className="form-group">
                        <input
                          type="text"
                          id="search_input"
                          placeholder="Search"
                          value={searchMyConnectionParam}
                          onChange={(e) => {
                            setSearchMyConnectionParam(e.target.value);
                          }}
                        />
                        <label htmlFor="search_input">
                          <img
                            src="/static/search-gray.svg"
                            className="gray-search"
                            alt=""
                          />
                          <img
                            src="/static/Search.svg"
                            className="black-search"
                            alt=""
                          />
                        </label>
                      </div>
                    </div>
                  )}

                  {/* --------> for single initiate chat ----------------->*/}

                  {!showCreateGroup && (
                    <div className="search-box">
                      <div className="form-group">
                        <input
                          type="text"
                          id="search_input"
                          placeholder="Search"
                          value={searchMyConnectionParam}
                          onChange={(e) => {
                            setSearchMyConnectionParam(e.target.value);
                          }}
                        />
                        <label htmlFor="search_input">
                          <img
                            src="/static/search-gray.svg"
                            className="gray-search"
                            alt=""
                          />
                          <img
                            src="/static/Search.svg"
                            className="black-search"
                            alt=""
                          />
                        </label>
                      </div>
                    </div>
                  )}

                  {!showCreateGroup && (
                    <div
                      className="new-chat-box"
                      onClick={() => setShowCreateGroup(true)}
                    >
                      <div className="left">
                        <span className="icon">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                          >
                            <path
                              d="M14.75 15C15.7165 15 16.5 15.7835 16.5 16.75L16.4989 17.7121C16.6156 19.9012 14.9879 21.009 12.0668 21.009C9.15786 21.009 7.5 19.9192 7.5 17.75V16.75C7.5 15.7835 8.2835 15 9.25 15H14.75ZM3.75 10L8.126 10.0001C8.04375 10.3197 8 10.6547 8 11C8 12.1155 8.45665 13.1244 9.1932 13.8499L9.35526 14.001L9.25 14C8.94865 14 8.65863 14.0485 8.38729 14.138C7.52255 14.4235 6.84765 15.1264 6.60122 16.0082L6.56679 16.009C3.65786 16.009 2 14.9192 2 12.75V11.75C2 10.7835 2.7835 10 3.75 10ZM20.25 10C21.2165 10 22 10.7835 22 11.75L21.9989 12.7121C22.1156 14.9012 20.4879 16.009 17.5668 16.009L17.3985 16.0073C17.1596 15.1534 16.5188 14.4673 15.6929 14.1659C15.4576 14.08 15.2073 14.0254 14.947 14.0069L14.75 14L14.6447 14.001C15.4758 13.268 16 12.1952 16 11C16 10.6547 15.9563 10.3197 15.874 10.0001L20.25 10ZM12 8C13.6569 8 15 9.34315 15 11C15 12.6569 13.6569 14 12 14C10.3431 14 9 12.6569 9 11C9 9.34315 10.3431 8 12 8ZM6.5 3C8.15685 3 9.5 4.34315 9.5 6C9.5 7.65685 8.15685 9 6.5 9C4.84315 9 3.5 7.65685 3.5 6C3.5 4.34315 4.84315 3 6.5 3ZM17.5 3C19.1569 3 20.5 4.34315 20.5 6C20.5 7.65685 19.1569 9 17.5 9C15.8431 9 14.5 7.65685 14.5 6C14.5 4.34315 15.8431 3 17.5 3Z"
                              fill="#8F939A"
                            />
                          </svg>
                        </span>
                        <span>Start a group chat</span>
                      </div>
                      <div className="right">
                        <span className="open-group-chat">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                          >
                            <path
                              d="M8.29289 19.7071C7.90237 19.3166 7.90237 18.6834 8.29289 18.2929L14.5858 12L8.29289 5.70711C7.90237 5.31658 7.90237 4.68342 8.29289 4.29289C8.68342 3.90237 9.31658 3.90237 9.70711 4.29289L16.7071 11.2929C17.0976 11.6834 17.0976 12.3166 16.7071 12.7071L9.70711 19.7071C9.31658 20.0976 8.68342 20.0976 8.29289 19.7071Z"
                              fill="#8F939A"
                            />
                          </svg>
                        </span>
                      </div>
                    </div>
                  )}

                  {/* --------> for single initiate chat ----------------->*/}

                  {newChatOpen && !showCreateGroup && (
                    <div className="my-connections-wrap">
                      <div className="heading">My Connections</div>
                      <div className="user-list-box">
                        {searchMyConnection.length > 0 ? (
                          <InfiniteScroll
                            dataLength={searchConnection.length}
                            next={fetchMoreMyConnection}
                            hasMore={nextUrlForMyConnection ? true : false}
                            loader={<ApiLoader />}
                          >
                            {searchMyConnection?.map((item) => {
                              return (
                                <div
                                  className="user-card"
                                  onClick={() => {
                                    handleInitiateChat(item?.id);
                                  }}
                                  key={item?.id}
                                >
                                  <div>
                                    {item?.profilePictureExtension?.includes(
                                      "video"
                                    ) ? (
                                      <video
                                        className={`user-img`}
                                        autoPlay
                                        playsInline
                                        loop
                                        muted
                                        src={item?.profilePicture}
                                      >
                                        <source
                                          src={"" || ""}
                                          type="video/mp4"
                                        />
                                      </video>
                                    ) : (
                                      <img
                                        alt=""
                                        src={
                                          item?.profilePicture
                                            ? item?.profilePicture
                                            : "/static/connect/avatar.svg"
                                        }
                                        className="user-img"
                                      />
                                    )}
                                    <span>{item?.name}</span>
                                  </div>
                                  {/* {selectedOptions.includes(id) && (
                                <CommonCheckbox
                                  id={id}
                                  value={id}
                                  checked={selectedOptions.includes(id)}
                                  onChange={handleCheckboxChange}
                                />
                              )} */}
                                  <RightArrowIcon fill="#F3F3F3" />
                                </div>
                              );
                            })}
                          </InfiniteScroll>
                        ) : (
                          <div
                            style={{ textAlign: "center", marginTop: "60px" }}
                          >
                            <img src="/static/no_search_found.svg" alt="" />
                            <p>
                              {STRING_CONSTANTS.we_apologise_no_search_result}
                              <b style={{ color: "#25272D" }}>
                                "{searchMyConnectionParam}"
                              </b>
                              .
                            </p>
                          </div>
                        )}
                      </div>
                    </div>
                  )}

                  {/* --------> for Group chat ----------------->*/}

                  {showCreateGroup && (
                    <div className="my-connections-wrap">
                      <div className="heading">My Connections</div>
                      <div className="user-list-box">
                        {searchMyConnection.length > 0 ? (
                          <InfiniteScroll
                            dataLength={searchConnection.length}
                            next={fetchMoreMyConnection}
                            hasMore={nextUrlForMyConnection ? true : false}
                            loader={<ApiLoader />}
                          >
                            {searchMyConnection.map((item, index) => {
                              return (
                                <div
                                  key={item?.id}
                                  className="user-card"
                                  onClick={() => {
                                    setOpenChat(true);
                                  }}
                                >
                                  <div>
                                    {item?.profilePictureExtension?.includes(
                                      "video"
                                    ) ? (
                                      <video
                                        className={`user-img`}
                                        autoPlay
                                        playsInline
                                        loop
                                        muted
                                        src={item?.profilePicture}
                                      >
                                        <source
                                          src={"" || ""}
                                          type="video/mp4"
                                        />
                                      </video>
                                    ) : (
                                      <img
                                        alt=""
                                        src={
                                          item?.profilePicture
                                            ? item?.profilePicture
                                            : "/static/connect/avatar.svg"
                                        }
                                        className="user-img"
                                      />
                                    )}
                                    {/* {item?.profilePicture ? <img alt="" src={item?.profilePicture} /> : <img alt="" src="/static/connect/avatar.svg" />} */}

                                    <span>{item?.name}</span>
                                  </div>
                                  <CommonCheckboxTwo
                                    checked={groupMembers.includes(item?.id)}
                                    icon="/static/OrangeRoundCheck.svg"
                                    onClick={() => {
                                      if (groupMembers.includes(item?.id)) {
                                        const filtered = groupMembers.filter(
                                          (id) => id !== item.id
                                        );
                                        setGroupMembers([...filtered]);
                                      } else {
                                        setGroupMembers([
                                          ...groupMembers,
                                          item?.id,
                                        ]);
                                      }
                                    }}
                                  />
                                </div>
                              );
                            })}
                          </InfiniteScroll>
                        ) : (
                          <div
                            style={{ textAlign: "center", marginTop: "60px" }}
                          >
                            <img src="/static/no_search_found.svg" alt="" />
                            <p>
                              {STRING_CONSTANTS.we_apologise_no_search_result}
                              <b style={{ color: "#25272D" }}>
                                "{searchMyConnectionParam}"
                              </b>
                              .
                            </p>
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                  {showCreateGroup && (
                    <div
                      className="create-group-button-container"
                      onClick={handleCreateGroup}
                    >
                      <CommonOutlineButton
                        className={
                          groupName && groupMembers.length
                            ? "common_btn_orange"
                            : "common_btn_grey"
                        }
                        text="Create Group"
                        width="360px"
                        height="52px"
                      />
                    </div>
                  )}
                </div>
              ) : null}
            </div>
          )}

          <div
            className={`main-section-right${
              isMobile && !openChat ? "-hidden" : ""
            }`}
          >
            {!chatRoomId && listingId && listingName && !showChat ? (
              <div className="listing-screen">
                <div className="nochat-wrap-listing">
                  <img alt="" src="/static/no-chat.svg" />
                  <div className="title">No chat yet</div>
                  <div className="desc">{listingName}</div>
                </div>
                <div className="chat-btn-wrapper">
                  <Button
                    btnTxt={`Initiate chat`}
                    className={" chatl-btn"}
                    onClick={() => {
                      handleCreateGroupListing(listingId);
                    }}
                  />
                </div>
              </div>
            ) : !chatRoomId ? (
              <div className="nochat-wrap">
                <img alt="" src="/static/no-chat.svg" />
                <div className="title">No chat yet</div>
                <div className="desc">
                  Ready to start a chat? Begin by selecting a contact or
                  initiating a new chat to connect with fitness buddies.
                </div>
              </div>
            ) : (
              <div className="chatting-screen">
                <div className="top-section">
                  <div className="left">
                    {isMobile && (
                      <div className="back-arrow">
                        <img
                          alt=""
                          src="/static/arrow-back.svg"
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            setOpenChat(false);
                          }}
                        />
                      </div>
                    )}
                    {selectedChatDetails?.roomType === Room_type.G ? (
                      <div
                        className="group-chat-wrap"
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          setShowGroupDetails(true);
                        }}
                      >
                        {selectedChatDetails?.roomImageDetails?.map(
                          (it, index) => {
                            return (
                              <img
                                alt=""
                                src={
                                  it?.media ||
                                  it?.profilePicture ||
                                  "/static/connect/avatar.svg"
                                }
                                className={`user-img-${index + 1}`}
                              />
                            );
                          }
                        )}
                      </div>
                    ) : selectedChatDetails?.roomType === Room_type.L ||
                      selectedChatDetails?.roomType === Room_type.E ? (
                      <img
                        alt=""
                        src={
                          selectedChatDetails?.roomImageDetails?.[0]?.media ||
                          "/static/connect/avatar.svg"
                        }
                        className="user-img"
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          setShowGroupDetails(true);
                        }}
                      />
                    ) : (
                      <img
                        alt=""
                        src={selectedChatDetails?.roomImageDetails?.[0]?.media || selectedChatDetails?.roomImageDetails?.[0]?.profilePicture || "/static/connect/avatar.svg"}
                        className="user-img"
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          navigate(
                            `/our-mates/${selectedChatDetails?.roomImageDetails?.[0]?.id}`
                          );
                        }}
                      />
                    )}
                    <div className="name">{selectedChatDetails?.name}</div>
                  </div>
                  <div className="right">
                    <div
                      onClick={() => setOpenMore(!openMore)}
                      className="more-icon"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                      >
                        <path
                          d="M12 7.2002C11.1716 7.2002 10.5 6.52862 10.5 5.7002C10.5 4.87177 11.1716 4.2002 12 4.2002C12.8284 4.2002 13.5 4.87177 13.5 5.7002C13.5 6.52862 12.8284 7.2002 12 7.2002ZM12 13.5002C11.1716 13.5002 10.5 12.8286 10.5 12.0002C10.5 11.1718 11.1716 10.5002 12 10.5002C12.8284 10.5002 13.5 11.1718 13.5 12.0002C13.5 12.8286 12.8284 13.5002 12 13.5002ZM10.5 18.3002C10.5 19.1286 11.1716 19.8002 12 19.8002C12.8284 19.8002 13.5 19.1286 13.5 18.3002C13.5 17.4718 12.8284 16.8002 12 16.8002C11.1716 16.8002 10.5 17.4718 10.5 18.3002Z"
                          fill="#0C1C3B"
                        />
                      </svg>
                    </div>
                    {openMore ? (
                      <div className="more-popup-item-wrap">
                        {selectedChatDetails?.roomType === Room_type.G ||
                        selectedChatDetails?.roomType === Room_type.L ||
                        selectedChatDetails?.roomType === Room_type.E ? (
                          <>
                            <div
                              onClick={() => {
                                setOpenMore(!openMore);
                                setShowGroupDetails(true);
                              }}
                              className="title"
                            >
                              Group details
                            </div>
                            {/* <div
                                className="title"
                                onClick={() => setShowDeleteChatModal(true)}
                              >
                                Delete chats
                              </div> */}
                            {!(
                              chatStatus === "CCM" ||
                              selectedChatDetails?.status === "CCM"
                            ) && (
                              <div
                                className="title"
                                onClick={() => setShowExitGroupModal(true)}
                              >
                                Exit group
                              </div>
                            )}
                          </>
                        ) : (
                          <>
                            <div
                              className="title"
                              onClick={() => {
                                navigate(
                                  `/our-mates/${selectedChatDetails?.roomImageDetails?.[0]?.id}`
                                );
                              }}
                            >
                              View Profile
                            </div>
                            {selectedChatDetails?.status !== "CCM" && (
                              <div
                                className="title"
                                onClick={() => setFlagModalOpen(true)}
                              >
                                Flag user
                              </div>
                            )}
                          </>
                        )}
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
                {chats?.length !== 0 || liveChats.length !== 0 ? (
                  <div className="message-box">
                    {/* <div id="scrollableDiv1"> */}
                    <div
                      id="scrollableDiv"
                      style={{
                        padding: "20px",
                        height: "100%",
                        overflow: "auto",
                        display: "flex",
                        flexDirection: "column-reverse",
                      }}
                    >
                      {chatsLoading ? (
                        <ApiLoader />
                      ) : (
                        <InfiniteScroll
                          dataLength={chats?.length + liveChats?.length - 2}
                          next={fetchMoreMsg}
                          hasMore={nextMsgUrl ? true : false}
                          style={{
                            display: "flex",
                            flexDirection: "column-reverse",
                            scrollBehavior: "smooth",
                          }}
                          inverse={true}
                          scrollableTarget="scrollableDiv"
                          loader={
                            <div
                              style={{
                                width: "100%",
                                display: "flex",
                                justifyContent: "center",
                              }}
                            >
                              <ApiLoader />
                            </div>
                          }
                        >
                          {(chatStatus !== "CCM" ||
                            selectedChatDetails.status !== "CCM") &&
                            liveChats?.toReversed()?.map((item, index) => {
                              return !item?.sentByMe ? (
                                <div className="recived-message-box-wrap">
                                  {/* map message here */}

                                  <Fragment key={index}>
                                    {isValidURL(item?.message) ? (
                                      <a
                                        href={item?.message}
                                        target="_blank"
                                        className="recived-message-box"
                                        rel="noreferrer"
                                      >
                                        {item?.message}
                                      </a>
                                    ) : (
                                      <div className="recived-message-box">
                                        {item?.message}
                                      </div>
                                    )}
                                    <div className="msg-info">
                                      {item?.profile_picture?.includes(
                                        "video"
                                      ) ? (
                                        <video
                                          className="seen-img"
                                          autoPlay
                                          playsInline
                                          loop
                                          muted
                                          src={item?.profile_picture}
                                        >
                                          <source
                                            src={"" || ""}
                                            type="video/mp4"
                                          />
                                        </video>
                                      ) : (
                                        <img
                                          alt=""
                                          src={
                                            item?.profile_picture
                                              ? item?.profile_picture
                                              : "/static/connect/avatar.svg"
                                          }
                                          className="seen-img"
                                        />
                                      )}

                                      <span className="msg-time">
                                        {item?.created_at
                                          ? moment(item?.created_at).format(
                                              "hh:mm A"
                                            )
                                          : ""}
                                      </span>
                                    </div>
                                  </Fragment>
                                </div>
                              ) : (
                                <div className="sent-message-box-wrap">
                                  {isValidURL(item?.message) ? (
                                    <a
                                      href={item?.message}
                                      target="_blank"
                                      className="sent-message-box"
                                      rel="noreferrer"
                                    >
                                      {item?.message}
                                    </a>
                                  ) : (
                                    <div className="sent-message-box">
                                      {item?.message}
                                    </div>
                                  )}
                                  <span className="msg-time">
                                    {item?.created_at
                                      ? moment(item?.created_at).format(
                                          "hh:mm A"
                                        )
                                      : ""}
                                  </span>
                                </div>
                              );
                            })}

                          {chats?.map((item, index) => {
                            return item?.sender?.id !==
                              getItemFromStore("userProfile")?.id ? (
                              <div className="recived-message-box-wrap">
                                {/* map message here */}

                                <Fragment key={index}>
                                  {isValidURL(item?.message) ? (
                                    <a
                                      href={item?.message}
                                      target="_blank"
                                      className="recived-message-box"
                                      rel="noreferrer"
                                    >
                                      {item?.message}
                                    </a>
                                  ) : (
                                    <div className="recived-message-box">
                                      {item?.message}
                                    </div>
                                  )}
                                  <div className="msg-info">
                                    {item?.sender?.profilePictureExtension?.includes(
                                      "video"
                                    ) ? (
                                      <video
                                        className="seen-img"
                                        autoPlay
                                        playsInline
                                        loop
                                        muted
                                        src={item?.sender?.profilePicture}
                                      >
                                        <source
                                          src={"" || ""}
                                          type="video/mp4"
                                        />
                                      </video>
                                    ) : item?.sender?.profilePicture ? (
                                      <img
                                        alt=""
                                        src={
                                          item?.sender?.profilePicture
                                            ? item?.sender?.profilePicture
                                            : "/static/connect/avatar.svg"
                                        }
                                        className="seen-img"
                                      />
                                    ) : (
                                      <div className="initial-name-wrap">
                                        {(item?.sender?.name).slice(0, 2)}
                                      </div>
                                    )}

                                    <span className="msg-time">
                                      {item?.createdAt
                                        ? moment(item?.createdAt, [
                                            "HH:mm",
                                          ]).format("hh:mm A")
                                        : ""}
                                    </span>
                                  </div>
                                </Fragment>
                              </div>
                            ) : (
                              <div className="sent-message-box-wrap">
                                {isValidURL(item?.message) ? (
                                  <a
                                    href={item?.message}
                                    target="_blank"
                                    className="sent-message-box"
                                    rel="noreferrer"
                                  >
                                    {item?.message}
                                  </a>
                                ) : (
                                  <div className="sent-message-box">
                                    {item?.message}
                                  </div>
                                )}
                                <span className="msg-time">
                                  {item?.createdAt
                                    ? moment(item?.createdAt, ["HH:mm"]).format(
                                        "hh:mm A"
                                      )
                                    : ""}
                                </span>
                              </div>
                            );
                          })}
                        </InfiniteScroll>
                      )}
                    </div>
                  </div>
                ) : (
                  <div className="nochat-wrap">
                    <img alt="" src="/static/no-chat.svg" />
                    <div className="title">No Active Messages</div>
                  </div>
                )}

                {(selectedChatDetails?.roomType === Room_type.G ||
                  selectedChatDetails?.roomType === Room_type.L) &&
                (chatStatus === "CCM" ||
                  selectedChatDetails?.status === "CCM") ? (
                  <div className="exit-group">
                    <p>
                      You can’t send messages to this group because you’re no
                      longer a lister.
                    </p>
                  </div>
                ) : selectedChatDetails?.roomType === Room_type.D &&
                  (chatStatus === "CCM" ||
                    selectedChatDetails?.status === "CCM") ? (
                  <div className="exit-group">
                    {isFlagLoading ? (
                      <Loader />
                    ) : isFlaggedByUser === false ? (
                      <p>
                        You cannot send messages to this user because they have
                        flagged you
                      </p>
                    ) : (
                      <p>
                        You cannot send messages to this user because you have
                        flagged them
                      </p>
                    )}
                  </div>
                ) : (
                  <div className="typing-box">
                    <div className="typing-box-outer">
                      <div className="form-group">
                        <input
                          type="text"
                          id="search_input"
                          placeholder="Write a message"
                          value={message}
                          onChange={(e) => {
                            if (e.target?.value?.length < 500) {
                              setMessage(e.target.value);
                            } else {
                              setMessage(e.target.value?.slice(0, 500));
                            }
                          }}
                          onKeyDown={(e) => {
                            if (e.key === "Enter") {
                              sendMessage();
                            }
                          }}
                        />
                        <label htmlFor="message_input"></label>
                      </div>
                      <div>
                        <img
                          alt=""
                          src="/static/send-btn.svg"
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            sendMessage();
                          }}
                        />
                      </div>
                    </div>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </div>

      {flagModalOpen ? (
        <FlagUserModal
          setShowFlagUser={() => setFlagModalOpen(false)}
          onCancel={() => setFlagModalOpen(false)}
          onOk={() => setFlaggedOpen(true)}
          userProfile={flagUserData}
        />
      ) : null}

      {flaggedOpen ? (
        <UserFlaggedModal onCancel={() => setFlaggedOpen(false)} />
      ) : null}
      {showDeleteChatModal && (
        <DeleteChatModal
          onOk={() => {}}
          onCancel={() => setShowDeleteChatModal(false)}
        />
      )}
      {showExitGroupModal && (
        <ExitGroupModal
          onOk={() => {
            handleExitGroup(chatRoomId);
          }}
          onCancel={() => {
            setShowExitGroupModal(false);
          }}
        />
      )}
    </>
  );
};

export default ChatManagement;
