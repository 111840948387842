import { Fragment, useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import ApiLoader from "../../../global/ApiLoader";
import Button from "../../../global/Button";
import {
  daysToYearsMonths,
  fetchMoreIntrestList,
  getIntrestExperience,
  setLocalStorage,
  showToast,
} from "../../../utils";
import endpoints from "../../../utils/endpoints";
import { makeGetCall, makePatchCall } from "../../../utils/Requests";
import { STRING_CONSTANTS } from "../../../utils/StringConstant";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import DashboardWrapper from "../../Dashboard/DashboardWrapper";
import ExperienceCard from "./ExperienceCard";

export default function TrainingExperience() {
  const [, setIsLoading] = useState(false);
  const [isBtnLoading, setIsBtnLoading] = useState(false);
  const [interestList, setInterestList] = useState<any>([]);
  const [userData, setUserData] = useState<any>(null);
  const [handleSave, setHandleSave] = useState<any>(false);

  const [nextUrl, setNextUrl] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    getIntrestExperience({}, setInterestList, setNextUrl, setIsLoading);
  }, [handleSave]);

  useEffect(() => {
    setIsLoading(true);
    makeGetCall({ url: endpoints.profile })
      .then((res) => {
        setUserData(res.data);
      })

      .catch((err) => {
        showToast(err, "error");
      });
    setIsLoading(false);
  }, []);

  let userAge = null;
  if (userData?.userDetail?.dob) {
    userAge = moment().diff(
      moment(userData?.userDetail?.dob, "YYYY-MM-DD"),
      "years"
    );
  }

  function convertToDays(years: any, months: any) {
    const daysInYear = 365;
    const daysInMonth = 30;
    const totalDays = years * daysInYear + months * daysInMonth;
    return totalDays;
  }

  function convertToYearsMonths(days: number) {
    const daysInYear = 365;
    const daysInMonth = 30;
  
    const years = Math.floor(days / daysInYear);
    const remainingDaysAfterYears = days % daysInYear;
    const months = Math.floor(remainingDaysAfterYears / daysInMonth);
  
    return { years, months };
  }

  const getTraineeExperience = (
    params: any = {
      want_paginated: false,
    }
  ) => {
    setIsLoading(true);
    makeGetCall({ url: endpoints.interest_experience, params })
      .then((res) => {
        let newArray = res?.results?.map((matchingPayload: any) => {
          // let matchingPayload =
          //   userData?.trainerData[0]?.trainerSpecializationExperience?.find(
          //     (payload: any) => payload?.id === obj?.id
          //   );
          const experienceInDays = matchingPayload?.experience;
          const { years, months } = experienceInDays ? convertToYearsMonths(experienceInDays) : { years: null, months: null };
    
          return {
            ...matchingPayload,
            years,
            months,
            interestInformation: matchingPayload?.interestInformation,
          };
        });

        setInterestList(newArray);
        setNextUrl(res?.next || null);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        showToast(err, "error");
      });
  };

  useEffect(() => {
    getTraineeExperience();
  }, []);

  const handleNext = () => {
    if (interestList.length > 0) {
      const updatedExperience = interestList.map((interestObj: any) => {
        const matchingPayload =
          userData?.trainerData[0]?.trainerSpecializationExperience?.find(
            (payload: any) => payload?.id === interestObj?.id
          );
        if (matchingPayload) {
          return {
            id: matchingPayload?.id,
            experience: convertToDays(interestObj.years, interestObj.months),
            interestInformation: interestObj?.interestInformation,
          };
        } else {
          return {
            interestId: interestObj?.id,
            experience: convertToDays(interestObj.years, interestObj.months),
            interestInformation: interestObj?.interestInformation,
          };
        }
      });

      setIsBtnLoading(true);
      let newObj = updatedExperience.filter(
        (obj2: any) => obj2.experience !== null && obj2.experience !== 0
      );

      if (newObj) {
        makePatchCall({
          url: endpoints.interest_experience,
          apiPayload: { updateExperience: newObj },
        })
          .then((res) => {
            if (res.status.code === 200) {
              showToast(res.status.message, "success");
              setLocalStorage("expData", res?.data);
              window.location.reload();
            } else {
              showToast(STRING_CONSTANTS.wrong_data_message, "error");
            }
          })
          .catch((err) => {
            showToast(err, "error");
          })
          .finally(() => {
            setIsBtnLoading(false);
          });
      }
    }
  };

  // useEffect(() => {
  //   setIsLoading(true);
  //   if (userData) {
  //     let newArray = interestList?.map((obj: any) => {
  //       let matchingPayload =
  //         userData?.trainerData[0]?.trainerSpecializationExperience?.find(
  //           (payload: any) => payload?.id === obj?.id
  //         );
  //       if (matchingPayload) {
  //         return {
  //           ...obj,
  //           id: matchingPayload?.id,
  //           years: matchingPayload?.experience
  //             ? Number(matchingPayload?.experience / 365)?.toFixed(0)
  //             : null,
  //           months: matchingPayload?.experience
  //             ? Number((matchingPayload?.experience % 365) / 12)?.toFixed(0)
  //             : null,
  //           interestInformation: matchingPayload?.interestInformation,
  //         };
  //       } else {
  //         return {
  //           ...obj,
  //         };
  //       }
  //     });
  //     setIsLoading(false);
  //     setInterestList(newArray);
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [userData, nextUrl]);

  const updateExpById = ({ id, value, type }: any) => {
    const objectToUpdate = interestList.find((obj: any) => obj?.id === id);
    if (objectToUpdate) {
      if (type === "year") {
        objectToUpdate.years = value;
      } else if (type === "month") {
        objectToUpdate.months = value;
      } else if (type === "interestInformation") {
        objectToUpdate.interestInformation = value;
      }
    }
    setInterestList([...interestList]);
  };

  return (
    <DashboardWrapper showTab={true}>
      <div className="personal-details-wrapper qualification-page">
        <div className="">
          <div className="details-title">
            <div className="title">Qualifications</div>
            <div className="add-btns">
              <span className="save" onClick={() => navigate("/interest")}>
                Add Interest
              </span>
              <img
                alt=""
                src="/static/plusorange.svg"
                onClick={() => navigate("/interest")}
              />
            </div>
          </div>
          <div className="loder-part-scro traning-expere">
            <InfiniteScroll
              className="expertise_web pt-0"
              dataLength={interestList?.length}
              next={() =>
                fetchMoreIntrestList(
                  nextUrl,
                  interestList,
                  setNextUrl,
                  setInterestList,
                  setIsLoading
                )
              }
              hasMore={nextUrl ? true : false}
              loader={<ApiLoader />}
            >
              {interestList?.map((item: any, index: number) => {
                return (
                  <Fragment key={index}>
                    <ExperienceCard
                      key={index}
                      item={item}
                      updateExpById={updateExpById}
                      userAge={userAge}
                      handleSave={() =>
                        getIntrestExperience(
                          {},
                          setInterestList,
                          setNextUrl,
                          setIsLoading
                        )
                      }
                      refetchList={
                        () => getTraineeExperience()
                        // getIntrestExperience(
                        //   {},
                        //   setInterestList,
                        //   setNextUrl,
                        //   setIsLoading
                        // )
                      }
                    />
                  </Fragment>
                );
              })}
            </InfiniteScroll>
          </div>

          {/* <div
            className="continue_btn"
            style={{
              maxWidth: "555px",
              margin: "0px auto",
            }}
          >
            <Button
              btnTxt="Save"
              onClick={handleNext}
              isLoading={isBtnLoading}
            />
          </div> */}
        </div>
      </div>
    </DashboardWrapper>
  );
}
