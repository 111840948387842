import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import IconToggleOn from "../../../assets/img/connectIcons/collapse-on.png";
import IconToggleOff from "../../../assets/img/connectIcons/collapse-off.png";
import IconSeeAll from "../../../assets/img/connectIcons/see-all.svg";
import BasicButton from "../../Button/BasicButton";
import { BUTTON_TEXT } from "../../../utils/StringConstant";
import ConnectUserCardShimmer from "../../Reusable/Shimmer/ConnectUserCardShimmer";

const ConnectScrollableCard = ({
  title,
  description,
  path = "/connect/favourite",
  ClassName,
  render,
  isLoading,
}) => {
  const navigate = useNavigate();
  const scrollContainerRef = useRef(null);
  const [isAtLeftEdge, setIsAtLeftEdge] = useState(true);
  const [isAtRightEdge, setIsAtRightEdge] = useState(true);
  const scrollByAmount = (amount: any) => {
    if (scrollContainerRef.current) {
      scrollContainerRef.current?.scrollBy({
        left: amount,
        behavior: "smooth",
      });
    }
  };

  const renderConnectUserCardShimmer = (length = 6) =>
    Array.from({length: length}).map((_, index) => (
      <div key={"000" + index}><ConnectUserCardShimmer  /></div>
    ));

  useEffect(() => {
    const handleScroll = () => {
      const scrollContainer = scrollContainerRef.current;

      if (!scrollContainer) return;

      const scrollLeft = scrollContainer.scrollLeft;
      const scrollWidth = scrollContainer.scrollWidth;
      const clientWidth = scrollContainer.clientWidth;

      setIsAtLeftEdge(scrollLeft === 0);
      // console.log("width",scrollLeft, scrollWidth, clientWidth)
      setIsAtRightEdge(scrollLeft + clientWidth + 50 >= scrollWidth);
    };

    const scrollContainer = scrollContainerRef.current;
    scrollContainer.addEventListener("scroll", handleScroll);

    // Initial check
    handleScroll();

    return () => {
      if (scrollContainer) {
        scrollContainer.removeEventListener("scroll", handleScroll);
      }
    };
  }, []);
  
  return (
    <div className="fav-connections">
      <div className="suggestion-wrapper ">
        <div
          style={{
            width: "100%",
            display: "grid",
            gridTemplateColumns: "70% 30%",
          }}
        >
          <span className="suggestion-heading font_f">{title}</span>
          <div className="see-all-btn-scrollable">
            <BasicButton
              onClick={() => {
                navigate(path);
              }}
            >
              {BUTTON_TEXT.SEE_ALL}
            </BasicButton>
          </div>
        </div>
        <div className="suggestion-desc font_os">{description}</div>
        <div
          className="fav-overflow"
          style={
            isAtLeftEdge
              ? { display: "grid", gridTemplateColumns: "0% 98% 2%" }
              : isAtRightEdge
              ? { display: "grid", gridTemplateColumns: "2% 98% 0%" }
              : { display: "grid", gridTemplateColumns: "2% 96% 2%" }
          }
        >
          <div className="scroll-icon-wrap" style={{ paddingRight: "15px", cursor:"pointer" }}>
            {!isAtLeftEdge && (
              <img
                src={IconToggleOff}
                className="scroll-icon-style"
                onClick={() => scrollByAmount(-200)}
              />
            )}
          </div>

          <div className={ClassName} ref={scrollContainerRef}>
            {isLoading ? (
             renderConnectUserCardShimmer()
            ) : (
              render
            )}
          </div>
          {!isAtRightEdge && <div className="scroll-icon-wrap" style={{ paddingLeft: "15px", cursor:"pointer" }}>
            <img src={IconToggleOn} className="scroll-icon-style" onClick={() => scrollByAmount(250)} />
          </div> }
          
        </div>
      </div>
    </div>
  );
};

export default React.memo(ConnectScrollableCard);
