import * as React from "react";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import "./style.scss";

export default function CustomRadio({
  label = "",
  radioData = [],
  row = true,
  radio_wrap_class = "",
  name = "",
  watch = (props: any) => null,
  handleSetValue = (key: any, val: any) => null,
  isDisabled = false,
}) {
  const handleChange = (event) => {
    handleSetValue(name, event.target.value);
  };
  return (
    <FormControl className="customRadio-wrap">
      {label && (
        <FormLabel id="demo-row-radio-buttons-group-label">{label}</FormLabel>
      )}
      <RadioGroup
        row={row}
        className={radio_wrap_class}
        aria-labelledby="demo-row-radio-buttons-group-label"
        name={name}
        value={watch(name)}
        onChange={handleChange}
      >
        {radioData?.map((item) => {
          return (
            <FormControlLabel
              key={item?.value}
              value={item?.value}
              control={<Radio />}
              label={item?.label}
              disabled={isDisabled}
            />
          );
        })}
      </RadioGroup>
    </FormControl>
  );
}
