import React, { Fragment } from "react";
import dayjs from "dayjs";
import "./style.scss";
import { SCHEDULE_TYPE } from "../../../constants/helperScheduleDates";
import { EventCardSimple } from "./EventCardSimple";

export type Props = {
  [key: string]: any;
};

const EventCardSection = (props: Props) => {
  const { data = [], handleEventEditClick, onDeleteEvent, watch } = props;
  return (
    <div className="event_card_container">
      {data?.map((item, index) => (
        <Fragment key={"package_event" + index}>
          <EventCardSimple
            item={item}
            handleEventEditClick={handleEventEditClick}
            onDeleteEvent={onDeleteEvent}
            index={index}
            watch={watch}
          />
        </Fragment>
      ))}
    </div>
  );
};

export default EventCardSection;
