import React, { useMemo } from "react";
import { apiConstants } from "../../../utils/Constants";
import { LISTING_TYPE } from "../../../types";
import { HEADINGS } from "../../../utils/StringConstant";
import { OutlineYellowButton } from "../../../global/OutlineButton";
import EventCardSection from "../EventsCardSection/EventCardSection";
import { MUI_ICONS } from "../../../assets/materialIcons";

interface PackageScheduleSectionProps {
  watch: (field: string) => any;
  onDeleteEvent: (eventId: string) => void;
  togglePackageTab?: any;
  packageScheduleData?: any;
}

const PackageScheduleSection: React.FC<PackageScheduleSectionProps> =
  React.memo(
    ({
      watch,
      onDeleteEvent,
      togglePackageTab,
      packageScheduleData = [],
    }) => {
      const listingType = watch(apiConstants.listingType);
      const multipleEvents = packageScheduleData;

      const eventCardSection = useMemo(() => {
        if (multipleEvents?.length > 0) {
          return (
            <EventCardSection
              data={multipleEvents}
              onDeleteEvent={onDeleteEvent}
              handleEventEditClick={togglePackageTab}
              totalData={multipleEvents.length}
              watch={watch}
            />
          );
        }
        return null;
      }, [multipleEvents, onDeleteEvent, togglePackageTab, watch]);

      const addEventButton = useMemo(() => {
        if (multipleEvents?.length > 0) {
          return null;
        } else {
          return (
            <OutlineYellowButton
              style={{ border: "1px solid #DCDCDC" }}
              text={HEADINGS.ADD_EVENT}
              icon={<MUI_ICONS.Add active />}
              onClick={togglePackageTab}
            />
          );
        }
      }, [multipleEvents?.length, togglePackageTab]);

      return (
        <>
          {listingType === LISTING_TYPE.M && (
            <div style={{ marginTop: "12px" }}>
              {eventCardSection}
              {addEventButton}
            </div>
          )}
        </>
      );
    }
  );

export default PackageScheduleSection;
