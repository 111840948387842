import { Form, Modal } from "react-bootstrap";
import { useState } from "react";
import Button from "../../../global/Button";
import { FlagListingReason } from "../../../utils/Constants";
import { makePostCall } from "../../../utils/Requests";
import endpoints from "../../../utils/endpoints";
import { showToast } from "../../../utils";
import { useNavigate } from "react-router";
import { LeftArrowStickIcon } from "../../../utils/Icons/svgs";

const FlagListingPopup = (props) => {
  console.log("props: ", props);
  const [selectFlagReason, setSelectFlagReason] = useState<any>(0);
  const [flagReason, setFlagReason] = useState<any>(null);
  const [reasonValue, setReasonValue] = useState<any>("");
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const FlagListing = () => {
    let apiPayload;
    if (flagReason) {
      apiPayload = {
        reason: flagReason,
      };
    } else {
      apiPayload = {
        reason: reasonValue,
      };
    }
    setIsLoading(true);

    makePostCall({
      url: endpoints.listing + `${props?.listing_id}/flag-listing/`,
      apiPayload: apiPayload,
    })
      .then((res) => {
        if (res?.status?.code === 200) {
          showToast(res.status.message, "success");

          navigate(`/events?type=${props?.type || "S"}`);
          props.onHide();
        } else {
          showToast(res.status.message, "error");
        }
        setIsLoading(false);
      })
      .catch((err) => {
        showToast(err, "error");
        setIsLoading(false);
      });
  };

  const isDisabled = () => {
    if (!selectFlagReason) {
      return true;
    }

    if (selectFlagReason === "other" && reasonValue?.length === 0) {
      return true;
    }
    return false;
  };

  const handleWordLimit = (e) => {
    let wordCount = e.target?.value?.split(" ");
    if (wordCount && wordCount.length <= 250) {
      setReasonValue(e.target.value);
    }
  };

  return (
    <Modal {...props} centered dialogClassName="width-619">
      <Modal.Body style={{ height: "fit-content" }}>
        <div
          className="for-responsive-only"
          style={{ backgroundColor: "#F3F3F3" }}
        >
          <div className="res-global-header">
          </div>
          <div className="emodal_wrapper p-3 bg-white">
            <div className="d-md-flex justify-content-end d-none ">
              <span
                className="text-end"
                onClick={props.onHide}
                style={{ cursor: "pointer" }}
              >
                <img src="/static/cross_circle.png" alt="" />
              </span>
            </div>

            <div className="fl-heading position-relative d-flex justify-content-center align-items-center  ">
              <div className="">
                <p className="fl-head-txt font_f">
                  <div
                    className="d-md-none position-absolute "
                    onClick={props.onHide}
                    style={{ cursor: "pointer" }}
                  >
                    <LeftArrowStickIcon width="20" height="20" />
                  </div>
                  Flag Listing
                </p>
                <p className="f-l-title font_f">Tell us what went wrong?</p>
                <p className="f-l-subtitle font_os">
                  Your feedback matters! Use this feature to report any users
                  that violate our community guidelines. Help us keep our
                  platform safe and enjoyable for everyone. Thank you!
                </p>
              </div>
            </div>
            <div className="p-0 position-relative">
              <div className="mb-3 flag-listing-wrapper">
                {FlagListingReason?.map((item, i) => {
                  return (
                    <div
                      className="d-flex gap-3 align-items-center flag-listing my-3"
                      key={i + 1}
                    >
                      <input
                        type="radio"
                        value={i + 1}
                        checked={selectFlagReason === i + 1}
                        onChange={() => {
                          setSelectFlagReason(() => i + 1);
                          setFlagReason(item);
                        }}
                      />
                      <p className="mb-0 font_os">{item}</p>
                    </div>
                  );
                })}
                <div className="d-flex gap-3 align-items-center flag-listing my-3">
                  <input
                    type="radio"
                    value="other"
                    checked={selectFlagReason === "other"}
                    onChange={() => {
                      setSelectFlagReason("other");
                      setFlagReason(null);
                    }}
                  />
                  <p className="mb-0 font_os">Other (Please provide details)</p>
                </div>
              </div>

              {selectFlagReason === "other" && (
                <div className="fl-textarea p-3 my-3">
                  <div className="d-flex justify-content-between font_os">
                    <label>Other</label>
                    <div className="fl-words">
                      {reasonValue?.length
                        ? reasonValue?.split(" ")?.length
                        : "0"}
                      /250
                    </div>
                  </div>
                  <Form.Control
                    as="textarea"
                    row={2}
                    style={{ border: "0px", padding: "0px" }}
                    className="font_os"
                    placeholder="Your feedback helps us improve."
                    value={reasonValue}
                    onChange={(e) => {
                      handleWordLimit(e);
                    }}
                  />
                </div>
              )}

              <Button
                btnTxt="Submit"
                className={"backtologin mt-3 d-none d-md-block font_f"}
                disabled={isDisabled()}
                isLoading={isLoading}
                onClick={() => {
                  FlagListing();
                }}
              />
              <div
                className="mt-3 "
                style={{
                  bottom: "16px",
                  position: "fixed",
                  padding: "1rem",
                  left: "0",
                  right: "0",
                }}
              >
                <Button
                  btnTxt="Submit"
                  className={"backtologin d-md-none font_f"}
                  disabled={isDisabled()}
                  isLoading={isLoading}
                  onClick={() => {
                    FlagListing();
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default FlagListingPopup;
