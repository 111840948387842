import React, { useState } from "react";
import CustomRadio from "../../../CustomRadio";
import CustomSingleSelect from "../../../CustomSingleSelect";
import { limitFrequency, WeekDays } from "../../../../utils/Constants";
import CustomNumberInput from "../../../CustomNumberInput";
import CustomWeekDay from "../../../CustomWeekDay";
import "./style.scss";
import InfoCard from "../../InfoCard";
import { LIMIT_TYPE } from "../../../../constants/helperScheduleDates";

const radioData = [
  {
    label: "Yes",
    value: true,
  },
  {
    label: "No",
    value: false,
  },
];

export default function PackageLimitLayout({ setValue, getValues, watch }) {
  console.log("getValues", getValues());
  const renderDayFields = () => {
    return (
      <>
        <InfoCard
          title="Please specify the maximum number of slots a single user can book every
        day."
        />

        <div className="d-flex flex-row align-items-center gap-4">
          <div>Every Day</div>
          <CustomNumberInput
            endAdornmentText="/20 slots"
            name="endAfterVal"
            // watch={watch}
            // handleSetValue={setValue}
            min={1}
            max={365}
            // isDisabled={
            //   getValues("endEventType") === "end-by" ||
            //   getValues("endEventType") === ""
            // }
          />
        </div>
      </>
    );
  };

  const renderWeekFields = () => {
    return (
      <>
        <InfoCard title="Please specify the maximum number of slots a single user can book every week." />

        <div className="d-flex flex-row align-items-center gap-4">
          <div>Every week</div>
          <CustomNumberInput
            endAdornmentText="/20 slots"
            name="endAfterVal"
            // watch={watch}
            // handleSetValue={setValue}
            min={1}
            max={365}
            // isDisabled={
            //   getValues("endEventType") === "end-by" ||
            //   getValues("endEventType") === ""
            // }
          />
        </div>

        <CustomWeekDay
          data={WeekDays}
          name="weekDays"
          // getValues={getValues}
          // handleSetValue={setValue}
          // isDisabled
        />
      </>
    );
  };

  const renderMonthFields = () => {
    return (
      <>
        <InfoCard title="Please specify the maximum number of slots a single user can book every month." />

        <div className="d-flex flex-row align-items-center gap-4">
          <div>January</div>
          <CustomNumberInput
            endAdornmentText="/20 slots"
            name="endAfterVal"
            // watch={watch}
            // handleSetValue={setValue}
            min={1}
            max={365}
            // isDisabled={
            //   getValues("endEventType") === "end-by" ||
            //   getValues("endEventType") === ""
            // }
          />
        </div>
      </>
    );
  };

  const renderTotalFields = () => {
    return (
      <>
        <InfoCard title="Please specify the maximum number of slots a single user can book in this event." />
        <div className="d-flex flex-row align-items-center gap-4">
          <div>Total slots</div>
          <CustomNumberInput
            endAdornmentText="/20 slots"
            name="endAfterVal"
            // watch={watch}
            // handleSetValue={setValue}
            min={1}
            max={365}
            // isDisabled={
            //   getValues("endEventType") === "end-by" ||
            //   getValues("endEventType") === ""
            // }
          />
        </div>
      </>
    );
  };

  const renderBookingLimitType = (type = "") => {
    switch (type) {
      case LIMIT_TYPE.DAY:
        return renderDayFields();
      case LIMIT_TYPE.WEEK:
        return renderWeekFields();
      case LIMIT_TYPE.MONTH:
        return renderMonthFields();
      default:
        return renderTotalFields();
    }
  };

  return (
    <div className="packageLimit-wrap">
      <div className=" d-flex flex-column align-items-center text-center gap-4">
        <CustomRadio
          row={true}
          radio_wrap_class="gap-3"
          radioData={radioData}
            name="isRestriction"
          watch={watch}
          handleSetValue={setValue}
        />
      </div>
      <InfoCard
        title="Restrict the number of slots a user can book for this event. Set daily,
        weekly, or monthly limits based on your preferences."
      />
      <CustomSingleSelect
        placeholder="Set booking limit for the user for every"
        label="Set booking limit for the user for every"
        name="restrictionFrequencyType"
        dropdownData={limitFrequency}
        watch={watch}
        handleSetValue={setValue}
        getValues={getValues}
        // isDisabled={getValues(WEEKLY_FORM_KEYS.EVENT_DATE).length > 1}
      />
      {renderBookingLimitType(watch("restrictionFrequencyType"))}
    </div>
  );
}
