import { forwardRef, useEffect, useState } from "react";
import { Controller, useController } from "react-hook-form";
import { emptyFunction, showToast } from "../utils";
interface InputFieldProps {
  [key: string]: any;
  name: string;
  type: React.HTMLInputTypeAttribute;
  inputLabel?: string;
  as?: string;
  placeholder?: string;
  style?: React.CSSProperties;
  className?: string;
  disabled?: boolean;
  hideErrorMessage?: boolean;
  errors?: object | any;
  value?: string | boolean | any;
  maxlength?: number | any;
  id?: string;
  control?: any;
  extramsg?: string;
  isChecked?: boolean;
  customOnChange?: any;
  reference?: any;
  onlyPastDates?: boolean;
  onlyFuturedates?: boolean;
  onKeyDown?: any;
  fileOnChange?: any;
  getValues?: any;
  setValue?: any;
  pattern?: any;
  handleAge?: any;
  handleNotes?: any;
  isListingTextArea?: any;
  isSpecialInstruction?: any;
  onBlur: any;
  displayErrorOutlined?: any;
  decimalLimit?: boolean;
}

const InputField = forwardRef((props: InputFieldProps, ref: any) => {
  const {
    name,
    type,
    extramsg,
    inputLabel,
    placeholder,
    style,
    control,
    className,
    errors,
    id,
    setValue,
    customOnChange,
    fileOnChange,
    reference,
    maxlength,
    getValues,
    disabled,
    pattern,
    onKeyDown = emptyFunction,
    handleAge = emptyFunction,
    handleNotes = emptyFunction,
    isListingTextArea = false,
    isSpecialInstruction = false,
    onKeyPress,
    addIconPadding = false,
    addIconPaddingTop = false,
    onBlur = () => null,
    displayErrorOutlined = false,
    manualValue,
    manualClear = () => null,
    textAreaStyle,
    onTextAreaInput = emptyFunction,
    passType,
    fieldType,
    setPassType = emptyFunction,
    isDecimalLimit=false,
    isFixedNumber=false,
  } = props;

  // const [isFocus] = useState(false);
  const [isFocus, setIsFocus] = useState(false);
  const [isPasteEvent, setIsPasteEvent] = useState(false);
  const [showRemoveFilledOnPaste, setShowRemoveFilledOnPaste] = useState(false);
  const {
    field: { onChange, value = manualValue },
  } = useController({
    name,
    control,
  });
  const handleOnchange = (e: any) => {
    if (customOnChange) {
      customOnChange(e.target);
    } else if (fileOnChange) {
      fileOnChange(e);
    }

    if (type === "number") {
      const formattedValue = String(e.target.value).replace(
        /^(\d*\.?\d{0,2}).*$/,
        "$1"
      );
      const fixedValue = String(e.target.value).replace(/\D/g, "");
      if (isFixedNumber) {
        onChange(fixedValue);
      } else if (isDecimalLimit) {
        onChange(formattedValue);
      } else {
        onChange(String(e.target.value));
      }
    } else {
      onChange(e);
    }
  };
  const handleOnFocus = () => {
    setIsFocus(true);
  };
  const handleOnBlur = () => {
    setIsFocus(false);
    // onBlur();
  };

  const renderMainInput = () => {
    switch (type) {
      case "radio":
        return (
          <Controller
            control={control}
            name={name}
            render={() => {
              return (
                <input
                  type={type}
                  onChange={onChange}
                  id={value}
                  checked={value === id}
                  placeholder={placeholder}
                  value={id}
                  disabled={disabled}
                  onKeyDown={onKeyDown || {}}
                  onKeyPress={onKeyPress}
                />
              );
            }}
          />
        );
      case "textarea":
        return (
          <Controller
            control={control}
            name={name}
            render={({ field: { value, onChange } }) => {
              return (
                <textarea
                  onChange={(e) => {
                    if (
                      !maxlength ||
                      e.target.value.split(" ").length <= maxlength
                    ) {
                      onChange(e);
                      handleNotes(e);
                    }
                  }}
                  placeholder={isFocus ? placeholder : ""}
                  value={value || ""}
                  disabled={disabled}
                  onKeyPress={onKeyPress}
                  onFocus={handleOnFocus}
                  onBlur={handleOnBlur}
                  style={textAreaStyle}
                  onInput={onTextAreaInput}
                />
              );
            }}
          />
        );
      case "checkbox":
        return (
          <Controller
            control={control}
            name={name}
            render={() => {
              return (
                <input
                  name={name}
                  type="checkbox"
                  onChange={(e) => {
                    handleOnchange(e);
                  }}
                  id={id || ""}
                  value={value}
                  checked={value}
                  onKeyDown={onKeyDown || {}}
                  onKeyPress={onKeyPress}
                />
              );
            }}
          />
        );
      case "number":
        return (
          <Controller
            control={control}
            name={name}
            render={({ field: { onChange, value } }) => {
              return (
                <input
                  onChange={(e) => {
                    const numericValue =
                      e?.target?.value === "" ? "" : Number(e?.target?.value);
                    if (!maxlength) {
                      const formattedValue = String(numericValue).replace(
                        /^(\d*\.?\d{0,2}).*$/,
                        "$1"
                      );
                      const fixedValue = String(numericValue).replace(
                        /\D/g,
                        ""
                      );
                      if (isFixedNumber) {
                        onChange(fixedValue);
                      } else if (isDecimalLimit) {
                        onChange(formattedValue);
                      } else {
                        onChange(numericValue);
                      }
                      handleOnchange(e);
                      handleAge();
                    } else if (e.target.value?.length <= maxlength) {
                      handleOnchange(e);
                      onChange(String(e?.target?.value));
                      handleAge();
                    }
                    if (
                      e?.target?.value?.length > maxlength &&
                      isPasteEvent &&
                      name === "aNumber"
                    ) {
                      showToast(
                        "Account number must be between 6 to 10 characters long",
                        "error"
                      );
                      setIsPasteEvent(false);
                    }
                    if (
                      e?.target?.value?.length > maxlength &&
                      isPasteEvent &&
                      name === "bNumber"
                    ) {
                      showToast("BSB number should be 6 digits", "error");
                      setIsPasteEvent(false);
                    }
                  }}
                  onPaste={() => {
                    setIsPasteEvent(true);
                    setShowRemoveFilledOnPaste(true);
                  }}
                  type={type}
                  id={id || ""}
                  ref={reference}
                  placeholder={isFocus ? placeholder : ""}
                  // value={value ? value : value === 0 ? value : null}
                  value={value ?? ""}
                  min={1}
                  onKeyDown={onKeyDown || {}}
                  pattern={pattern}
                  max={maxlength}
                  disabled={disabled}
                  onKeyPress={onKeyPress}
                  onWheel={(e) => e.currentTarget.blur()} //prevent value change on scroll
                  onFocus={handleOnFocus}
                  onBlur={() => {
                    handleOnBlur();
                    onBlur();
                  }}
                />
              );
            }}
          />
        );
      case "file":
        return (
          <input
            onChange={(e) => {
              handleOnchange(e);
            }}
            type={type}
            ref={reference}
            id={id || ""}
            value={value || ""}
            onKeyPress={onKeyPress}
          />
        );
      default:
        return (
          <Controller
            control={control}
            name={name}
            render={() => {
              return (
                <input
                  onChange={(e) => {
                    handleOnchange(e);
                  }}
                  type={type}
                  ref={reference}
                  maxLength={maxlength}
                  id={id || ""}
                  placeholder={isFocus ? placeholder : ""}
                  value={value || ""}
                  onKeyDown={onKeyDown || {}}
                  style={{
                    border: displayErrorOutlined
                      ? "1px solid #FF3B30"
                      : "1px solid #dcdcdc",
                  }}
                  autoComplete={"off"}
                  disabled={disabled}
                  onKeyPress={onKeyPress}
                  onFocus={handleOnFocus}
                  onBlur={handleOnBlur}
                />
              );
            }}
          />
        );
    }
  };
  useEffect(() => {
    if (id === "autocomplete") {
      onChange(value);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);
  return (
    <div
      style={style}
      className={`form_group ${
        props?.value || props?.value === 0 || value || value === 0 || isFocus
          ? "focused"
          : ""
      } ${className ? className : ""} `}
    >
      <div
        className={
          errors && errors[name] ? "input_box input_error" : "input_box"
        }
      >
        {inputLabel && <span className="input_label">{inputLabel}</span>}
        {renderMainInput()}
        {type === "textarea" ? (
          <>
            {isListingTextArea ? (
              <span className="count">
                {`${
                  getValues(name)
                    ?.split(/\s+/)
                    ?.filter((word) => word.trim() !== "").length || 0
                }/${maxlength}
                `}
              </span>
            ) : isSpecialInstruction ? (
              <span className="count">
                {`${
                  getValues(name)
                    ?.split(/\s+/)
                    ?.filter((word) => word.trim() !== "").length || 0
                }/${maxlength}
                `}
              </span>
            ) : (
              <span className="count">
                {getValues(name)?.trim()?.split(" ")?.length || 0}/ {maxlength}
              </span>
            )}
          </>
        ) : null}

        <div className="field-endIcon-wrap">
          {fieldType === "password" ? (
            <img
              src={
                passType === "password"
                  ? "/static/closeeye.svg"
                  : "/static/openeye.svg"
              }
              alt=""
              onClick={() =>
                setPassType(passType === "password" ? "text" : "password")
              }
            />
          ) : (
            ""
          )}
          {(value?.length > 0 || showRemoveFilledOnPaste) && (
            <img
              src="/static/cross-circle-gray-dark.svg"
              className={`${addIconPadding ? "icon_adjust" : ""}${
                disabled ? "disabled" : ""
              } ${addIconPaddingTop ? " icon_adjust_top " : ""} cross-icon`}
              // className={disabled ? "disabled cross-icon " : "cross-icon"}
              alt=""
              onClick={() => {
                if (type === "number") {
                  setValue(name, null);
                  if (reference) {
                    reference.current.value = null;
                  }
                  setShowRemoveFilledOnPaste(false);
                } else {
                  setValue(name, "");
                }
                manualClear();
              }}
            />
          )}
        </div>
      </div>
      {extramsg && <p className="extramsg">{extramsg}</p>}

      {errors && errors[name] && (
        <span className="text-danger">{errors[name]?.message}</span>
      )}
    </div>
  );
});

export default InputField;
