import React, { useState } from "react";
import { Box } from "@mui/material";
import PrimaryButton from "../../Button/PrimaryButton";
import "./style.scss";
import LoadingLayer from "../../ConnectUserProfile/LoaderLayer";
import { useNavigate } from "react-router-dom";
import { handleShowScheduleDate } from "../../../constants/helperCreateListing";
import InputField from "../../../global/InputField";
import { ACTIVITY_TYPE, apiConstants } from "../../../utils/Constants";
import GooglePlacesSearchBar from "../../../global/GooglePlaceSelector";
import { LABELS, MESSAGE } from "../../../utils/StringConstant";
import { useForm } from "react-hook-form";
import { MUI_ICONS } from "../../../assets/materialIcons";
import {
  SCHEDULE_TYPE,
  getListingTypeDescription,
} from "../../../constants/helperScheduleDates";
import { handleLocation, showToast } from "../../../utils";
import ScheduleDatesLayout from "../ScheduleDatesSection/ScheduleDatesLayout";

const PackageAddEvent = React.memo(
  ({
    handleScreenSection,
    packageScheduleData,
    setPackageScheduleData,
    virtual = false,
    isSingleVirtualLink = false,
  }: {
    handleScreenSection?: any;
    packageScheduleData: any;
    setPackageScheduleData?: any;
    virtual?: any;
    isSingleVirtualLink?: any;
  }) => {
    // Memoized reset handler
    const loading = false;
    const navigate = useNavigate();

    const [initialPackageData = null] = packageScheduleData;

    const [locationData, setLocationData] = useState(
      initialPackageData?.address || null
    );

    const [isPackageScheduleDateTab, setIsPackageScheduleDateTab] =
      useState(false);

    const [sessionScheduleData, setSessionScheduleData] = useState<any>(null);

    // form
    const { control, watch, setValue, getValues } = useForm({
      defaultValues: { ...initialPackageData },
      mode: "onChange",
    });

    const handlePackageSchedular = (data, isSubmit) => {
      setIsPackageScheduleDateTab(false);
      if (isSubmit) {
        setValue("schedulePackage", data);
      }
    };

    const handlePackageSave = () => {
      const allData = getValues();
      if (!isSingleVirtualLink && allData?.address !== locationData) {
        return showToast(MESSAGE.LOCATION_FROM_SUGGESTION, "error");
      } else if (
        virtual &&
        watch("schedulePackage")?.payloadSchedule?.length &&
        !watch("schedulePackage")?.payloadSchedule[0]?.virtual_link
      ) {
        return showToast(MESSAGE.WEB_PACKAGE_VIRTUAL_REQUIRED, "error");
      } else {
        setPackageScheduleData([allData]);
        setTimeout(() => {
          navigate(-1);
        }, 0);
      }
    };

    const handleDisabled = () => {
      if (!watch("title")?.trim()) {
        return true;
      } else if (!isSingleVirtualLink && !watch(apiConstants.address)?.trim()) {
        return true;
      } else if (
        !watch("schedulePackage")?.payloadSchedule?.length ||
        watch("schedulePackage")?.payloadSchedule?.length < 1
      ) {
        return true;
      }

      return false;
    };

    return (
      <>
        {isPackageScheduleDateTab ? (
          <ScheduleDatesLayout
            virtual={virtual}
            sessionScheduleData={sessionScheduleData}
            setSessionScheduleData={setSessionScheduleData}
            handlePackageSchedular={handlePackageSchedular}
            isPackage={true}
          />
        ) : (
          <Box className="package_schedule_dates_layout">
            {loading && <LoadingLayer isCustom />}
            {/* body */}
            <Box className="package_schedule_body_wrapper">
              {/* heading */}
              <Box
                className="package_schedule_back_arrow_container"
                onClick={() => navigate(-1)}
              >
                <img
                  src="/static/arrow/backArrowGrey.svg"
                  alt=""
                  className="package_arrow_icon_back"
                />
                <Box className="package_back_btn_arrow_text">
                  Back to package listing
                </Box>
              </Box>
              <Box className="package_schedule_header_wrapper">
                <Box className="package_schedule_header_data">
                  <Box className="package_schedule_header_heading">
                    Add Event
                  </Box>
                  <Box className="package_schedule_header_subheading">
                    Please select the days you'd like your event to run
                  </Box>
                </Box>
              </Box>
              {/* content */}
              <Box className="package_schedule_content_wrapper">
                <Box className="package_schedule_content_inner">
                  <div className="inner-contet">
                    <InputField
                      type="text"
                      className="floating_input mb-4"
                      name={"title"}
                      inputLabel="Event Name"
                      placeholder="Type event name"
                      control={control}
                      errors={{}}
                      setValue={setValue}
                    />

                    {
                    !isSingleVirtualLink && (
                        <div className="mb-4">
                          <GooglePlacesSearchBar
                            hideMap={true}
                            onChangeLocation={(
                              lat: string,
                              lng: string,
                              address: any,
                              address_components: any
                            ) => {
                              const location = handleLocation(
                                lat,
                                lng,
                                address,
                                address_components
                              );
                              setLocationData(address);
                              setValue(apiConstants.address, address);
                              setValue(apiConstants.lat, lat);
                              setValue(apiConstants.lng, lng);
                              setValue(apiConstants.postCode, location.zipcode);
                              setValue(apiConstants.state, location.state);
                              setValue(apiConstants.city, location.locality);
                              setValue(apiConstants.country, location.country);
                            }}
                            setValue={setValue}
                            handleLocation={handleLocation}
                            control={control}
                            name={apiConstants.address}
                            label={LABELS.SEARCH_LOCATION}
                            placeholder={LABELS.INCLUDE_LOCATION}
                          />
                        </div>
                      )}

                    <Box
                      onClick={() => {
                        setSessionScheduleData(watch("schedulePackage"));
                        setTimeout(() => {
                          setIsPackageScheduleDateTab(true);
                        }, 0);
                      }}
                    >
                      <div className="package_event_schedule_selection">
                        {watch("schedulePackage")?.finalScheduleData?.length >
                        0 ? (
                          <>
                            {watch("schedulePackage")?.eventType && (
                              <div className="filled_label">Schedule dates</div>
                            )}

                            <div className="no_package_schedule obtained_data">
                              {getListingTypeDescription(
                                watch("schedulePackage")
                              )}
                            </div>
                          </>
                        ) : (
                          <div className="no_package_schedule">
                            Schedule dates
                          </div>
                        )}

                        <MUI_ICONS.ArrowForward className="schedule_package_event_icon" />
                      </div>
                    </Box>
                  </div>
                </Box>
              </Box>
            </Box>
            {/* footer */}
            <Box className="package_schedule_footer_wrapper">
              <Box className="package_schedule_footer_action">
                <Box className="package_add_event_btn_container">
                  <PrimaryButton
                    minWidth={"334px"}
                    onClick={handlePackageSave}
                    disabled={handleDisabled()}
                  >
                    Add Event
                  </PrimaryButton>
                </Box>
              </Box>
            </Box>
          </Box>
        )}
      </>
    );
  }
);

export default PackageAddEvent;
