import React, { useMemo } from "react";
import { apiConstants } from "../../../utils/Constants";
import { LISTING_TYPE } from "../../../types";
import { HEADINGS } from "../../../utils/StringConstant";
import { OutlineYellowButton } from "../../../global/OutlineButton";
import AddIconYellow from "../../../assets/img/icons/AddIconYellow";
import MultipleEventCard from "../EventsCardSection/MultipleEventCard";

interface PackageEventSectionProps {
  watch: (field: string) => any;
  getValues: (field: string) => any;
  onDeleteEvent: (eventId: string) => void;
  handleEventEditClick: any;
  setShowAddEventSidebar: (value: boolean) => void;
  setShowEventListingsSidebar: (value: boolean) => void;
}

const PackageEventSection: React.FC<PackageEventSectionProps> = React.memo(
  ({
    watch,
    getValues,
    onDeleteEvent,
    handleEventEditClick,
    setShowAddEventSidebar,
    setShowEventListingsSidebar,
  }) => {
    const listingType = watch(apiConstants.listingType);
    const multipleEvents = getValues(apiConstants.multipleEvents);




    const eventCardSection = useMemo(() => {
      if (multipleEvents?.length > 0) {
        return (
          <MultipleEventCard
            data={multipleEvents.slice(0, 2)}
            onDeleteEvent={onDeleteEvent}
            handleEventEditClick={handleEventEditClick}
            setShowEventListingsSidebar={setShowEventListingsSidebar}
            totalData={multipleEvents.length}
            watch={watch}
          />
        );
      }
      return null;
    }, [
      multipleEvents,
      onDeleteEvent,
      handleEventEditClick,
      setShowEventListingsSidebar,
      watch,
    ]);

    const addEventButton = useMemo(() => {
      return (
        <OutlineYellowButton
          style={{ border: "1px solid #DCDCDC" }}
          text={HEADINGS.ADD_EVENT}
          icon={<AddIconYellow />}
          onClick={() => {
            setShowEventListingsSidebar(false);
            setShowAddEventSidebar(true);
          }}
        />
      );
    }, [setShowAddEventSidebar, setShowEventListingsSidebar]);

    return (
      <>
        {listingType === LISTING_TYPE.M && (
          <div style={{marginTop:'12px'}}>
            {eventCardSection}
            {addEventButton}
          </div>
        )}
      </>
    );
  }
);

export default PackageEventSection;
