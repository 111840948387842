import { connect } from "react-redux";
import PropTypes from "prop-types";
import React from "react";
import { Navigate } from "react-router-dom";
import { ROUTE_CONSTANTS } from "../utils/RouteConstants";

const OpenRoutes = ({ auth, children }) => {
  const isAuth = Boolean(localStorage.getItem("fm_token"));
  const isProfileCompleted = Boolean(auth?.moreUserDetails?.isProfileCompleted);

  if (isAuth && isProfileCompleted) {
    return <Navigate to={ROUTE_CONSTANTS.landing} />;
  }
   else {
    return children;
  }
};

OpenRoutes.propTypes = {
  children: PropTypes.node.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps)(OpenRoutes);
