import React from "react";
import IconNoSearchedData from "../../../assets/img/connectIcons/no-searched-data.svg";

const NoSearchedFilter = ({ mode = "search", searchTerm = "" }) => {
  
  return (
    <div className="no-data-wrapper">
      <div className="content-wrapper">
        <img
          src={IconNoSearchedData}
          alt="search-icon"
          height="147px"
          width="147px"
        />
        <div className="shadow-wrap">
          <div className="shadow"></div>
        </div>

        <div className="title-text">{}</div>
        <div className="desc-text" style={{ marginTop: "32px" }}>
          <div>We apologise, but we couldn't find any results</div>
          <span>
            {`that match your ${mode === "search" ? "search term " : "Filters"}`}
            {mode === "search" && <b>{searchTerm}</b>}
          </span>{" "}
        </div>
      </div>
    </div>
  );
};

export default React.memo(NoSearchedFilter);
