import React from "react";
import halfCircle from "../../assets/img/signupWelcome/halfCircle.svg";
import laugh from "../../assets/img/signupWelcome/laugh.svg";
import dumbbell from "../../assets/img/signupWelcome/dumbbell.svg";
import checked from "../../assets/img/signupWelcome/checked.svg";
import dismiss from "../../assets/img/signupWelcome/dismiss.svg";
type Props = {
  compareData?: any;
};

const CompareTable = ({
  compareData = [],
  guestData = null,
  memberData = null,
}) => {
  return (
    <div className="compare_table">
      <div className="compare_table_row compare_table_heading">
        <div className="compare_table_cell first_cell " />
        <div className="compare_table_cell guest_heading">
          <img src={laugh} alt="" />
          Browsers
        </div>
        <div className="compare_table_cell highlight special member_heading">
          <img src={dumbbell} alt="" />
          Lister
          <div className="pop_style">
            <img src={halfCircle} alt="" />
          </div>
        </div>
      </div>
      {compareData.map((item, id) => (
        <>
          <div className="compare_table_row" key={item.name}>
            <div className="compare_table_cell first_cell">{item.name}</div>
            {guestData ? (
              <div
                className={`compare_table_cell guest_data ${
                  item?.isGuestFree ? "free" : ""
                }`}
              >
                {true || guestData[item.key] ? (
                  guestData[item.key] === true ||
                  guestData[item.key] === false ||
                  guestData[item.key] === null ? (
                    guestData[item.key] === true ? (
                      <img src={checked} alt="Yes" />
                    ) : (
                      <img src={dismiss} alt="No" />
                    )
                  ) : (
                    guestData[item.key]
                  )
                ) : (
                  ""
                )}
              </div>
            ) : (
              ""
            )}

            {memberData ? (
              <div className="compare_table_cell highlight">
                {true || memberData[item.key] ? (
                  memberData[item.key] === true ||
                  memberData[item.key] === false ||
                  memberData[item.key] === null ? (
                    memberData[item.key] === true ? (
                      <img src={checked} alt="Yes" />
                    ) : (
                      <img src={dismiss} alt="No" />
                    )
                  ) : (
                    memberData[item.key]
                  )
                ) : (
                  ""
                )}
              </div>
            ) : (
              ""
            )}

            <div className="horizontal_line" />
          </div>
        </>
      ))}
    </div>
  );
};

export default CompareTable;
