import React from "react";
import { Button, ButtonProps } from "@mui/material";
import { styled } from "@mui/system";

interface BasicButtonProps extends ButtonProps {
  maxWidth?: any;
  minWidth?: any;
  loading?: boolean;
}

const StyledButton = styled(Button)(({ maxWidth }: BasicButtonProps) => ({
  backgroundColor: "transparent !important",
  color: "#EE7830",
  fontFamily: "Open sans, sans-serif",
  fontSize: "18px",
  fontWeight: 500,
  lineHeight: "24px",
  textAlign: "center",
  textTransform: "none",
  textDecoration: 'underline',
  padding: "4px 12px",
  borderRadius: "0px",
  border: "none",
  boxShadow: "none !important",
  "&:hover": {
    backgroundColor: "transparent !important",
    color: "#ff6200",
    textDecoration: 'underline',

  },
  "@media screen and (max-width:768px)": {
    padding: "16px",
  },
}));

const BasicButton = ({
  children = "Submit",
  maxWidth = false,
  minWidth = false,
  loading = false,
  onClick,
  ...restProps
}: BasicButtonProps) => {
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (loading) return;
    if (onClick) onClick(event);
  };

  return (
    <StyledButton
      sx={{
        maxWidth: typeof minWidth === "string" ? minWidth : "auto",
        minWidth: typeof minWidth === "string" ? minWidth : "fit-content",
      }}
      variant="contained"
      onClick={handleClick}
      {...restProps}
    >
      {children}
    </StyledButton>
  );
};

export default BasicButton;
