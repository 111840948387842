import { useNavigate } from "react-router-dom";
import IconPeoples from "../../../assets/img/connectIcons/people-group.png";
const NoConnections = () => {
  const navigate = useNavigate();
  return (
    <div className="no-data-wrapper">
      <div className="content-wrapper">
        <img src={IconPeoples} alt="connection-img" />
        <div className="shadow-wrap">
          <div className="shadow"></div>
        </div>

        <div className="title-text font_f">No connections to show</div>
        <div className="desc-text font_os">
          <div>There are no connections to display here. Please go to</div>
          <span>Suggestions, and add new mates there. </span>{" "}
          <span
            className="click-text"
            onClick={() => navigate("/connect/suggestions")}
          >
            Click here
          </span>
        </div>
      </div>
    </div>
  );
};

export default NoConnections;
