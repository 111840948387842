import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import {
  useNavigate,
  useSearchParams,
  useParams,
  useLocation,
} from "react-router-dom";
import moment from "moment";
import { useForm } from "react-hook-form";

// custom hook and store
import { useOutsideClick } from "../../hooks";
import { useAppSelector } from "../../store/store";

// constant and common function
import { LISTING_TYPE, PACKAGE_TYPE, USER_TYPE } from "../../types";
import { BUTTON_TEXT, HEADINGS, MESSAGE } from "../../utils/StringConstant";

import {
  ACTIVITY_TYPE,
  apiConstants,
  MAX_PARTICIPANTS,
  MAX_EVENT_COST,
  PRICING_TYPE,
} from "../../utils/Constants";
import {
  generateEventObject,
  generateListingPayload,
  getValidations,
  mapDraftResponseToTemp,
  validateDataForDisableCase,
  validateMeetingLink,
} from "../../constants/helperCreateListing";
import { ListingFormDefaultValue } from "../../utils/FormConfig";
import { ROUTE_CONSTANTS } from "../../utils/RouteConstants";
import apiConfig from "../../utils/endpoints";
import {
  DATE_FORMAT,
  SCHEDULE_TYPE,
} from "../../constants/helperScheduleDates";
import { handleFileUpload, handleLocation, showToast } from "../../utils";
import { makeGetCall, makePatchCall, makePostCall } from "../../utils/Requests";

// loader
import LoadingLayer from "../ConnectUserProfile/LoaderLayer";
import ApiLoaderListing from "../../global/ApiLoaderListing";
// component
import AddLocationSection from "../AddListingComponent/AddLocationSection";
import AgePreferenceSection from "../AddListingComponent/AgePreferenceSection";
import CancellationSection from "../AddListingComponent/CancellationSection";
import ConfirmDraft from "../AddListingComponent/ConfirmDraft";
import DifficultyLevelSection from "../AddListingComponent/DifficultyLevelSection";
import FilesUploadComponent from "../AddListingComponent/FilesUploadComponent";
import GenderPreferenceSection from "../AddListingComponent/GenderPreferenceSection";
import ListingAction from "../AddListingComponent/ListingAction";
import ListingAmountSection from "../AddListingComponent/ListingAmountSection";
import ListingDetailsForm from "../AddListingComponent/ListingDetailsForm";
import ListingHeading from "../AddListingComponent/ListingHeading";
import ListingPayout from "../AddListingComponent/ListingPayout";
import ListingFreePaidType from "../AddListingComponent/ListingFreePaidType";
import ListingTypeForm from "../AddListingComponent/ListingTypeForm";
import PackageAddEventSidebar from "../AddListingComponent/PackageAddEventSidebar";
import PackageAddEvent from "../AddListingComponent/PackageAddEvent";
import PackageEventSection from "../AddListingComponent/PackageEventSection";
import PackageListingsSidebar from "../AddListingComponent/PackageListingsSidebar";
import PayoutSection from "../AddListingComponent/PayoutSection";
import SavePackageEvent from "../AddListingComponent/SavePackageEvent";
import ScheduleDatesLayout from "../AddListingComponent/ScheduleDatesSection/ScheduleDatesLayout";
import SchedulePackageEventSidebar from "../AddListingComponent/SchedulePackageEventSidebar/ScheduleListingSidebar";
import SelectInterestButton from "../AddListingComponent/SelectInterestButton";
import SelectInterestComponent from "../AddListingComponent/SelectInterestComponent";
import SessionScheduleSection from "../AddListingComponent/SessionScheduleSection";
import SpecialInstruction from "../AddListingComponent/SpecialInstruction";
import StickyDraftButton from "../AddListingComponent/StickyDraftButton";

// style
import "../../assets/scss/createListing.scss";
import VerifyEmailAlert from "../VerifyEmail/VerifyEmailAlert";
import VerifyEmailModal from "../VerifyEmail/VerifyEmailModal";
import PackageScheduleSection from "../AddListingComponent/PackageScheduleSection";
import PackageTypeSection from "../AddListingComponent/PackageTypeSection";
import PackageDiscardModal from "../AddListingComponent/PackageDiscardModal";

interface ListingFormProps {
  edit?: boolean;
}
type LoadingState = {
  draft: boolean;
  publish: boolean;
};
const EditListingForm = ({ edit }: ListingFormProps) => {
  const auth = useAppSelector((state) => state.auth);
  const { listingId } = useParams();

  const navigate = useNavigate();
  const location = useLocation();

  // form
  const {
    control,
    watch,
    setValue,
    getValues,
    formState: { errors, dirtyFields },
    reset,
    trigger,
  } = useForm({
    defaultValues: ListingFormDefaultValue,
    mode: "onChange",
  });

  // loading key
  const [isDataLoading, setIsDataLoading] = useState<boolean>(false);
  const [loadingState, setLoadingState] = useState<LoadingState>({
    draft: false,
    publish: false,
  });

  // media upload key
  const [images, setImages] = useState([]); // media
  const [mediaUploadPayload, setMediaUploadPayload] = useState<any>([]); // payload: temp media upload
  const [isMediaUploadComplete, setIsMediaUploadComplete] = useState(null);
  const [isMediaUploadInprogress, setIsMediaUploadInprogress] = useState(false);

  // payment-keys
  const [isRecommend, setIsRecommend] = useState<boolean>(true);
  const [paymentMode, setPaymentMode] = useState<any>(null);

  // session-keys only
  const [sessionScheduleData, setSessionScheduleData] = useState<any>(null);
  // package data
  const [packageScheduleData, setPackageScheduleData] = useState<any>([]);

  // package-keys only
  const [editEvent, setEditEvent] = useState<any>(false); // edit a event in package
  const [editEventId, setEditEventId] = useState<any>(null);
  const [multipleScheduleListing, setMultipleScheduleListing] =
    useState(false);
  const [packageLocation, setPackageLocation] = useState(null);
  const [showEventListingsSidebar, setShowEventListingsSidebar] =
    useState<boolean>(false);

  // location key
  const [isLocationAccessible, setIsLocationAccessible] = useState<any>(false);
  const [locationData, setLocationData] = useState(null);

  // tabs
  const [isInterestTabOpen, setIsInterestTabOpen] = useState(false); // interest-tab
  const [isPaymentOpen, setIsPaymentOpen] = useState<any>(false); // payment-option-tab
  const [isScheduleDatesSection, setIsScheduleDatesSection] =
    useState<any>(false); // session-schedule-dates-tab

  const [isAddEventTabOpen, setIsAddEventTabOpen] = useState(false);

  // modals
  const [showAddEventSidebar, setShowAddEventSidebar] = useState<any>(false);
  const [confirmSaveEventInPackage, setConfirmSaveEventInPackage] =
    useState(false); // confirm package event save modal
  const [showDraftPopUp, setShowDraftPopUp] = useState(false);

  // other key
  const currUserInfo = auth?.moreUserDetails || {}; // user details
  const userType = auth?.moreUserDetails?.userType; // user type
  const [listingDraftData, setListingDraftData] = useState<any>(null);
  const [commissionAmounts, setCommissionAmounts] = useState<any>(null); // platform fee

  const refInfo = useRef(null);
  const [togglePaymentHowInfo, setTogglePaymentHowInfo] = useState<any>(false);
  const [toggleFeeInfoShown, setToggleFeeInfoShown] = useState(false);
  const [isListingInfo, setIsListingInfo] = useState<any>(false);
  const [paymentInfo, setPaymentInfo] = useState<any>(false);
  const [isBookingInfo, setIsisBookingInfo] = useState<any>(false);

  // ends of key declaration

  // redirection & navigation
  // declare and getting the params
  const [searchParams, setSearchParams] = useSearchParams();
  const screenSection = searchParams.get("section");
  const [showOtpModal , setShowOtpModal] = useState(false);

  const handleScreenSection = (type) => {
    setSearchParams({ section: type });
  };

  // main back btn function
  const handleMainBack = () => {
    if (getDisableData()) {
      setShowDraftPopUp(false);
      navigate(-1);
    } else {
      setShowDraftPopUp(true);
    }
  };

  // navigation action on params change
  useEffect(() => {
    switch (screenSection) {
      case "schedule":
        setIsScheduleDatesSection(true);
        setIsAddEventTabOpen(false);
        break;
      case "interest":
        setIsInterestTabOpen(true);
        break;
      case "package-event":
        setIsAddEventTabOpen(true);
        setIsScheduleDatesSection(false);
        break;
      case "payment":
        if (
          userType !== USER_TYPE.GUEST ||
          watch(apiConstants.listingType) !== LISTING_TYPE.C
        ) {
          setIsPaymentOpen(true);
        } else {
          setIsInterestTabOpen(false);
          setIsPaymentOpen(false);
          setIsScheduleDatesSection(false);
          setIsAddEventTabOpen(false);
        }
        break;
      default:
        setIsInterestTabOpen(false);
        setIsPaymentOpen(false);
        setIsScheduleDatesSection(false);
        setIsAddEventTabOpen(false);

        break;
    }
  }, [searchParams]);

  // form hook dirty fields
  const handleSetValue = (field: string, value: any) => {
    setValue(field, value, { shouldDirty: true });
  };

  // function: disable loading
  const disableLoading = (type) => {
    setTimeout(() => {
      switch (type) {
        case "data":
          setIsDataLoading(false);
          break;
        case "inline":
          setIsDataLoading(false);
          break;
        default:
          break;
      }
    }, 0);
  };

  // save as draft
  const handleSaveAsDraft = () => {
    if (!getDisableData()) {
      handleSetValue(apiConstants.isDraft, true);
      setTimeout(() => {
        handleNext({ isDraft: true });
      }, 500);
    }
  };

  // publish listing
  const handlePublishEvent = async () => {
    if (!auth?.moreUserDetails?.isEmailVerified) {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
      return showToast("Please verify your email.", "error");
      
    } else {
      await handleNext({ isDraft: false });
      handleSetValue(apiConstants.isDraft, false);
    }
  };

  // final add interest
  const handleInterestSubmit = async (value) => {
    handleSetValue(apiConstants.selectedInterest, value);
    setTimeout(() => {
      navigate(-1);
    }, 0);
  };

  // remove interest
  const handleRemoveInterest = (id) => {
    let tempData = watch(apiConstants.selectedInterest)?.filter(
      (item) => item?.id !== id
    );
    handleSetValue(apiConstants.selectedInterest, tempData);
  };

  //  toggle interest tab
  const toggleInterestTab = () => {
    isMediaUploadInprogress
      ? showMediaUploadInProgressWarning()
      : handleScreenSection("interest");
  };

  //  toggle package schedule tab
  const togglePackageTab = () => {
    isMediaUploadInprogress
      ? showMediaUploadInProgressWarning()
      : handleScreenSection("package-event");
  };

  // handle save event in package
  const handleSaveEvent = () => {
    setConfirmSaveEventInPackage(false);
    const virtual =
      watch(apiConstants.listing_event_type) === ACTIVITY_TYPE.VIRTUAL;

    const tempData = generateEventObject(watch, getValues, virtual);
    const multipleEvents = getValues(apiConstants.multipleEvents) || [];

    if (editEvent) {
      const updatedEvents = multipleEvents.map((event, index) => {
        return index === editEventId ? tempData : event;
      });
      setValue(apiConstants.multipleEvents, updatedEvents);
      setEditEvent(false);
      setEditEventId(null);
    } else {
      setValue(apiConstants.multipleEvents, [...multipleEvents, tempData]);
    }

    // clear form fields
    const fieldsToClear = [
      apiConstants.event_title,
      apiConstants.city,
      apiConstants.state,
      apiConstants.country,
      apiConstants.postCode,
      apiConstants.address,
      apiConstants.listing_location,
      apiConstants.event_start_date,
      apiConstants.event_start_time,
      apiConstants.event_end_date,
      apiConstants.event_end_time,
      apiConstants.schedule_date,
      apiConstants.event_date,
      apiConstants?.virtual_links_package,
    ];

    fieldsToClear.forEach((field) => setValue(field, ""));
    setPackageLocation(null);
  };

  // fetching draft data
  const fetchDraftData = async () => {
    if (!listingId) return;

    if (listingId) {
      setIsDataLoading(true);
      await makeGetCall({
        url: apiConfig.listing + `${listingId}/edit-listing/`,
      })
        .then(async (res) => {
          const data = res?.data || {};
          if (data?.scheduleDetails?.eventDates?.length > 0) {
            if (
              data.scheduleDetails.eventDates.some((item) =>
                moment(item, "YYYY-MM-DD").isBefore(moment().startOf("day"))
              )
            ) {
              data.scheduleDetails.eventDates = [];
            }
          }

          if (data?.scheduleDetails?.events?.length > 0) {
            if (
              data.scheduleDetails?.events?.some((item) =>
                moment(item?.date, "YYYY-MM-DD").isBefore(
                  moment().startOf("day")
                )
              )
            ) {
              data.scheduleDetails.events = [];
            }
          }
          const scheduleDetails = data?.scheduleDetails || {};
          const address = data?.listingType !== LISTING_TYPE.M && data?.listingEventType === ACTIVITY_TYPE.PHYSICAL ? scheduleDetails?.address : {};
          setListingDraftData(data);

          const temp = mapDraftResponseToTemp(
            data,
            scheduleDetails,
            address,
            userType,
            setSessionScheduleData,
            setPackageScheduleData
          );

          setImages(data.documents || []);
          setPaymentMode(data.paymentMode);

          if (data.listingType !== LISTING_TYPE.M) {
            setLocationData(address.address);
          }

          await reset(temp);

          temp[apiConstants.user_type] = userType;
        })
        .catch((err) => {
          console.error(err);
        })
        .finally(() => {
          disableLoading("data");
        });
    }
  };

  // fetch data before ui loads
  useLayoutEffect(() => {
    if (edit) {
      fetchDraftData();
    } else {
      if (!location?.state?.listingType) {
        navigate(ROUTE_CONSTANTS.select_listing, { replace: true });
      }
      setValue(apiConstants.listingType, location?.state?.listingType);
    }
  }, []);

  // handle disable btn
  const getDisableData = () => {
    return validateDataForDisableCase(
      getValues,
      watch,
      sessionScheduleData,
      currUserInfo
    );
  };

  // save listing
  const handleSaveListing = (
    listingId: string | null,
    payload: any,
    draft: boolean
  ) => {
    const isCreating = !listingId;
    const apiUrl = listingId
      ? listingDraftData?.listingType === LISTING_TYPE.M && watch(apiConstants.package_type) === PACKAGE_TYPE.S
        ? `${apiConfig.package_listing}${listingId}?version=v3`
        : `${apiConfig.listing}${listingId}?version=v3`
      : watch(apiConstants.listingType) === LISTING_TYPE.M && watch(apiConstants.package_type) === PACKAGE_TYPE.S
      ? `${apiConfig.package_listing}`
      : `${apiConfig.listing}?version=v4`;

    const apiCall = isCreating ? makePostCall : makePatchCall;

    setLoadingState({
      draft,
      publish: !draft,
    });

    const handleResponse = (res: any) => {
      setShowDraftPopUp(false);
      setLoadingState({
        draft: false,
        publish: false,
      });

      if (res?.status?.code === 200) {
        showToast(res?.status?.message, "success");
      } else {
        showToast(res?.status?.message, "error", "listing");
      }

      if (!draft) {
        navigate("/view-listing");
      } else {
        navigate(ROUTE_CONSTANTS.drafts);
      }
    };

    const handleError = (err: any) => {
      setShowDraftPopUp(false);

      setLoadingState({
        draft: false,
        publish: false,
      });

      showToast(err?.status?.message, "error", "listing");
    };

    apiCall({
      url: apiUrl,
      apiPayload: payload,
      content_type: "application/json",
    })
      .then(handleResponse)
      .catch(handleError);
  };

  const finalSubmit = (draft) => {
    const listingId = getValues(apiConstants.listingDetails)?.id;

    const payloadData = generateListingPayload({
      draft,
      getValues,
      watch,
      mediaUploadPayload,
      sessionScheduleData,
      packageScheduleData,
      userType,
    });

    handleSaveListing(listingId, payloadData, draft);
  };

  const showMediaUploadInProgressWarning = () => {
    return showToast(MESSAGE.MEDIA_UPLOAD_PROGRESS, "error");
  };

  // publish button validation
  const validations = getValidations({
    watch,
    locationData,
    sessionScheduleData,
    packageScheduleData,
    currUserInfo,
  });

  // button click (publish or draft)
  const handleNext = async ({ isDraft }: any) => {
    if (!isDraft) {
      for (const { condition, message } of validations || []) {
        if (condition()) {
          showToast(message, "error");
          return;
        }
      }
    }

    let tempIntrestList: any = [];
    if (watch(apiConstants.selectedInterest)?.length > 0) {
      tempIntrestList = watch(apiConstants.selectedInterest).map(
        (item: any) => {
          return item.id;
        }
      );
      handleSetValue(apiConstants.interests, tempIntrestList);
    }

    if (isMediaUploadComplete !== null && !isMediaUploadComplete) {
      showToast(MESSAGE.MEDIA_UPLOAD_PROGRESS, "error");
      return;
    }

    if (isMediaUploadComplete || isMediaUploadComplete === null) {
      finalSubmit(isDraft);
    }
  };

  const resetListing = () => {
    reset({
      ...ListingFormDefaultValue,
      [apiConstants.listingType]: watch(apiConstants.listingType),
    });
    setImages([]);
    setMediaUploadPayload([]);
    setSessionScheduleData(null);
  };

  // media upload function
  const handleUploadFiles = async (files: any = []) => {
    let finalMedia: any = [];

    for (const val of files) {
      if (!val?.uploadDocumentUrl) {
        setIsMediaUploadInprogress(true);
        const res = await makeGetCall({
          url: `${listingId ? `${apiConfig.id_signed_url}/${listingId}`: apiConfig.signed_url}/${val.file.name}`,
        });

        if (res.status.code === 200) {
          const {
            AWSAccessKeyId = "",
            key = "",
            policy = "",
            signature = "",
          } = res?.data?.fields;
          const uploadedResponse = await handleFileUpload(
            val.file,
            res?.data?.url,
            key,
            AWSAccessKeyId,
            policy,
            signature,
            val?.tempId
          );
          finalMedia.push(uploadedResponse);
        }
      }
    }

    return finalMedia;
  };

  // upload queue
  const getMediaUrlForUpload = async (uploadedMedia: any) => {
    let uploadResponse = null;
    const updatedMedia = uploadedMedia?.filter(
      (media) =>
        !mediaUploadPayload?.some(
          (payload) => payload?.tempId === media?.tempId
        )
    );
    if (updatedMedia?.length > 0) {
      setIsMediaUploadComplete(false);
      uploadResponse = await handleUploadFiles(updatedMedia);
      if (uploadResponse) {
        let finalResponse = [...mediaUploadPayload, ...uploadResponse];
        setMediaUploadPayload(finalResponse);
        setIsMediaUploadInprogress(false);
        setIsMediaUploadComplete(true);
      }
    }
  };

  const handleMediaFIles = (media: any) => {
    setValue(apiConstants.media, media);
    getMediaUrlForUpload(media);
  };

  useOutsideClick(refInfo, () => {
    setIsListingInfo(false);
    setPaymentInfo(false);
    setIsisBookingInfo(false);
    setTogglePaymentHowInfo(false);
  });

  const onDeleteEvent = (index) => {
    if (watch(apiConstants.package_type) === PACKAGE_TYPE.S) {
      const data = packageScheduleData?.filter((_, idx) => idx !== index);
      setPackageScheduleData(data);
    } else {
      setPackageScheduleData(null);
    }
  };

  // payment tab stripe
  const handleConnectStripe = () => {
    makePostCall({ url: apiConfig.connect_stripe })
      .then((res) => {
        if (res?.status.code === 200) {
          window.open(res?.data, "_blank", "rel=noopener noreferrer");
          showToast(res.status.message, "success");
        }
      })
      .catch((err) => {
        showToast(err?.status?.message, "error");
      });
  };

  // package edit an event
  const handleEventEditClick = (event: any, eventIndex: any) => {
    setEditEvent(true);
    setEditEventId(eventIndex);

    // Address-related values
    const { address = {} } = event;
    setValue(apiConstants.city, address?.city);
    setValue(apiConstants.state, address?.state);
    setValue(apiConstants.country, address?.country);
    setValue(apiConstants.postCode, address?.postCode);
    setValue(apiConstants.address, address?.address);
    setValue(apiConstants.listing_location, address?.location);
    setPackageLocation(address?.address);

    // Event-related values
    setValue(apiConstants.event_title, event?.title);
    setValue(
      apiConstants.event_start_date,
      moment(event?.date, DATE_FORMAT.YYYYMMDD_FORMAT).format(
        DATE_FORMAT.DATE_MONTH_FORMAT
      )
    );
    setValue(
      apiConstants.event_date,
      moment(event?.date, DATE_FORMAT.YYYYMMDD_FORMAT).format(
        DATE_FORMAT.DATE_MONTH_FORMAT
      )
    );
    setValue(apiConstants.virtual_links_package, event?.virtual_links_package);

    // Time formatting using moment
    const formatTime = (date, time) =>
      moment(`${date} ${time}`, "YYYY-MM-DD HH:mm:ss.SSSSSS").format(
        "YYYY-MM-DD HH:mm:ss.SSSSSS"
      );

    setValue(
      apiConstants.event_start_time,
      formatTime(event?.date, event?.start_time)
    );
    setValue(
      apiConstants.event_end_time,
      formatTime(event?.date, event?.end_time)
    );

    setShowEventListingsSidebar(false);
    setShowAddEventSidebar(true);
  };

  // optimization pending

  const selectedPreferences = Array.isArray(watch("gender_preference"))
    ? watch("gender_preference")
    : ["M", "F", "N"];

  const selectedPreferencesDifficulty = Array.isArray(watch("difficulty_level"))
    ? watch("difficulty_level")
    : watch(apiConstants.difficulty_level);

  const handleGender = (gender: any) => {
    const tempList = watch(apiConstants.selectedGender) || [];
    let index = tempList.findIndex((gen: any) => gen === gender);
    if (index > -1) {
      tempList.splice(index, 1);
    } else {
      tempList.push(gender);
    }
    handleSetValue(apiConstants.selectedGender, [...tempList]);
  };
  const handleDifficulty = (level: any) => {
    const tempList = watch(apiConstants.selecteddifficulty) || [];
    let index = tempList.findIndex((lev: any) => lev === level);
    if (index > -1) {
      tempList.splice(index, 1);
    } else {
      tempList.push(level);
    }
    handleSetValue(apiConstants.selecteddifficulty, [...tempList]);
  };

  useEffect(() => {
    handleSetValue(
      apiConstants.selecteddifficulty,
      selectedPreferencesDifficulty
    );
    handleSetValue(apiConstants.selectedGender, selectedPreferences);
  }, []);

  // fetch commission cost
  useEffect(() => {
    makeGetCall({ url: apiConfig.commission_amounts })
      .then((res) => {
        if (res.status.code === 200) {
          setCommissionAmounts(res.results);
        }
      })
      .catch((err) => {
        showToast(err, "error");
      });
  }, []);

  // update or save package event
  const handleUpdateOrSaveEvent = () => {
    let meetingError = false;
    if (
      watch(apiConstants.listing_event_type) === ACTIVITY_TYPE.VIRTUAL &&
      watch(apiConstants.multiple_virtual_links_allowed) === false &&
      watch(apiConstants.virtual_links_package)
    ) {
      const data = validateMeetingLink(
        watch(apiConstants.virtual_links_package)
      );
      if (!data) {
        meetingError = true;
      }
    }
    //
    if (!watch(apiConstants.event_title)) {
      showToast(MESSAGE.LISTING_NAME_REQUIRED, "error");
    } else if (
      watch(apiConstants.listing_event_type) === ACTIVITY_TYPE.PHYSICAL &&
      !watch(apiConstants.address)
    ) {
      showToast(MESSAGE.LOCATION_REQUIRED, "error");
    } else if (
      watch(apiConstants.listing_event_type) === ACTIVITY_TYPE.PHYSICAL &&
      watch(apiConstants.address) !== packageLocation
    ) {
      showToast(MESSAGE.LOCATION_FROM_SUGGESTION, "error");
    } else if (
      watch(apiConstants.listing_event_type) === ACTIVITY_TYPE.VIRTUAL &&
      watch(apiConstants.multiple_virtual_links_allowed) === false &&
      !watch(apiConstants.virtual_links_package)
    ) {
      showToast(MESSAGE.VIRTUAL_LINK_REQUIRED, "error");
    } else if (meetingError) {
      showToast(MESSAGE.INVALID_VIRTUAL_LINK, "error");
    } else if (
      !watch(apiConstants.event_start_time) ||
      !watch(apiConstants.event_end_time)
    ) {
      showToast(MESSAGE.SCHEDULE_DATES_REQUIRED, "error");
    } else {
      setShowAddEventSidebar(false);
      setConfirmSaveEventInPackage(true);
    }
  };

  // call schedule listing
  const handleScheduleListing = async () => {
    await handleScreenSection("schedule");
  };

  // runtime participant validate
  useEffect(() => {
    if (watch(apiConstants.max_participants)) {
      const length = watch(apiConstants.max_participants)?.length;
      if (
        watch(apiConstants.max_participants) > 4 &&
        currUserInfo?.userType === USER_TYPE.GUEST &&
        length > 0
      ) {
        showToast(MESSAGE.MAX_GUEST_PARTICIPANTS, "error");
        handleSetValue(apiConstants.max_participants, 4);
      } else if (
        watch(apiConstants.max_participants) > MAX_PARTICIPANTS.VIRTUAL &&
        watch(apiConstants.listing_event_type) === ACTIVITY_TYPE.VIRTUAL &&
        length > 0
      ) {
        showToast(MESSAGE.MAX_VIRTUAL_PARTICIPANTS, "error");
        handleSetValue(apiConstants.max_participants, MAX_PARTICIPANTS.VIRTUAL);
      }
    }
  }, [
    watch(apiConstants.max_participants),
    watch(apiConstants.listing_event_type),
  ]);

  // validate listing cost
  const handleMinListingCost = () => {
    if (
      watch(apiConstants.listing_cost) &&
      watch(apiConstants.listing_cost) < 6 &&
      currUserInfo?.userType !== USER_TYPE.GUEST &&
      watch(apiConstants.listingType) !== LISTING_TYPE.C &&
      watch(apiConstants.listing_price_type) === PRICING_TYPE.PAID
    ) {
      showToast(MESSAGE.MINIMUM_COST, "error");
      handleSetValue(apiConstants.listing_cost, 6);
    } else if (
      watch(apiConstants.listing_cost) &&
      watch(apiConstants.listing_cost) > MAX_EVENT_COST &&
      currUserInfo?.userType !== USER_TYPE.GUEST &&
      watch(apiConstants.listingType) !== LISTING_TYPE.C &&
      watch(apiConstants.listing_price_type) === PRICING_TYPE.PAID
    ) {
      showToast(MESSAGE.MAXIMUM_COST, "error");
      handleSetValue(apiConstants.listing_cost, MAX_EVENT_COST);
    }
  };

  const handleSendOtpAndModal = () => {
    //integrate api here
    setShowOtpModal(true)
  }
  
  return (
    <>
      {isDataLoading && <LoadingLayer />}
      {(loadingState.draft || loadingState.publish) && (
        <ApiLoaderListing isLoadingDraft={loadingState.draft} />
      )}

      {!auth?.moreUserDetails?.isEmailVerified && (
        <div className="mt-3">
          <VerifyEmailAlert handleVerifyClick={() => handleSendOtpAndModal()} />
        </div>
      )}

      {isPaymentOpen ? (
        <ListingPayout
          setIsPaymentOpen={setIsPaymentOpen}
          handleClose={() => navigate(-1)}
          isRecommend={isRecommend}
          setIsRecommend={setIsRecommend}
          paymentMode={paymentMode}
          setPaymentMode={setPaymentMode}
          setValue={setValue}
          control={control}
          errors={errors}
          handleConnectStripe={handleConnectStripe}
          watch={watch}
        />
      ) : isInterestTabOpen ? (
        <SelectInterestComponent
          interestPicked={watch(apiConstants?.selectedInterest)}
          handleAdd={handleInterestSubmit}
        />
      ) : isAddEventTabOpen ? (
        <PackageAddEvent
          handleScreenSection={handleScreenSection}
          packageScheduleData={packageScheduleData}
          setPackageScheduleData={setPackageScheduleData}
          isSingleVirtualLink={
            watch(apiConstants.listing_event_type) === ACTIVITY_TYPE.VIRTUAL
          }
          virtual={
            watch(apiConstants.listing_event_type) === ACTIVITY_TYPE.VIRTUAL &&
            watch(apiConstants.multiple_virtual_links_allowed) === false
          }
        />
      ) : isScheduleDatesSection ? (
        <ScheduleDatesLayout
          virtual={
            watch(apiConstants.listing_event_type) === ACTIVITY_TYPE.VIRTUAL &&
            watch(apiConstants.multiple_virtual_links_allowed) === false
          }
          sessionScheduleData={sessionScheduleData}
          setSessionScheduleData={setSessionScheduleData}
          isPackage={watch(apiConstants.listingType) === LISTING_TYPE.M}
        />
      ) : (
        <div className="user-profile create_listing create_listing_main">
          <div className="user-white-part-width p-0">
            <div className="user-white-part-large listing_types">
              <div
                className="lsiting-detials"
                style={{
                  paddingBottom: "24px",
                }}
              >
                {/* listing heading */}
                <ListingHeading
                  watch={watch}
                  handleMainBack={handleMainBack}
                  handleSaveAsDraft={handleSaveAsDraft}
                  published={listingDraftData?.isActive}
                  resetListing={resetListing}
                />

                {/* file upload */}
                <FilesUploadComponent
                  handleMediaFIles={handleMediaFIles}
                  images={images}
                  setImages={setImages}
                  isMediaUploadComplete={isMediaUploadComplete}
                  mediaUploadPayload={mediaUploadPayload}
                  setMediaUploadPayload={setMediaUploadPayload}
                />

                {/* listing heading & description */}
                <ListingDetailsForm
                  watch={watch}
                  control={control}
                  errors={errors}
                  setValue={setValue}
                  getValues={getValues}
                  isListingInfo={isListingInfo}
                  setIsListingInfo={setIsListingInfo}
                />

                {/* listing interest */}
                <SelectInterestButton
                  isOutside={false}
                  handleClick={toggleInterestTab}
                  list={watch(apiConstants.selectedInterest) || []}
                  handleRemoveInterest={handleRemoveInterest}
                />

                {/* listing type virtual physical */}
                <ListingTypeForm
                  watch={watch}
                  handleSetValue={handleSetValue}
                  control={control}
                  setValue={setValue}
                />

                {/* listing special instruction */}
                <SpecialInstruction watch={watch} setValue={setValue} />

                {/* package type */}
                <PackageTypeSection
                  watch={watch}
                  setValue={setValue}
                  disableRadio={!listingDraftData?.isDraft && edit}
                />

                {/* {edit && listingDraftData?.isDraft && <PackageDiscardModal />} */}

                {/* package event section */}
                {watch(apiConstants.package_type) === PACKAGE_TYPE.S ? (
                  <PackageScheduleSection
                    watch={watch}
                    onDeleteEvent={onDeleteEvent}
                    togglePackageTab={togglePackageTab}
                    packageScheduleData={packageScheduleData}
                  />
                ) : (
                  <PackageEventSection
                    watch={watch}
                    getValues={getValues}
                    onDeleteEvent={onDeleteEvent}
                    handleEventEditClick={handleEventEditClick}
                    setShowAddEventSidebar={setShowAddEventSidebar}
                    setShowEventListingsSidebar={setShowEventListingsSidebar}
                  />
                )}

                {/* add location */}
                <AddLocationSection
                  watch={watch}
                  setIsLocationAccessible={setIsLocationAccessible}
                  isLocationAccessible={isLocationAccessible}
                  handleLocation={handleLocation}
                  handleSetValue={handleSetValue}
                  setValue={setValue}
                  control={control}
                  setLocationData={setLocationData}
                />

                {/* member free/paid listing */}
                <ListingFreePaidType
                  watch={watch}
                  handleSetValue={handleSetValue}
                  userType={userType}
                />

                {/* listing participants and const */}
                <ListingAmountSection
                  userType={userType}
                  watch={watch}
                  control={control}
                  setValue={setValue}
                  paymentInfo={paymentInfo}
                  setPaymentInfo={setPaymentInfo}
                  currUserInfo={currUserInfo}
                  commissionAmounts={commissionAmounts}
                  toggleFeeInfoShown={toggleFeeInfoShown}
                  setToggleFeeInfoShown={setToggleFeeInfoShown}
                  handleMinListingCost={handleMinListingCost}
                />

                {/* listing session schedule */}
                <SessionScheduleSection
                  watch={watch}
                  isMediaUploadInprogress={isMediaUploadInprogress}
                  showMediaUploadInProgressWarning={
                    showMediaUploadInProgressWarning
                  }
                  handleScheduleListing={handleScheduleListing}
                  sessionScheduleData={sessionScheduleData}
                />

                {/* listing payout section */}
                <PayoutSection
                  userType={userType}
                  watch={watch}
                  getValues={getValues}
                  isMediaUploadInprogress={isMediaUploadInprogress}
                  showMediaUploadInProgressWarning={
                    showMediaUploadInProgressWarning
                  }
                  handleScreenSection={handleScreenSection}
                  togglePaymentHowInfo={togglePaymentHowInfo}
                  setTogglePaymentHowInfo={setTogglePaymentHowInfo}
                />

                {/* listing cancellation */}
                <CancellationSection
                  userType={userType}
                  watch={watch}
                  handleSetValue={handleSetValue}
                  isBookingInfo={isBookingInfo}
                  setIsBookingInfo={setIsisBookingInfo}
                />

                {/* listing age */}
                <AgePreferenceSection
                  watch={watch}
                  handleSetValue={handleSetValue}
                  control={control}
                />

                {/* listing gender */}
                <GenderPreferenceSection
                  watch={watch}
                  handleSetValue={handleSetValue}
                  control={control}
                  handleGender={handleGender}
                />

                <DifficultyLevelSection
                  control={control}
                  setValue={setValue}
                  handleDifficulty={handleDifficulty}
                  selectedPreferencesDifficulty={selectedPreferencesDifficulty}
                />

                <ListingAction
                  getDisableData={getDisableData}
                  loadingState={loadingState}
                  handleSaveAsDraft={handleSaveAsDraft}
                  listingDraftData={listingDraftData}
                  handlePublishEvent={handlePublishEvent}
                />
              </div>
              {!edit && (
                <StickyDraftButton
                  getDisableData={getDisableData}
                  loadingState={loadingState}
                  handleSaveAsDraft={handleSaveAsDraft}
                />
              )}
            </div>
          </div>
        </div>
      )}

      {/* modal - save as draft */}
      {showDraftPopUp && (
        <ConfirmDraft
          title={
            listingDraftData?.isActive
              ? HEADINGS.SAVE_PUBLISH_EDITED
              : HEADINGS.SAVE_DRAFT
          }
          btnTxt={BUTTON_TEXT.SAVE}
          discard={() => {
            navigate(-1);
          }}
          onClose={() => setShowDraftPopUp(false)}
          showDiscard={true}
          draft={handleSaveAsDraft}
          published={listingDraftData?.isActive}
        />
      )}

      {/* modal - add package event */}
      {showAddEventSidebar && !showEventListingsSidebar && (
        <PackageAddEventSidebar
          showAddEventSidebar={showAddEventSidebar}
          setShowAddEventSidebar={setShowAddEventSidebar}
          setEditEvent={setEditEvent}
          editEvent={editEvent}
          setValue={setValue}
          control={control}
          watch={watch}
          setPackageLocation={setPackageLocation}
          handleLocation={handleLocation}
          setMultipleScheduleListing={setMultipleScheduleListing}
          handleSetValue={handleSetValue}
          isLocationAccessible={isLocationAccessible}
          setIsLocationAccessible={setIsLocationAccessible}
          handleUpdateOrSaveEvent={handleUpdateOrSaveEvent}
        />
      )}

      {/* modal - schedule package event */}
      {multipleScheduleListing && (
        <SchedulePackageEventSidebar
          setMultipleScheduleListing={() => {
            setMultipleScheduleListing(false);
            setShowAddEventSidebar(true);
          }}
          control={control}
          handleSetValue={handleSetValue}
          watch={watch}
          getValues={getValues}
          trigger={trigger}
          errors={errors}
          dirtyFields={dirtyFields}
        />
      )}

      {/* modal - confirm package event */}
      {confirmSaveEventInPackage ? (
        <SavePackageEvent
          title={HEADINGS.SAVE_EVENT}
          onClose={() => {
            setConfirmSaveEventInPackage(false);
            setEditEvent(false);
          }}
          reCheck={() => {
            setConfirmSaveEventInPackage(false);
            setShowAddEventSidebar(true);
          }}
          onContinue={() => handleSaveEvent()}
        />
      ) : null}

      {/* modal - view package event */}
      {showEventListingsSidebar && (
        <PackageListingsSidebar
          showEventListingsSidebar={showEventListingsSidebar}
          setShowEventListingsSidebar={setShowEventListingsSidebar}
          getValues={getValues}
          onDeleteEvent={onDeleteEvent}
          handleEventEditClick={handleEventEditClick}
          setShowAddEventSidebar={setShowAddEventSidebar}
          watch={watch}
        />
      )}

      {showOtpModal && (
        <VerifyEmailModal
          showOtpModal={showOtpModal}
          handleNotShowOtpModal={() => setShowOtpModal(false)}
        />
      )}
    </>
  );
};

export default EditListingForm;
