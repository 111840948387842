import React, { useState } from "react";
import AllInterestModal from "./AllInterestModal";
import "./style.scss";

type Props = {
  [key: string]: any;
};

const SelectInterestButton = (props: Props) => {
  const { handleClick, list, handleRemoveInterest, isOutside } = props;
  const [modalAllInterest, setModalAllInterest] = useState(false);
  return (
    <div style={{ marginBottom: "24px" }}>
      {isOutside ? (
        <div className="f-title font_f">Filter by interest</div>
      ) : (
        <div className="interest_heading font_os">Add interest</div>
      )}

      <div
        className={
          isOutside
            ? "interest_btn_wrap interest_btn_wrap-2 d-flex justify-content-between "
            : "interest_btn_wrap d-flex justify-content-between"
        }
        onClick={handleClick}
      >
        <div className="select_payout_heading font_os">
          Click here to select the interest
        </div>
        <img src="/static/createListingIcon/forwardGrey.svg" alt="" />
      </div>
      {list?.length > 0 && (
        <>
          <div className="d-none d-md-flex interest_list">
            {list?.slice(0, 3)?.map((item, idx) => (
              <div
                className="list_chip special_w_limit"
                key={item?.title + idx}
              >
                <img src={item?.icon} alt="" />
                <div className="chip_title font_os">{item?.title}</div>
                <img
                  src="/static/createListingIcon/clearGrey.svg"
                  className="cursor-pointer"
                  alt=""
                  onClick={() => handleRemoveInterest(item.id)}
                />
              </div>
            ))}
            {list?.length > 3 && (
              <div
                className="list_chip button_chip"
                onClick={() => setModalAllInterest(true)}
              >
                <div>{`+${list?.length - 3} more`}</div>
              </div>
            )}
          </div>
          <div className="d-md-none d-flex overflow-y-hidden interest_list">
            {list?.slice(0, 2)?.map((item, idx) => (
              <div
                className="list_chip special_w_limit"
                key={item?.title + idx}
              >
                <img src={item?.icon} alt="" />
                <div className="chip_title font_os">{item?.title}</div>
                <img
                  src="/static/createListingIcon/clearGrey.svg"
                  className="cursor-pointer"
                  alt=""
                  onClick={() => handleRemoveInterest(item.id)}
                />
              </div>
            ))}
            {list?.length > 2 && (
              <div
                className="list_chip button_chip"
                onClick={() => setModalAllInterest(true)}
              >
                <div>{`+${list?.length - 2} more`}</div>
              </div>
            )}
          </div>
        </>
      )}
      {modalAllInterest && list?.length > 2 && (
        <AllInterestModal
          show={modalAllInterest}
          handleClose={() => setModalAllInterest(false)}
          list={list}
          handleRemoveInterest={handleRemoveInterest}
        />
      )}
    </div>
  );
};

export default SelectInterestButton;
