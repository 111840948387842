import React from "react";
import { Button, ButtonProps } from "@mui/material";
import { styled } from "@mui/system";

interface SecondaryButtonProps extends ButtonProps {
  maxWidth?: any;
  minWidth?: any;
  loading?: boolean;
}

const StyledButton = styled(Button)(({ maxWidth }: SecondaryButtonProps) => ({
  backgroundColor: "#FFFFFF",
  color: "#25272D",
  fontFamily: "Open sans, sans-serif",
  fontSize: "18px",
  fontWeight: 500,
  lineHeight: "24px",
  textAlign: "center",
  textTransform: "capitalize",
  padding: "16px 44px",
  borderRadius: "12px",
  border: "1px solid #DCDCDC",
  boxShadow: "0px 4px 32px 0px #00000014",
  "&:hover": {
    backgroundColor: "#F5F5F5",
  },
  "@media screen and (max-width:768px)": {
    padding: "16px",
  },
}));

const SecondaryButton = ({
  children = "Submit",
  maxWidth = false,
  minWidth = false,
  loading = false,
  onClick,
  ...restProps
}: SecondaryButtonProps) => {
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (loading) return;
    if (onClick) onClick(event);
  };

  return (
    <StyledButton
      sx={{
        maxWidth: typeof minWidth === "string" ? minWidth : "auto",
        minWidth: typeof minWidth === "string" ? minWidth : "fit-content",
      }}
      variant="contained"
      onClick={handleClick}
      {...restProps}
    >
      {children}
    </StyledButton>
  );
};

export default SecondaryButton;
