import React, { useEffect, useRef } from "react";
import FlaggedUserCard from "./FlaggedUserCard";
import ConnectUserCard from "./ConnectUserCard";
import { CONNECTION_TYPE } from "../ConnectConstants";
import "./MapViewStyle.scss";
import { EventsMapCard } from "../../Dashboard/Event/EventsMapCard";
// import Slider from "react-slick";
// import "slick-carousel/slick/slick.css";
// import "slick-carousel/slick/slick-theme.css";


type Props = {
  multipleEventData?: any[];
  mode?: string;
  show?: any;
  hide?: any;
};

// function SampleNextArrow(props) {
//   const { className, style, onClick } = props;
//   return (
//     <img
//       className="image-carousel-button next_arrow map_next_arrow"
//       style={{
//         background: "orange",
//         borderRadius: "50%",
//         border: "none",
//         cursor: "pointer",
//         height: 24,
//         width: 24,
//         position: "absolute",
//         zIndex: "0",
//         top: "98%",
//         justifyContent: "center",
//         alignItems: "center",
//       }}
//       onClick={onClick}
//       alt=""
//       src="/static/chevron-black-right.svg"
//     />
//   );
// }

// function SamplePrevArrow(props) {
//   const { className, style, onClick } = props;
//   return (
//     <img
//       className="image-carousel-button prev_arrow map_prev_arrow"
//       style={{
//         background: "orange",
//         borderRadius: "50%",
//         border: "none",
//         cursor: "pointer",
//         height: 24,
//         width: 24,
//         position: "absolute",
//         zIndex: "99",
//         top: "98%",
//         justifyContent: "center",
//         alignItems: "center",
//       }}
//       onClick={onClick}
//       alt=""
//       src="/static/chevron-black-left.svg"
//     />
//   );
// }

const MapViewMultiCardLayout = ({
  multipleEventData = [],
  mode,
  show,
  hide = () => null,
}: Props) => {
  const modalRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        hide(false); // Close the modal
      }
    };

    if (show) {
      document.addEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [show]);

  // var settings = {
  //   dots: false,
  //   infinite: true,
  //   variableWidth: true,
  //   // infinite: multipleEventData.length > 1,
  //   // slidesToShow: 4,
  //   // slidesToShow: Math.min(multipleEventData.length, 4),
  //   slidesToScroll: 1,
  //   initialSlide: 0,
  //   prevArrow: <SamplePrevArrow/>,
  //   nextArrow: <SampleNextArrow/>,
  // };


  return (
    <div className="layout_map_view_card" ref={modalRef}>
      <div className="map_view_card_wrap">
      {/* <div className="slider-container" style={{ width: '100%'}}>
      <Slider {...settings}> */}
        {multipleEventData.map((val, index) => {
          return (
            <div key={index} className="px-2">
              {mode === "event" ? (
                <EventsMapCard
                  item={val}
                  showEvent={show}
                  onClickOutside={() => {
                    // hide(false);
                    return;
                  }}
                />
              ) : mode === CONNECTION_TYPE.Flagged ? (
                <FlaggedUserCard connectionData={val} />
              ) : (
                <ConnectUserCard
                  connectionData={val}
                  mode={mode}
                  isScrollable={false}
                />
              )}
            </div>
          );
        })}
      {/* </Slider>
      </div> */}
      </div>
    </div>
  );
};

export default MapViewMultiCardLayout;
