import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import Skeleton from "@mui/material/Skeleton";

const ConnectUserCardShimmer = () => {
  return (
    <Card
      sx={{
        width: 295,
        height: 398,
        position: "relative",
        border: "1px solid #dcdcdc",
        borderRadius: 3,
        backgroundColor: "rgba(255, 255, 255, 0.13)",
      }}
    >
      <CardHeader
        avatar={
          <Skeleton
            variant="circular"
            width={140}
            height={140}
            sx={{
              backgroundColor: "rgb(152 149 145 / 24%)",
              position: "absolute",
              top: 42,
              left: 16,
            }}
          />
        }
        sx={{height: 150, width: 295, backgroundColor: "antiquewhite"}}
      ></CardHeader>
      <CardContent
        sx={{
          backgroundColor: "rgba(255, 255, 255, 0.13)",
        }}
      >
        <Skeleton
          height={18}
          width={66}
          style={{borderRadius: 6, position: "absolute", top: 163, right: 10}}
        />
        <div style={{display: "flex", gap: 4}}>
          <Skeleton
            animation="wave"
            height={32}
            width={195}
            style={{borderRadius: 4, marginTop: 30}}
          />
        </div>
        <div
          style={{
            display: "flex",
            marginTop: "8px",
            gap: "8px",
            alignItems: "center",
          }}
        >
          <Skeleton height={30} width={16} style={{borderRadius: 4}} />
          <Skeleton height={26} width={150} style={{borderRadius: 4}} />
        </div>
        <div
          style={{
            display: "flex",
            marginTop: "16px",
            gap: "8px",
            alignItems: "center",
          }}
        >
          <Skeleton height={28} width={66} style={{borderRadius: 6}} />
          <Skeleton height={28} width={156} style={{borderRadius: 6}} />
        </div>
        <div
          style={{
            display: "flex",
            marginTop: "16px",
            gap: "12px",
            alignItems: "center",
            position: "absolute",
            bottom: 20,
          }}
        >
          <Skeleton
            variant="circular"
            width={40}
            height={40}
            sx={{
              backgroundColor: "rgb(152 149 145 / 24%)",
            }}
          />
          <Skeleton variant="rounded" width={209} height={40} />
        </div>
      </CardContent>
    </Card>
  );
};

export default ConnectUserCardShimmer;
