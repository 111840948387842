import React, { useMemo } from "react";
import { LISTING_TYPE, USER_TYPE } from "../../../types";
import { PRICING_TYPE, apiConstants } from "../../../utils/Constants";
import { HEADINGS, STRING_CONSTANTS } from "../../../utils/StringConstant";
import InputField from "../../../global/InputField";
import InfoMessage from "../InfoMessage";
import ListingCommissionTable from "../ListingCommissionTable";

interface ListingAmountSectionProps {
  userType: string;
  watch: Function;
  control: any;
  setValue: any;
  paymentInfo: boolean;
  setPaymentInfo: Function;
  currUserInfo: any;
  commissionAmounts: any;
  toggleFeeInfoShown: boolean;
  setToggleFeeInfoShown: Function;
  handleMinListingCost: Function;
}

const ListingAmountSection = React.memo(
  ({
    userType,
    watch,
    control,
    setValue,
    paymentInfo,
    setPaymentInfo,
    currUserInfo,
    commissionAmounts,
    toggleFeeInfoShown,
    setToggleFeeInfoShown,
    handleMinListingCost,
  }: ListingAmountSectionProps) => {
    const isListingTypeC = watch(apiConstants.listingType) === LISTING_TYPE.C;
    const isGuestUser = userType === USER_TYPE.GUEST;
    const listingCost = watch(apiConstants.listing_cost);
    const listingPriceType = watch(apiConstants.listing_price_type);

    const maxParticipantsLabel = useMemo(() => {
      return isGuestUser || isListingTypeC
        ? HEADINGS.PARTICIPANTS
        : HEADINGS.PAYMENT_AND_SCHEDULING;
    }, [isGuestUser, isListingTypeC]);

    const listingCostLabel = useMemo(() => {
      return isListingTypeC ? HEADINGS.TEAM_LISTING_COST : HEADINGS.EVENT_COST;
    }, [isListingTypeC]);

    return (
      <>
        <div className="main-had" style={{ marginTop: "28px" }}>
          <h3 className="headis">{maxParticipantsLabel}</h3>
          <img
            src="/static/info.svg"
            alt=""
            className="info_icon"
            onClick={() => setPaymentInfo(!paymentInfo)}
          />
        </div>

        <div className="ps_container_wrap">
          <div
            className={
              isGuestUser || isListingTypeC
                ? "ps_content_inner full_width"
                : "ps_content_inner"
            }
          >
            <InputField
              type={"number"}
              className={"floating_input"}
              name={apiConstants.max_participants}
              inputLabel={"Max Participants"}
              placeholder={"Add your max participants"}
              control={control}
              errors={{}}
              isFixedNumber
              setValue={setValue}
              max={75}
            />
            {paymentInfo && (
              <InfoMessage message={STRING_CONSTANTS.max_participant_info} />
            )}
          </div>
          {!isGuestUser && !isListingTypeC && (
            <div className="ps_content_inner">
              <InputField
                type={"number"}
                className={"floating_input"}
                name={apiConstants.listing_cost}
                inputLabel={listingCostLabel}
                placeholder={"Add your joining cost"}
                control={control}
                errors={{}}
                setValue={setValue}
                isDecimalLimit
                disabled={listingPriceType === PRICING_TYPE.FREE}
                onBlur={() => handleMinListingCost()}
                decimalLimit
              />
              {paymentInfo && (
                <InfoMessage message={STRING_CONSTANTS.listing_cost_info} />
              )}
            </div>
          )}
        </div>

        {!isGuestUser &&
          listingCost > 0 &&
          listingPriceType === PRICING_TYPE.PAID && (
            <ListingCommissionTable
              listing_cost={listingCost}
              merchantFee={currUserInfo?.merchantFee}
              toggleFeeInfoShown={toggleFeeInfoShown}
              setToggleFeeInfoShown={setToggleFeeInfoShown}
              commissionAmounts={commissionAmounts}
              listingType={watch(apiConstants.listingType)}
            />
          )}
      </>
    );
  }
);

export default ListingAmountSection;
