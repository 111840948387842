import { useEffect, useState } from "react";
import { useParams, Link, useNavigate } from "react-router-dom";
import ApiLoader from "../../global/ApiLoader";
import {
  dateFormatter,
  formatDateRange,
  handleVideoClick,
  scrollToTop,
  showToast,
} from "../../utils";
import { makeGetCall, makeNextPageCall } from "../../utils/Requests";
import endpoints from "../../utils/endpoints";
import Button from "../../global/Button";
import GlobalHeader2 from "../Dashboard/GlobalHeader2";
import GlobalFooter from "../Dashboard/GlobalFooter";
import ReactPaginate from "react-paginate";
import BackArrow from "../../assets/img/icons/BackArrow";
import Pagination from "../ConnectUserProfile/Pagination";
import moment from "moment";
import { DATE_FORMAT } from "../../constants/helperScheduleDates";

const PromotionDetails = () => {
  const navigate = useNavigate();
  const [detailsData, setDetailsData] = useState<any>({});
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { id } = useParams();
  const [promotion, setPromotion] = useState<any>([]);
  const [nextUrl, setNextUrl] = useState<any>(null);
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [pageCount, setPageCount] = useState<number>(1);
  const [limit] = useState<number>(1);
  const [offset, setOffset] = useState<number>(0);

  const handlePageClick = (event) => {
    setCurrentPage(event.selected);
    setOffset(event.selected * limit);
    if (nextUrl) {
      makeNextPageCall({ url: nextUrl })
        .then((res) => {
          const tempData = [...promotion, ...res.results];
          setNextUrl(res?.next);
          setPageCount(res?.count);
          setPromotion(tempData as never);
        })
        .catch((err) => {
          showToast(err, "error");
        });
      scrollToTop();
    }
  };

  useEffect(() => {
    setIsLoading(true);
    makeGetCall({
      url: endpoints.listings_promotion + `${id}`,
    })
      .then((res) => {
        setDetailsData(res.data);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        showToast(err, "error");
      });
  }, [id]);

  const getPromotionData = () => {
    setPromotion([]);
    setIsLoading(true);
    makeGetCall({
      url: `${endpoints.listings_promotion}?exclude=${id}&limit=${limit}&offset=${offset}&version=v2`,
    })
      .then((res) => {
        if (res.status.code === 200) {
          setPromotion(res?.results);
          setNextUrl(res?.next);
          setPageCount(res?.count);
        } else {
          showToast(res.status.message, "error");
        }
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        showToast(err, "error");
      });
  };

  useEffect(() => {
    getPromotionData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  return (
    <>
      <GlobalHeader2 />
      <div className="promotion-detail-page">
        {isLoading ? (
          <div className="page_loader">
            <ApiLoader />
          </div>
        ) : (
          <>
            <div className="content-box">
              <div className="details-box">
                <div className="title">
                  <div
                    className="cursor-pointer"
                    onClick={() => navigate(`/promotions`)}
                  >
                    <BackArrow />
                  </div>
                  {detailsData.title}
                </div>
                <div>
                  {["mp4", "mov", "avi"].includes(
                    detailsData?.media?.split(".").splice(-1)[0].toLowerCase()
                  ) ? (
                    <>
                      <video
                        src={detailsData?.image}
                        className="promocard-img"
                        style={{ cursor: "pointer" }}
                        onClick={() => handleVideoClick(`eventvideo${id}`)}
                        id={`eventvideo${id}`}
                      >
                        <source src={detailsData?.image} />
                      </video>
                    </>
                  ) : (
                    <img
                      src={detailsData?.image || "/static/expertise2.png"}
                      className="promocard-img"
                      alt=""
                    />
                  )}
                </div>
                <div className="details-content">
                  <div className="desc">{detailsData?.description}</div>
                  <div className="find-more-box mt-4">
                    {detailsData?.endDate &&
                      detailsData?.startDate &&
                      moment(
                        detailsData?.endDate,
                        DATE_FORMAT.YYYYMMDD_FORMAT
                      ).isSameOrAfter(moment()) && (
                        <div className="others">
                          <div className="time">
                            <img src="/static/Calendar2x.svg" alt="" />
                            {formatDateRange(
                              detailsData?.startDate,
                              detailsData?.endDate
                            )}
                          </div>
                        </div>
                      )}

                    {detailsData?.promotionUrl ? (
                      <div className="btn-box">
                        <Link to={detailsData?.promotionUrl} target="_blank">
                          <Button
                            btnTxt="Click here to find out"
                            btnImg={"/static/findout.svg"}
                          />
                        </Link>
                      </div>
                    ) : (
                      <div className="btn-box">
                        <Button
                          btnTxt="No url added"
                          btnImg={"/static/findout.svg"}
                        />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
        <hr />
        <div className="other-related-box">
          <div className="heading">Other Related Promotions for you</div>

          <div className="card-list-wrap">
            {isLoading ? (
              <div className="page_loader">
                <ApiLoader />
              </div>
            ) : promotion.length > 0 ? (
              <div className="listings-card">
                {promotion?.map((item: any, index: number) => {
                  return (
                    <div
                      style={{ cursor: "pointer" }}
                      className="card-wrap"
                      key={index}
                      onClick={() => navigate(`/promotions/${item.id}`)}
                    >
                      <div className="card-media">
                        {["mp4", "mov", "avi"].includes(
                          item?.media?.split(".").splice(-1)[0].toLowerCase()
                        ) ? (
                          <>
                            <Link to={`/promotions/${item.id}`}>
                              <video
                                src={item?.image}
                                className="event_img"
                                style={{ cursor: "pointer" }}
                                onClick={() =>
                                  handleVideoClick(`eventvideo${index}`)
                                }
                                id={`eventvideo${index}`}
                              >
                                <source src={item?.image} />
                              </video>
                            </Link>
                          </>
                        ) : (
                          <Link to={`/promotions/${item.id}`}>
                            <img
                              src={item?.imageThumbnail || item?.image || "/static/expertise2.png"}
                              className="event_img"
                              alt=""
                            />
                          </Link>
                        )}
                      </div>
                      <div className="card-content">
                        <div className="title">
                          <Link
                            className="event-link-title"
                            to={`/promotions/${item.id}`}
                          >
                            {item?.title}
                          </Link>
                        </div>

                        {item?.endDate &&
                          item?.startDate &&
                          moment(
                            item?.endDate,
                            DATE_FORMAT.YYYYMMDD_FORMAT
                          ).isSameOrAfter(moment()) && (
                            <div className="others">
                              <Link
                                className="event-link-title"
                                to={`/promotions/${item.id}`}
                              >
                                <div className="time">
                                  <img src="/static/calendar.svg" alt="" />
                                  {dateFormatter(
                                    item?.startDate,
                                    "DD MMM, YYYY"
                                  )}{" "}
                                  -{" "}
                                  {dateFormatter(item?.endDate, "DD MMM, YYYY")}
                                </div>
                              </Link>
                            </div>
                          )}

                        <Link
                          className="event-link-title"
                          to={`/promotions/${item.id}`}
                        >
                          <div className="desc">{item?.description}</div>
                        </Link>
                      </div>
                      <div className="find-out-box">
                        <div className="click-here">
                          <Link
                            className="promo-link-title"
                            to={`${item?.promotionUrl}`}
                            target="_blank"
                          >
                            <span>
                              <img alt="" src="/static/openfind.svg" />
                            </span>
                            <span>Click here to find out</span>
                          </Link>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            ) : (
              <div className="no-promotion-box">
                <img alt="" src="/static/giftcardmoney.svg" />
                <div className="no-promotion-title">
                  No active promotions & news
                </div>
                <div className="no-promotion-desc">
                  Currently, there are no active promotions & news available.
                  Check back later for exciting offers and discounts. Stay
                  tuned!
                </div>
              </div>
            )}

            <div className="paination-container">
              <Pagination
                handlePageClick={handlePageClick}
                currentPage={currentPage}
                pageCount={pageCount / limit}
              />
            </div>
          </div>
        </div>
      </div>
      <GlobalFooter />
    </>
  );
};
export default PromotionDetails;
