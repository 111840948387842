import * as React from "react";
import moment from "moment"; // Import moment
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment"; // Import AdapterMoment for MUI
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { MobileDatePicker } from "@mui/x-date-pickers";
import { InputAdornment, TextField } from "@mui/material";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import { DATE_FORMAT } from "../../../../constants/helperScheduleDates";

export default function SingleDatePicker({
  label = "",
  name = "",
  value = null,
  watch = (props: any) => null,
  handleSetValue = (key: any, val: any) => null,
  onChange = (key: any) => null,
  customMinDate = null,
  disabled = false,
  onOpen = () => null,
  onDone = () => null,
  showCalendarIcon = true,
  customClass = "",
  format = null,
  isDisabled = false,
  placeholder = "",
  setMaxDate = false,
}) {
  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <MobileDatePicker
        disabled={isDisabled}
        className={customClass}
        name={name}
        value={
          value
            ? moment(value, format || DATE_FORMAT.DDMMYYYY_FORMAT)
            : watch(name)
            ? moment(watch(name), format || DATE_FORMAT.DDMMYYYY_FORMAT)
            : null
        }
        onAccept={(newValue) => {
          handleSetValue(
            name,
            moment(newValue).format(format || DATE_FORMAT.DDMMYYYY_FORMAT)
          );
          onChange(
            moment(newValue).format(format || DATE_FORMAT.DDMMYYYY_FORMAT)
          );
          onDone();
        }}
        onOpen={() => {
          onOpen();
        }}
        sx={{
          width: "100%",
          fontFamily: "Open sans, sans-serif !important",
        }}
        onClose={() => {
          onDone();
        }}
        format={format || DATE_FORMAT.DDMMYYYY_FORMAT}
        label={label}
        showDaysOutsideCurrentMonth
        readOnly={disabled}
        minDate={customMinDate || moment().add(1, "day")}
        maxDate={setMaxDate ? moment().add(1, "year") : null}
        slotProps={{
          textField: {
            ...(placeholder && { placeholder: placeholder }),
            focused: true,
            sx: {
              width: "100%",
              ".MuiInputLabel-root": {
                fontFamily: "Open sans, sans-serif !important",
                color: "#DCDCDC !important",
                "&.Mui-focused": {
                  fontSize: "0.93rem",
                  color: "#25272D !important",
                },
              },
              ".MuiOutlinedInput-root": {
                borderRadius: "10px",
                "& fieldset": {
                  border: "1px solid #DCDCDC",
                },
                "&:hover fieldset": {
                  border: "1px solid #DCDCDC",
                },
                "&.Mui-focused fieldset": {
                  border: "1px solid #DCDCDC",
                },
                fieldset: {
                  border: "1px solid #DCDCDC !important",
                },
              },
              ".MuiInput-input": {
                fontFamily: "Open sans, sans-serif !important",
                fontSize: "16px",
                fontWeight: 500,
                lineHeight: "24px",
                padding: "13px !important",
                borderRadius: "10px !important",
                color: "#25272D",
              },
              ".MuiInput-input:focus": {
                outline: "none",
                borderRadius: "10px !important",
              },
            },
            InputProps: {
              endAdornment: showCalendarIcon && (
                <InputAdornment position="end" sx={{ cursor: "pointer" }}>
                  <CalendarMonthIcon
                    sx={{
                      color: "#25272D",
                    }}
                  />
                </InputAdornment>
              ),
            },
          },
          layout: {
            sx: {
              color: "#000000",
              borderRadius: "6px",
              borderWidth: "0px",
              borderColor: "transparent",
              border: "none",
              ".MuiPickersDay-root:hover": {
                backgroundColor: "rgba(238, 120, 48, 0.2)",
                color: "#000000",
              },
              ".MuiPickersDay-root.Mui-selected": {
                backgroundColor: "rgba(238, 120, 48, 1) !important",
                color: "#ffffff !important",
                fontWeight: "600",
              },
              ".MuiPickersDay-root:focus ": {
                backgroundColor: "transparent",
              },
              ".MuiPickersDay-root.MuiPickersDay-today:not(.Mui-selected)": {
                color: "#000000",
                border: "1px solid #EE7830",
              },
              ".MuiPickersDay-root.MuiPickersDay-today.Mui-disabled:not(.Mui-selected)":
                {
                  color: "rgba(0, 0, 0, 0.38)",
                },
              ".MuiDialogActions-root .MuiButton-root.MuiButton-text": {
                color: "rgba(238, 120, 48, 1)",
                "&:hover": {
                  background: "rgba(238, 120, 48, 0.1)",
                },
              },
            },
          },
        }}
      />
    </LocalizationProvider>
  );
}
