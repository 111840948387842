import { Modal } from "react-bootstrap";
import Button from "../../../global/Button";
import { Link } from "react-router-dom";
import { makeDeleteCall } from "../../../utils/Requests";
import endpoints from "../../../utils/endpoints";
import { showToast } from "../../../utils";
import { useState } from "react";

const CancelSubsPopup = (props) => {
  const [cancelSubsLoading, setCancelSubsLoading] = useState(false);
  const cancelSubscription = () => {
    setCancelSubsLoading(true);
    makeDeleteCall({ url: endpoints.subscription })
      .then((res) => {
        if (res.status.code === 200) {
          props.onHide();
        }
        setCancelSubsLoading(true);
        props.setRefreshToggle((prev) => !prev);
      })
      .catch((err) => {
        showToast(err, "error");
        setCancelSubsLoading(true);
      });
  };

  return (
    <Modal {...props} centered dialogClassName="s-modal-container">
      <Modal.Body>
        <div className="s-modal-wrapper">
          <div className="close">
            <img
              src="/static/dismiss-cross-round-black.svg"
              alt=""
              onClick={props.onHide}
            />
          </div>

          <p className="s-title">Subscription Cancellation Confirmation</p>
          <p className="s-sub-title">
            If you proceed, you will be removed from the lister subscription,
            and from next cycle onwards you won't be charged for membership fee
            however feel free to enjoy the services till the billing cycle is
            over.
          </p>
          <p className="s-warning">
            Are you sure you want to cancel your subscription?
          </p>
          <div className="s-refund-info">
            <img src="/static/greyinfo.svg" alt="" />
            <p className="d-none d-md-block">
              Refunds will be processed by the system in case of subscription
              cancellation.
            </p>
            <p className="d-md-none">
              No refunds will be processed by the system in case of subscription
              cancellation.
            </p>
          </div>
          <div className="s-purchase-popup-links-wrap mx-auto">
            By tapping below, I also agree to the{" "}
            <Link to="">Terms of Service,</Link> <Link to="">Payments</Link> and
            I acknowledge the <Link to="">Privacy Policy</Link>.
          </div>

          <Button
            btnTxt="Confirm cancellation"
            className={"backtologin"}
            isLoading={cancelSubsLoading}
            onClick={() => {
              cancelSubscription();
            }}
          />
          <Button
            btnTxt="Go back"
            className={"goback "}
            onClick={props.onHide}
          />
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default CancelSubsPopup;
