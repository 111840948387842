import { showToast, handleVideoClick, scrollToTop } from "../../utils";
import Modal from "react-bootstrap/Modal";

import { useEffect, useState } from "react";
import React from "react";
import moment from "moment";
import { SpDateIcon, ExclusiveMembericon } from "../../utils/Icons/svgs";
import GooglePlacesSearchBarFilterSidebar from '../../global/GooglePlacesSearchBarFilterSidebar'
import endpoints from "../../utils/endpoints";
import { makeGetCall } from "../../utils/Requests";
import ReactPaginate from "react-paginate";
import useCheckDeviceScreen from "../../hooks/useCheckScreen";
import PackageBookingCard from "../PackageBookingCard";

const ListingDetailAllSlots = ({ showListing, detailsData , isNewFlowPackage = false }) => {

  const [active, setActive] = useState<any>("upcoming");
  const [location, setLocation] = useState<any>(null);
  const [archiveData, setArchiveData] = useState([])
  const [upcomingData, setUpcomingData] = useState([])
  const [showShare, setShowShare] = useState(false);
  const [limit, setLimit] = useState(24);
  const [offset, setOffset] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [eventsList, setEventsList] = useState([]);
  const { isMobile } = useCheckDeviceScreen();
  const [pageCount, setPageCount] = useState(1);

  const handleNotShowShare = () => {
    setShowShare(false);
  };
  const handleShowShare = () => {
    setShowShare(true);
  };

  const isImage = /\.(jpg|jpeg|gif|png|svg|webp|avif|heic)$/.test(
    detailsData?.media?.toLowerCase()
  );
  const isVideo = /\.(mp4|mov|avi)$/.test(detailsData?.media?.toLowerCase());

  useEffect(() => {
    setTimeout(() => {
      scrollToTop();
    }, 1000)

    // console.log(detailsData)
    // alert('hi');
  }, [])

  const handlePageClick = (event) => {
    scrollToTop();
    let page = event.selected + 1;
    setCurrentPage(event.selected);
    setOffset(event.selected * limit);
  };

  const getDetailsData = () => {
    let active_temp = active === "archived" ? "archive" : active
    makeGetCall({
      url: endpoints.listing + `${detailsData?.id}/events/?filter_type=${active_temp}&limit=${limit}&offset=${offset}`,
    })
      .then((res) => {
        if (res.status.code === 200) {
          // console.log(res?.results);
          // alert('hi')
          setPageCount(res?.count)
          setEventsList(res?.results)
          scrollToTop();
        }
      })
      .catch((err) => {
        console.error("error", err);
        showToast(err.message, "error");
      });

    // var tempUpcoming = []
    // var tempArchive = []
    // detailsData?.events.map((a) => {
    //   if (moment() < moment(a?.date)) {
    //     tempUpcoming.push(a)
    //   }
    //   if (moment() > moment(a?.date)) {
    //     tempArchive.push(a)
    //   }
    // })
    // setArchiveData(tempArchive)
    // setUpcomingData(tempUpcoming)
  }

  useEffect(() => {
    getDetailsData()
  }, [offset, active])
  return (
    <>
      <div className="listing_gallery_content mid_screen_handling">
        <img
          src="/static/arrow-back.svg"
          alt="back_arrow"
          className="listing_detail_back_arrow"
          style={{ cursor: "pointer" }}
          onClick={showListing}
        />
        <div className="d-flex justify-content-between">
          <div className="listing_detail_title">
            <h4>{detailsData.title} </h4>
          </div>
        </div>
        {detailsData.listingType == "P" && (
          <div className="d-flex justify-content-between">
            <div className="listing_detail_title">
              <h3
                style={{
                  fontSize: "20px",
                  marginTop: "20px",
                  fontWeight: "600",
                }}
              >
                Total Events Included:{" "}
                <span style={{ color: "#EE7830" }}>
                  {detailsData?.events?.length}
                </span>{" "}
              </h3>
            </div>
          </div>
        )}
        {/* */}
        {isNewFlowPackage && (
          <h3
            style={{ fontSize: "18px", marginTop: "20px", fontWeight: "600" }}
          >
            Events Name:{" "}
            {detailsData?.events?.length && detailsData?.events?.[0]?.title}
          </h3>
        )}
        {detailsData.listingType == "P" ? (
          <div className="session_detail_dates">
            {isNewFlowPackage ? (
              <div className="">
                {detailsData?.events.map((item, index) => (
                  <PackageBookingCard item={item} customClass="col-md-3" />
                ))}
              </div>
            ) : (
              <div>
                {detailsData?.events.map((item, index) => (
                  <span
                    key={index}
                    className={"111" === index + 1 ? "active-date" : ""}
                    style={{ cursor: "pointer" }}
                    //  onClick={() => setSelectDate(index + 1)}
                  >
                    <div className="row">
                      <h5 className="col-9">{item?.title}</h5>
                      <div
                        className="col-2 "
                        style={{ padding: 0, justifyContent: "end" }}
                      >
                        <div className="number-circle">{++index}</div>
                      </div>
                    </div>

                    {detailsData?.listingEventType !== "V" ? (
                      <p>
                        <img src="/static/location-shape.svg" alt="" />
                        <p style={{ width: "60%" }}>{item?.address?.address}</p>
                        <h4
                          className="open-map"
                          onClick={() => {
                            setLocation(item?.address?.location);
                          }}
                        >
                          Open Maps
                        </h4>
                      </p>
                    ) : (
                      <p> </p>
                    )}

                    <div>
                      <p>
                        <SpDateIcon />
                        {moment(item?.date).date()}{" "}
                        {moment(item?.date).format("MMM")},{" "}
                        {moment(item?.date).year()}{" "}
                      </p>
                      <p>
                        <img
                          src="/static/clock.svg"
                          alt="clock"
                          width={16}
                          height={16}
                        />
                        {moment(item?.startTime?.substring(0, 5), [
                          "HH:mm",
                        ]).format("hh:mmA")}{" "}
                        -{" "}
                        {moment(item?.endTime?.substring(0, 5), [
                          "HH:mm",
                        ]).format("hh:mmA")}
                      </p>
                    </div>
                  </span>
                ))}
              </div>
            )}
          </div>
        ) : (
          <>
            <div className="emodal_wrapper">
              <div className="edates_wrapper" style={{ padding: "16px" }}>
                <div className="e_all_dates_container row gx-0 gap-3">
                  <div
                    className="active-inactive-box"
                    style={{ maxWidth: "311px", margin: "auto" }}
                  >
                    <div
                      className={
                        active === "upcoming"
                          ? "item cursor-pointer more-width"
                          : "item cursor-pointer less-width"
                      }
                      // className=""
                      onClick={() => {
                        scrollToTop();
                        setActive("upcoming");
                        setCurrentPage(0);
                        setOffset(0);
                      }}
                    >
                      <div
                        className={
                          active === "upcoming"
                            ? "active mobile-full-res"
                            : "mobile-full-res"
                        }
                        style={{ marginRight: "5px" }}
                      >
                        Upcoming
                      </div>
                    </div>
                    <div
                      className={
                        active === "archived"
                          ? "item cursor-pointer more-width "
                          : "item cursor-pointer less-width"
                      }
                      onClick={() => {
                        scrollToTop();
                        setActive("archived");
                        setCurrentPage(0);
                        setOffset(0);
                      }}
                    >
                      <div
                        className={
                          active === "archived"
                            ? "active mobile-full-res"
                            : "mobile-full-res"
                        }
                        style={{ marginLeft: "5px" }}
                      >
                        Archived
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="listing_detail_dates listing_detail_dates_new">
              <div>
                {active == "upcoming" ? (
                  eventsList.length > 0 ? (
                    eventsList.map((item, index) => (
                      <span key={index}>
                        <h5>
                          {moment(item?.date).format("ddd")},{" "}
                          {moment(item?.date).date()}{" "}
                          {moment(item?.date).format("MMMM")}
                        </h5>
                        <p>
                          <img
                            src="/static/clock.svg"
                            alt="clock"
                            width={16}
                            height={16}
                          />
                          {moment(item?.startTime?.substring(0, 5), [
                            "HH:mm",
                          ]).format("hh:mmA")}{" "}
                          -{" "}
                          {moment(item?.endTime?.substring(0, 5), [
                            "HH:mm",
                          ]).format("hh:mmA")}
                        </p>
                        <p>
                          <img
                            src="/static/seat.svg"
                            alt="seat"
                            width={16}
                            height={16}
                          />
                          <b>{item?.slotsAvailable} left</b> out of{" "}
                          {detailsData?.maxParticipants} spots
                        </p>
                      </span>
                    ))
                  ) : (
                    <span style={{ margin: "auto", border: 0 }}>No Events</span>
                  )
                ) : active == "archived" ? (
                  eventsList.length > 0 ? (
                    eventsList.map((item, index) => (
                      <span key={index}>
                        <h5>
                          {moment(item?.date).format("ddd")},{" "}
                          {moment(item?.date).date()}{" "}
                          {moment(item?.date).format("MMMM")}
                        </h5>
                        <p>
                          <img
                            src="/static/clock.svg"
                            alt="clock"
                            width={16}
                            height={16}
                          />
                          {moment(item?.startTime?.substring(0, 5), [
                            "HH:mm",
                          ]).format("hh:mmA")}{" "}
                          -{" "}
                          {moment(item?.endTime?.substring(0, 5), [
                            "HH:mm",
                          ]).format("hh:mmA")}
                        </p>
                        <p>
                          <img
                            src="/static/seat.svg"
                            alt="seat"
                            width={16}
                            height={16}
                          />
                          <b>{item?.slotsAvailable} left</b> out of{" "}
                          {detailsData?.maxParticipants} spots
                        </p>
                      </span>
                    ))
                  ) : (
                    <span style={{ margin: "auto", border: 0 }}>No Events</span>
                  )
                ) : (
                  ""
                )}
                {/* {
                detailsData?.events?.map((item, index) => (
                  active == "upcoming" && moment() < moment(item?.date) ?
                    <span key={index}>
                      <h5>
                        {moment(item?.date).format("ddd")},{" "}
                        {moment(item?.date).date()}{" "}
                        {moment(item?.date).format("MMMM")}
                      </h5>
                      <p>
                        <img
                          src="/static/clock.svg"
                          alt="clock"
                          width={16}
                          height={16}
                        />
                        {moment(item?.startTime?.substring(0, 5), [
                          "HH:mm",
                        ]).format("hh:mmA")}{" "}
                        -{" "}
                        {moment(item?.endTime?.substring(0, 5), [
                          "HH:mm",
                        ]).format("hh:mmA")}
                      </p>
                      <p>
                        <img
                          src="/static/seat.svg"
                          alt="seat"
                          width={16}
                          height={16}
                        />
                        <b>{item?.slotsAvailable} left</b> out of{" "}
                        {detailsData?.maxParticipants} spots
                      </p>
                    </span>
                    :
                    active == "archived" && moment() > moment(item?.date) ?
                      <span key={index}>
                        <h5>
                          {moment(item?.date).format("ddd")},{" "}
                          {moment(item?.date).date()}{" "}
                          {moment(item?.date).format("MMMM")}
                        </h5>
                        <p>
                          <img
                            src="/static/clock.svg"
                            alt="clock"
                            width={16}
                            height={16}
                          />
                          {moment(item?.startTime?.substring(0, 5), [
                            "HH:mm",
                          ]).format("hh:mmA")}{" "}
                          -{" "}
                          {moment(item?.endTime?.substring(0, 5), [
                            "HH:mm",
                          ]).format("hh:mmA")}
                        </p>
                        <p>
                          <img
                            src="/static/seat.svg"
                            alt="seat"
                            width={16}
                            height={16}
                          />
                          <b>{item?.slotsAvailable} left</b> out of{" "}
                          {detailsData?.maxParticipants} spots
                        </p>
                      </span> :
                      <span style={{ margin: 'auto', border: 0 }}>No Events</span>
                )
                )
              } */}
              </div>
            </div>
          </>
        )}
        <div
          className="pages-container my-3 pagination-inside-slotting"
          style={{ display: "flex", justifyContent: "center" }}
        >
          <div className="pages-inner-container">
            <div className="pagination-box">
              <ReactPaginate
                breakLabel="..."
                nextLabel={
                  <>
                    {/* <div className={(Math.ceil(pageCount / limit) === currentPage+1)?"previous-button disabled":"previous-button"}>Next  <img src="/static/u_angle-left-b(1).svg" alt="" /></div> */}

                    <div className="previous-button">
                      {" "}
                      <img src="/static/u_angle-left-b(1).svg" alt="" />{" "}
                      {!isMobile ? "Next " : ""}
                    </div>
                  </>
                }
                onPageChange={handlePageClick}
                pageRangeDisplayed={!isMobile ? 5 : 2}
                forcePage={currentPage}
                pageCount={Math.ceil(pageCount / limit)}
                previousLabel={
                  <>
                    <div className="previous-button">
                      {!isMobile ? "Previous " : ""}
                      <img src="/static/u_angle-left-b.svg" alt="" />
                    </div>
                  </>
                }
                renderOnZeroPageCount={null}
                pageClassName="page-item"
                pageLinkClassName="page-link"
                previousClassName="page-item"
                previousLinkClassName="page-link"
                nextClassName="page-item"
                nextLinkClassName="page-link"
                breakClassName="page-item"
                breakLinkClassName="page-link"
                containerClassName="pagination"
                activeClassName="active"
              />
            </div>
          </div>
        </div>
      </div>
      {location ? (
        <div className="fixed-sidebar-left">
          <div className="sidebar-box" style={{ maxWidth: "50%" }}>
            <img
              src="/static/cross-black.svg"
              className="close-btn hide-large"
              alt=""
              onClick={() => {
                setLocation(null);
              }}
            />
            <GooglePlacesSearchBarFilterSidebar location={location} />
          </div>
        </div>
      ) : (
        ""
      )}
    </>
  );
};
export default ListingDetailAllSlots;
