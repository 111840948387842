export function getCroppedImg(
  imageSrc: string,
  pixelCrop: { x: number; y: number; width: number; height: number },
  rotation: number = 0
): Promise<any> {
  return new Promise((resolve, reject) => {
    const image = new Image();
    image.src = imageSrc;

    image.onload = () => {
      const canvas = document.createElement("canvas");
      const ctx = canvas.getContext("2d");

      if (!ctx) {
        reject(new Error("Failed to get 2D context."));
        return;
      }

      const radians = (rotation * Math.PI) / 180;
      const sin = Math.sin(radians);
      const cos = Math.cos(radians);

      // Calculate the bounding box of the rotated image
      const boundWidth =
        Math.abs(image.width * cos) + Math.abs(image.height * sin);
      const boundHeight =
        Math.abs(image.width * sin) + Math.abs(image.height * cos);

      canvas.width = boundWidth;
      canvas.height = boundHeight;

      // Translate to the center of the canvas
      ctx.translate(boundWidth / 2, boundHeight / 2);
      ctx.rotate(radians);
      ctx.drawImage(image, -image.width / 2, -image.height / 2);

      // Get the cropped image
      const croppedCanvas = document.createElement("canvas");
      croppedCanvas.width = pixelCrop.width;
      croppedCanvas.height = pixelCrop.height;

      const croppedCtx = croppedCanvas.getContext("2d");
      if (!croppedCtx) {
        reject(new Error("Failed to get 2D context for cropped canvas."));
        return;
      }

      croppedCtx.drawImage(
        canvas,
        pixelCrop.x,
        pixelCrop.y,
        pixelCrop.width,
        pixelCrop.height,
        0,
        0,
        pixelCrop.width,
        pixelCrop.height
      );

      croppedCanvas.toBlob((blob) => {
        if (!blob) {
          reject(new Error("Canvas is empty"));
          return;
        }
        resolve(blob);
      }, "image/jpeg");
    };

    image.onerror = () => reject(new Error("Failed to load the image."));
  });
}

export const convertBlobToFile = (blob, fileName) => {
  const file = new File([blob], fileName, {
    type: blob.type,
    lastModified: new Date().getTime(), // Current timestamp
  });
  return file;
};
