import { Link, useNavigate } from "react-router-dom";
import { scrollToTop } from "../../utils";
import { ROUTE_CONSTANTS } from "../../utils/RouteConstants";
import FooterPartnerPopup from "../FooterPopup/FooterPartnersPopup";
import { useEffect, useRef, useState } from "react";
import useCheckDeviceScreen from "../../hooks/useCheckScreen";
import StickyFooter from "../StickyFooter";
import { SVG_ICON } from "../../assets/svg";


const GlobalFooter = () => {
  const { isMobile } = useCheckDeviceScreen();
  const footerRef = useRef(null);
  const [displayStickyFooter, setDisplayStickyFooter]= useState(true);
  const currentYear = new Date().getFullYear();

  const navigate = useNavigate();
  const [showAffiliate, setShowAffiliate] = useState(false);
  const [modalData, setModalData] = useState("affiliate");

  const handleOpenModal = (modalType) => {
    setModalData(modalType);
    setShowAffiliate(true);
  };
  const handleCloseModal = () => {
    setShowAffiliate(false);
  };
  const handleExternalLinks = (externalLink:string) => window.open(externalLink, "_blank", "noopener,noreferrer");
  useEffect(() => {
    const handleScroll = () => {
      if (footerRef.current) {
        const footerTop = footerRef.current.getBoundingClientRect().top;
        const viewportHeight = window.innerHeight;

        if (footerTop <= viewportHeight) {
          setDisplayStickyFooter(false);
        } else {
          setDisplayStickyFooter(true);
        }
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div className="global-footer" ref={footerRef}>
      {
        displayStickyFooter &&
        <StickyFooter />
      }
      <div className="footer-container">
        <div className="footer-first-part row gx-0">
          <div
            className="links-box quick-links col-12 col-sm-6 col-md-3 d-sm-none d-xs-block"
            style={{ marginBottom: "20px" }}
          >
            <img
              src="/static/FooterIcon2.svg"
              alt=""
              style={{ width: "132px" }}
            />
          </div>
          <div className="links-box quick-links col-12 col-sm-6 col-md-3 font_f">
            <h4 className="font_f">Quick Links</h4>
            <ul>
              <li
                onClick={() => {
                  navigate(ROUTE_CONSTANTS.landing);
                  scrollToTop();
                }}
              >
                Home
              </li>
              <li
                onClick={() => {
                  navigate(ROUTE_CONSTANTS.promotion);
                  scrollToTop();
                }}
              >

                Promotions & News
              </li>
              <li
                onClick={() => {
                  navigate(ROUTE_CONSTANTS.about_us);
                  scrollToTop();
                }}
              >
                About us
              </li>
              <li
                onClick={() => {
                  navigate(ROUTE_CONSTANTS.connect);
                  scrollToTop();
                }}
              >
                Meet Mates
              </li>
              <li onClick={() => handleOpenModal("carrier")}>Careers</li>
              <li onClick={() => handleExternalLinks("https://discover.fitnessmates.com/how-it-works/")}>How it works</li>
              <li onClick={() => handleExternalLinks("https://discover.fitnessmates.com/")}>Blogs</li>
              <li onClick={() => handleExternalLinks("https://discover.fitnessmates.com/earn-money-as-lister/")}>Earn money as lister</li>
            </ul>
          </div>
          <div className="links-box partners col-12 col-sm-6 col-md-3 font_f">
            <h4 className="font_f">Partners</h4>
            <ul>
              <li onClick={() => handleOpenModal("affiliate")}>
                Affiliate Program
              </li>
              <li onClick={() => handleOpenModal("program")}>
                Become an Ambassador
              </li>
              <li onClick={() => handleOpenModal("affiliate")}>
                Become an Affiliate
              </li>
            </ul>
          </div>
          <div className="links-box locate-us col-12 col-sm-6 col-md-3 font_f">
            <h4 className="font_f">Locate us</h4>
            <div className="locate-item-container">
              <div className="locate-item">
                <img src="/static/outline-email.svg" alt="" />
                <a href="mailto:info@fitnessmates.com">info@fitnessmates.com</a>
              </div>
              <div className="locate-item">
                <img src="/static/outline-call.svg" alt="" />
                <p>1300 683 892</p>
              </div>
              <div className="locate-item">
                <img src="/static/outline-location.svg" alt="" />
                <p>Sydney, Australia</p>
              </div>
            </div>
          </div>
          <div className="links-box follow-us col-12 col-sm-6 col-md-3 font_f">
            <h4 className="font_f">Follow us</h4>
            <div className="follow-icon-container">
              <div
                className="img-container"
                onClick={() => {
                  window.open("https://twitter.com/fitnessmatesau", "_blank");
                }}
              >
                <img src="/static/twitter-white.svg" alt="" />
                <img src="/static/twitter-or.svg" alt="" />
              </div>
              <div
                className="img-container"
                onClick={() => {
                  window.open(
                    "https://www.tiktok.com/@fitnessmatesau",
                    "_blank"
                  );
                }}
              >
                <img src="/static/tiktok-white.svg" alt="" />
                <img src="/static/tiktok-or.svg" alt="" />
              </div>
              <div
                className="img-container"
                onClick={() => {
                  window.open("https://instagram.com/fitnessmatesau", "_blank");
                }}
              >
                <img src="/static/insta-white.svg" alt="" />
                <img src="/static/insta-or.svg" alt="" />
              </div>
              <div
                className="img-container"
                onClick={() => {
                  window.open(
                    "https://www.youtube.com/@fitnessmatesau",
                    "_blank"
                  );
                }}
              >
                <img src="/static/youtube-white.svg" alt="" />
                <img src="/static/youtube-or.svg" alt="" />
              </div>
              <div
                className="img-container"
                onClick={() => {
                  window.open("https://facebook.com/fitnessmatesau", "_blank");
                }}
              >
                <img src="/static/facebook-white.svg" alt="" />
                <img src="/static/facebook-or.svg" alt="" />
              </div>
              <div
                className="img-container"
                onClick={() => {
                  window.open(
                    "https://www.linkedin.com/company/fitness-mates",
                    "_blank"
                  );
                }}
              >
                <img src="/static/linkedin-white.svg" alt="" />
                <img src="/static/linkedin-or.svg" alt="" />
              </div>
            </div>
            <div className="follow-store-container">
              <div
                className="download-image"
                style={{
                  cursor: "pointer",
                }}
                onClick={() => {
                  window.open(
                    "https://apps.apple.com/in/app/fitness-mates-pty-ltd/id6449068527",
                    "_blank"
                  );
                }}
              >
                <SVG_ICON.AppStore />
                {/* <span className="coming-soon">Coming Soon</span> */}
              </div>
              <div
                className="download-image"
                style={{
                  cursor: "pointer",
                }}
                onClick={() => {
                  window.open(
                    "https://play.google.com/store/apps/details?id=com.fitnessmates.mobile.app",
                    "_blank"
                  );
                }}
              >
                <SVG_ICON.PlayStore />
                {/* <span className="coming-soon">Coming Soon</span> */}
              </div>
            </div>
          </div>
        </div>
        <div className="footer-second-part font_f">
          {!isMobile ? (
            <div>© {currentYear} Fitness mates Pty Ltd. All rights reserved.</div>
          ) : (
            ""
          )}

          <div className="footer-terms">
            <Link to="/policies?activeTab=terms">
              <p>Terms & Conditions</p>
            </Link>
            <span>|</span>
            <Link to="/policies?activeTab=privacy">
              <p>Privacy Policy</p>
            </Link>
          </div>
          {isMobile ? (
            <div style={{ fontSize: "10px" }}>
              © {currentYear} Fitness mates Pty Ltd. All rights reserved.
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
      {showAffiliate && (
        <FooterPartnerPopup
          show={showAffiliate}
          onHide={handleCloseModal}
          modalType={modalData}
        />
      )}
    </div>
  );
};

export default GlobalFooter;
