import React, { useEffect, useLayoutEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import OTPInput from "react-otp-input";
import { getItemFromStore, showToast } from "../../../utils";
import { makeGetCall, makePostCall } from "../../../utils/Requests";
import apiConfig from "../../../utils/endpoints";
import PrimaryButton from "../../Button/PrimaryButton";
import { useApiRequest } from "../../../hooks/useApiRequest";

export default function VerifyEmailModal({
  showOtpModal = false,
  handleNotShowOtpModal = () => {},
  email = getItemFromStore("userProfile")?.email || "",
  customOnSuccess = () => null,
  isEmailChanged= false,
}) {
  const getUserProfile = useApiRequest();

  const [otp, setOtp] = useState("");
  const [timer, setTimer] = useState(60);

  const handleGenerateOtp = (type = '') => {
    let apiPayload = {
      ...(isEmailChanged ? {changed_email: email} : {})
    };
    makePostCall({ url: apiConfig.generate_otp, apiPayload })
      .then((res) => {
        if (res.status.code === 200) {
          if(type === "resendOtp"){
            showToast("OTP resent successfully.", "success");
          }else{
            showToast("OTP has been sent successfully.", "success");
          }
          setTimer(60);
        }
      })
      .catch((err) => showToast(err, "error"));
  };

  useLayoutEffect(() => {
    if (showOtpModal) handleGenerateOtp();
  }, []);
  const handleChange = (value) => {
    setOtp(value);
  };

  useEffect(() => {
    if (timer > 0 && showOtpModal) {
      const interval = setInterval(() => {
        setTimer((prev) => prev - 1);
      }, 1000);

      return () => clearInterval(interval);
    }
  }, [timer, showOtpModal]);

  const handleResendOtp = async () => {
    await handleGenerateOtp("resendOtp");
  };

  const handleVerifyEmail = () => {
    makeGetCall({ url: `${apiConfig.generate_otp}?otp=${otp}` })
      .then(async (res) => {
        if (res.status.code === 200) {
          if (res?.data?.isValid) {
            showToast("Email has been verified.", "success");

            const resp = await getUserProfile();
            if (resp?.status?.code === 200) {
              handleNotShowOtpModal();
              customOnSuccess();
            }
          } else {
            showToast("Please enter a valid OTP", "error");
          }
        }
      })
      .catch((err) => showToast(err, "error"));
  };

  return (
    <Modal
      show={showOtpModal}
      onHide={handleNotShowOtpModal}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="otp_Modal"
    >
      <Modal.Body>
        <div className="share_modal_layout">
          <img
            src="/static/close.svg"
            alt="Close"
            style={{ cursor: "pointer" }}
            onClick={handleNotShowOtpModal}
            className="share_modal_close_btn"
          />
          {/* <h4 className="modal-heading">OTP Verification</h4> */}
          <div className="share_modal_details otp_modal_details">
            {/* <div className=" d-flex flex-column justify-content-center mx-auto"> */}
            <div className="heading_verify_modal">
              Kindly check your inbox and complete the verification process
            </div>
            <h5 className="mb-4 mt-2 sub_heading_verify_modal">
              {"Enter the verification code sent to "}
              <span>{email}</span>
            </h5>
            {/* </div> */}
            <OTPInput
              value={otp}
              onChange={handleChange}
              numInputs={4}
              // renderSeparator={<span>-</span>}
              shouldAutoFocus={true}
              renderInput={(props) => <input {...props} />} // Required renderInput prop
              inputStyle={{
                width: "100%",
                height: "60px",
                margin: "0 0.5rem",
                fontSize: "1.5rem",
                borderRadius: "12px",
                border: "1px solid #ccc",
              }}
            />
            <div className="my-4">
              Didn't receive code?{" "}
              {timer ? (
                <b>Resend in {timer} seconds</b>
              ) : (
                <span onClick={() => handleResendOtp()} className="resendBtn">
                  Resend
                </span>
              )}
            </div>
            <PrimaryButton
              // className="verifyBtn"
              fullWidth
              disabled={!(otp?.length && otp?.length === 4)}
              onClick={() => handleVerifyEmail()}
            >
              Verify
            </PrimaryButton>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}
