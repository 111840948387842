import React from "react";
import { getItemFromStore } from "../../utils";
import { useNavigate } from "react-router-dom";

const GroupDetailsMemberItem = ({
  setShowOptions,
  showOptions,
  setFlagModalOpen,
  item,
  index,
  setFlagUserData,
  flagUserData,
}: any) => {

  const navigate = useNavigate();
  const cursorStyle = {
    cursor : item?.id !== getItemFromStore("userProfile")?.id ?"pointer":"auto",
  }
  return (
    <div className="group-details-member-item">
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          position: "relative",
        }}
      >
        <div
          className="profile-image"
          style={{
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
            backgroundSize: "cover",
            marginRight: 10,
            height: 48,
            width: 48,
          }}
        >
            <img
              alt=""
              src={item?.media || item?.profilePicture || "/static/connect/avatar.svg"}
              className="user-img"
              style={cursorStyle}
              onClick={() => {
                if(item?.id !== getItemFromStore("userProfile")?.id){
                  navigate(`/our-mates/${item.id}`)
                }
              }}
            />
        </div>
        <div className="name">{item?.name}</div>
      </div>
      {item?.id !== getItemFromStore("userProfile")?.id ? (
        <div>
          <div
            onClick={() => {
              if (showOptions === index) {
                setShowOptions(-1);
              } else {
                setShowOptions(index);
              }
            }}
            className="more-icon"
            style={{ cursor:"pointer" }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
            >
              <path
                d="M12 7.2002C11.1716 7.2002 10.5 6.52862 10.5 5.7002C10.5 4.87177 11.1716 4.2002 12 4.2002C12.8284 4.2002 13.5 4.87177 13.5 5.7002C13.5 6.52862 12.8284 7.2002 12 7.2002ZM12 13.5002C11.1716 13.5002 10.5 12.8286 10.5 12.0002C10.5 11.1718 11.1716 10.5002 12 10.5002C12.8284 10.5002 13.5 11.1718 13.5 12.0002C13.5 12.8286 12.8284 13.5002 12 13.5002ZM10.5 18.3002C10.5 19.1286 11.1716 19.8002 12 19.8002C12.8284 19.8002 13.5 19.1286 13.5 18.3002C13.5 17.4718 12.8284 16.8002 12 16.8002C11.1716 16.8002 10.5 17.4718 10.5 18.3002Z"
                fill="#0C1C3B"
              />
            </svg>
          </div>
          {showOptions === index ? (
            <div className="more-popup-item-wrap">
              <div
                className="title"
                onClick={() => {
                  navigate(`/our-mates/${item.id}`)
                }}
              >
                View Profile
              </div>

              <div
                className="title"
                onClick={() => {
                  setFlagModalOpen(true);
                  setFlagUserData(item);
                }}
              >
                Flag user
              </div>
            </div>
          ) : (
            ""
          )}
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default GroupDetailsMemberItem;
