import React from "react";
import { Modal } from "react-bootstrap";
import MediaContainer from "../MediaContainer";
import { showToast } from "../../../utils";
import "./style.scss";

type Props = {
  [key: string]: any;
};

const ShareModal = (props: Props) => {
  const { show, handleClose, shareData } = props;
  const handleCopy = async () => {
    try {
      await navigator.clipboard.writeText(shareData?.link);
      showToast("Text copied!", "success");
    } catch (err) {
      showToast("Failed to copy the text. Please try again.", "error");
    }
  };

  const encodedMessage = encodeURIComponent("Check out this link!");
  const encodedLink = encodeURIComponent(shareData?.link);

  const handleShare = (platform) => {
    let url = "";
    switch (platform) {
      case "whatsapp":
        url = `https://api.whatsapp.com/send?text=${encodedMessage}%20${encodedLink}`;
        break;
      case "sms":
        url = `sms:?body=${encodedMessage}%20${encodedLink}`;
        break;
      case "messenger":
        url = `fb-messenger://share?link=${encodedLink}`;
        break;
      case "twitter":
        url = `https://twitter.com/intent/tweet?text=${encodedMessage}%20${encodedLink}`;
        break;
      case "facebook":
        url = `https://www.facebook.com/sharer/sharer.php?u=${encodedLink}`;
        break;
      case "email":
        url = `mailto:?subject=${encodedMessage}&body=${encodedMessage}%20${encodedLink}`;
        break;
      default:
        handleCopy();
        return;
    }
    window.open(url, "_blank");
  };
  return (
    <Modal
      show={show}
      onHide={handleClose}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body>
        <div className="share_common_modal_layout">
          <img
            src="/static/close.svg"
            alt="Close"
            style={{ cursor: "pointer" }}
            onClick={handleClose}
            className="share_modal_close_btn"
          />
          <h4 className="font_f">Share Listing</h4>
          <div className="share_modal_details">
            {shareData?.media ? (
              <MediaContainer
                className={"custom_share_media"}
                documentName={shareData?.media?.documentName?.toLowerCase()}
                documentFileType={shareData?.media?.documentName}
                uploadDocumentUrl={shareData?.media?.uploadDocument}
              />
            ) : (
              <img
                style={{
                  width: "110px",
                  height: "86px",
                  borderRadius: "12px",
                }}
                src="/static/expertise2.png"
                alt=""
              />
            )}

            <div className="title font_f">
              {shareData?.title && <h5>{shareData?.title}</h5>}
              {shareData?.address && (
                <p className="sub-title font_os">Address: {shareData?.address}</p>
              )}
            </div>
            <div>
              {shareData?.listingType === "S" ? (
                <button
                  className="session_btn_popup font_f"
                  // style={{ padding: "10px" }}
                >
                  <img src="/static/Sport-white.svg" alt="Sport Balls" />
                  Session
                </button>
              ) : shareData?.listingType === "P" ? (
                <button
                  className="package_btn_popu font_fp"
                  style={{ padding: "10px" }}
                >
                  <img src="/static/box-white.svg" alt="Sport Balls" />
                  Package
                </button>
              ) : (
                <button className="team_btn_popup font_f" style={{ padding: "10px" }}>
                  <img src="/static/people-white.svg" alt="Sport Balls" />
                  Team
                </button>
              )}
            </div>
          </div>
          <div className="share_options">
            <span className="flex-fill font_os" onClick={() => handleShare("copy")}>
              <img src="/static/copy.svg" alt="Copy icon" />
              Copy Link
            </span>
            <span className="flex-fill font_os" onClick={() => handleShare("email")}>
              <img src="/static/mail.svg" alt="Mail icon" />
              Email
            </span>
            <span className="flex-fill font_os" onClick={() => handleShare("sms")}>
              <img src="/static/message.svg" alt="Message icon" />
              Message
            </span>
            <span className="flex-fill font_os" onClick={() => handleShare("whatsapp")}>
              <img src="/static/whatsapp.svg" alt="WhatsApp icon" />
              WhatsApp
            </span>
            <span
              className="flex-fill font_os"
              onClick={() => handleShare("messenger")}
            >
              <img src="/static/messenger.svg" alt="Messenger icon" />
              Messenger
            </span>
            <span className="flex-fill font_os" onClick={() => handleShare("facebook")}>
              <img src="/static/facebook.svg" alt="Facebook icon" />
              Facebook
            </span>
            <span className="flex-fill font_os" onClick={() => handleShare("twitter")}>
              <img src="/static/twitter.svg" alt="Twitter icon" />
              Twitter
            </span>
          </div>
          <button className="mt-5 d-none back-btn font_os" onClick={handleClose}>
            Back
          </button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default ShareModal;
