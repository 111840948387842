import React, { useEffect, useRef, useState } from "react";
import MediaContainer from "./MediaContainer";
import { getItemFromStore, handleFileUpload, showToast } from "../../../utils";
import { apiConstants, genderOption } from "../../../utils/Constants";
import InputField from "../../../global/InputField";
import { MESSAGE, STRING_CONSTANTS } from "../../../utils/StringConstant";
import Button from "../../../global/Button";
import { makeGetCall } from "../../../utils/Requests";
import ImageCropper from "../../../global/ImageCropper";
import DobField from "../../ProfileSection/DobField";
import CropperImage from "../../Cropper";
import { Box } from "@mui/material";
import VerifyEmailModal from "../../VerifyEmail/VerifyEmailModal";
import { useAppSelector } from "../../../store/store";
import { USER_TYPE } from "../../../types";
import apiConfig from "../../../utils/endpoints";
import ApiLoader from "../../../global/ApiLoader";

type Props = {
  [key: string]: any;
};

const UserDetails = ({
  handleNext,
  control,
  errors,
  isLoading,
  setValue,
  getValues,
  watch,
  userTypeData
}: Props) => {
  const coverRef = useRef(null);
  const profileRef = useRef(null);
  const [abnResponse, setAbnResponse] = useState<any>("");
  const auth = useAppSelector((state) => state.auth);
  const [showOtpModal, setShowOtpModal] = useState(false);
  
  const [isMediaUploadInprogress, setIsMediaUploadInprogress] = useState({cover:false, profile:false});
  //cropper
  const [cropperOpen, setCropperOpen] = useState(false);
  const [imageFile, setImageFile] = useState("");
  const [croppedImage, setCroppedImage] = useState<any>("");
  const [uploadedFileName, setuploadedFileName] = useState("");
  // requirement filename and path ;
   const handleUploadFiles = async (files: any = [], mediaType:string = "users") => {
    let finalMedia: any = [];      
      if (files) {
        mediaType === "users" ? setIsMediaUploadInprogress((prev) => ({...prev, profile:true})) : setIsMediaUploadInprogress((prev) => ({...prev, cover:true}));
        const res = await makeGetCall({
          url: `${apiConfig.signed_url}/${mediaType}/${uploadedFileName ? uploadedFileName : files?.name}`,
        });

        if (res.status.code === 200) {
          const {
            AWSAccessKeyId = "",
            key = "",
            policy = "",
            signature = "",
          } = res?.data?.fields;
          const uploadedResponse = await handleFileUpload(
            files,
            res?.data?.url,
            key,
            AWSAccessKeyId,
            policy,
            signature,
            // val?.tempId
          );
          finalMedia.push(uploadedResponse);
          // update the actual img values according to s3 key
          if(mediaType === "users"){
          setValue(apiConstants.profileMediaKey,key , { shouldDirty: true });
          if(uploadedResponse){
            setIsMediaUploadInprogress((prev) => ({ ...prev, profile:false }));
          }
          }else{
          setValue(apiConstants.coverMediaKey, key, {shouldDirty: true });
          if(uploadedResponse){
            setIsMediaUploadInprogress((prev) => ({ ...prev, cover:false }));
          }
          }
        }
      }
    

    // return finalMedia;
  }; 

  useEffect(() => {
    if (croppedImage) {
      setValue(apiConstants.profileImage, croppedImage, { shouldDirty: true });
      setValue(apiConstants.profileImageExtension, croppedImage.type);
      handleUploadFiles(croppedImage,"users");
    }
  }, [croppedImage]);

  //cropper
  const [cropperCoverOpen, setCropperCoverOpen] = useState(false);
  const [imageCoverFile, setImageCoverFile] = useState("");
  const [croppedCoverImage, setCroppedCoverImage] = useState<any>("");


  useEffect(() => {
    if (croppedCoverImage) {
      setValue(apiConstants.coverPhoto, croppedCoverImage, {
        shouldDirty: true,
      });
      setValue(apiConstants.coverExtension, croppedCoverImage.type);
      handleUploadFiles(croppedCoverImage, "cover");
    }
  }, [croppedCoverImage]);

  
  const handleUploadClick = (target = "cover") => {
    if (target === "profile") {
      profileRef.current.click();
    } else {
      coverRef.current.click();
    }
  };

  const setFileValue = (target, file) => {
    if (target === "profile") {
      setValue(apiConstants.profileImage, file, { shouldDirty: true });
      setValue(apiConstants.profileImageExtension, file.type);
    } else {
      setValue(apiConstants.coverPhoto, file, { shouldDirty: true });
      setValue(apiConstants.coverExtension, file.type);
    }
  };

  const handleFileChange = async (event, target = "cover") => {
    const file = event?.target?.files[0];
    if (!file) {
      return;
    }
    const maxSize = 5 * 1024 * 1024; // 5 MB
    const allowedImageTypes = ["image/jpeg", "image/jpg", "image/png"];
    const allowedVideoTypes = ["video/mp4", "video/quicktime"]; // .MOV MIME type is video/quicktime
    const isValidImageType = allowedImageTypes.includes(file.type);
    const isValidVideoType = allowedVideoTypes.includes(file.type);
    const isValidType = isValidImageType || isValidVideoType;

    if (target === "profile" && !isValidImageType) {
      showToast(
        "You can only upload a JPG, JPEG, or PNG image for the profile photo.",
        "error"
      );
      return;
    }

    if (target === "cover" && !isValidType) {
      showToast(
        "You can only upload JPG, JPEG, PNG images, or MP4, MOV videos for the cover photo.",
        "error"
      );
      return;
    }

    if (file.size > maxSize) {
      showToast("The maximum file size you can upload is 5MB.", "error");
      return;
    }

    // if a valid img or video then update the recent file name;
    setuploadedFileName(file?.name);
    if (isValidVideoType && target === "cover") {
      const video = document.createElement("video");
      video.preload = "metadata";
      video.src = URL.createObjectURL(file);

      video.onloadedmetadata = function () {
        URL.revokeObjectURL(video.src);
        if (video.duration > 30) {
          showToast("The video cannot be longer than 30 seconds.", "error");
        } else {
          setFileValue(target, file);
          handleUploadFiles(file , "cover")
        }
      };
    } else {
      if (file?.type?.startsWith("image")) {
        if (target === "cover") {
          setImageCoverFile(file);
          setTimeout(() => {
            setCropperCoverOpen(true);
          }, 0);
        } else {
          setImageFile(file);
          setTimeout(() => {
            setCropperOpen(true);
          }, 0);
        }
      } else {
        setFileValue(target, file);
      }
    }
  };

  useEffect(() => {
    if (watch(apiConstants.abn)?.length === 11) {
      makeGetCall({
        url: `auth/${getValues(apiConstants.abn)}/validate-abn/`,
      })
        .then((res) => {
          if (res.status.code === 200) {
            if (res.data.AbnStatus === "Active") {
              setAbnResponse(res.data);
              setValue(apiConstants.abnDetails, res.data);
            } else {
              showToast(STRING_CONSTANTS.invalid_abn, "error");
              setAbnResponse(null);
            }
          } else {
            showToast(STRING_CONSTANTS.invalid_abn, "error");
            setAbnResponse(null);
          }
        })
        .catch((err) => {
          showToast(err, "error");
        });
    } else {
      setAbnResponse(null);
    }
  }, [watch(apiConstants.abn)]);


  const handleSubmit = () => {
    if (
      auth?.moreUserDetails?.userType !== USER_TYPE.GUEST &&
      !auth?.moreUserDetails?.isEmailVerified
    ) {
      return showToast("Please verify your email.", "error");
    } 
    if(isMediaUploadInprogress.cover || isMediaUploadInprogress.profile){
      showToast(MESSAGE.MEDIA_UPLOAD_PROGRESS, "error", "isMediaUploadInprogress");
      return
    }
    handleNext();
  };


  return (
    <div className="mp_form_wrapper">
      <div className="mp_form_container">
        <div className="mp_cover_wrapper">
          <div
            className="mp_cover_container"
            onClick={() => handleUploadClick("cover")}
          >
            {watch(apiConstants.coverExtension) ? (
              isMediaUploadInprogress.cover ? 
              <div className="mp_cover_empty">
              <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
                gap: "42px",
              }}
            >
              <div style={{ height: "24px", width: "24px" }}>
                <ApiLoader />
              </div>
              <div style={{ color: "red" }}>Please wait, uploading ...</div>
              </div>
            </div> :
              <MediaContainer
                file={watch(apiConstants.coverPhoto)}
                type={watch(apiConstants.coverExtension)}
                className="cover_media_file"
              />
            ) : (
              <div className="mp_cover_empty">
                <img
                  className="add_cover_icon"
                  src="/static/addFilledPrimary.svg"
                  alt=""
                />
                <div className="add_cover_title">Add cover photo/video</div>
              </div>
            )}
            <input
              type="file"
              accept="image/jpeg,image/jpg,image/png,video/mp4,video/quicktime"
              ref={coverRef}
              style={{ display: "none" }}
              onInput={(e) => handleFileChange(e, "cover")}
              onClick={(e: any) => (e.target.value = "")}
            />
          </div>
          <div className="mp_profile_wrapper">
            <div className="user_profile_container">
              {watch(apiConstants.profileImageExtension) ? (
                isMediaUploadInprogress.profile ? 
                <div className="mp_profile_media" style={{backgroundColor:"antiquewhite"}}>
                <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "100%",
                  height:"100%"
                }}
              >
                <div>
                  <ApiLoader />
                </div>
                </div>
              </div> :
                <MediaContainer
                  file={watch(apiConstants.profileImage)}
                  type={watch(apiConstants.profileImageExtension)}
                  className="mp_profile_media"
                />
              ) : (
                <img
                  className="mp_profile_media"
                  src="/static/Group 1437254547.svg"
                  alt=""
                />
              )}

              <img
                className="mp_profile_icon"
                src="/static/addProfileIcon.svg"
                alt=""
                onClick={() => handleUploadClick("profile")}
              />
              <input
                type="file"
                ref={profileRef}
                accept="image/jpeg,image/jpg,image/png"
                style={{ display: "none" }}
                onInput={(e) => handleFileChange(e, "profile")}
                onClick={(e: any) => (e.target.value = "")}
              />
            </div>
          </div>
        </div>
        <div className="mp_form_fill_container">
          <div className="mp_section">
            <InputField
              className={"floating_input"}
              type="text"
              name={apiConstants.name}
              control={control}
              errors={errors}
              inputLabel={"Username"}
              placeholder={"Add your preferred username"}
              setValue={setValue}
              maxlength={250}
            />
          </div>
          {
            auth?.moreUserDetails?.userType !== USER_TYPE.GUEST &&
            <div className="mp_section">
              <InputField
                className={"floating_input"}
                type="text"
                name={apiConstants.email}
                control={control}
                errors={errors}
                inputLabel={"Email"}
                placeholder={"Email"}
                setValue={setValue}
                maxlength={250}
                disabled
              />
              {!auth?.moreUserDetails?.isEmailVerified && (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    marginTop: "8px",
                  }}
                >
                  <Box
                    component={"span"}
                    sx={{
                      cursor: "pointer",
                      color: "#EE7830",
                      fontSize: "12px",
                      "&:hover": {
                        textDecoration: "underline",
                      },
                    }}
                    onClick={() => setShowOtpModal(true)}
                  >
                    Verify email now
                  </Box>
                </div>
              )}
            </div>
          }

          {!userTypeData?.isBusiness && (
            <div className="mp_section">
              <label className="mp_section_sub_label mb-2">
                Which closely describes your gender?
              </label>
              <div className="gender_wrapper d-none d-md-flex">
                {genderOption.map((item, index) => {
                  return (
                    <div className="gen-opt-cont" key={"gen" + index}>
                      <label
                        htmlFor={item.value}
                        className={
                          watch(apiConstants.gender) === item.value
                            ? "gender_option active"
                            : "gender_option"
                        }
                      >
                        <img src={item.img} alt="" />
                        <InputField
                          // className={"floating_input"}
                          key={index}
                          name={apiConstants.gender}
                          type="radio"
                          control={control}
                          id={item.value}
                          inputLabel={item.label}
                          setValue={setValue}
                        />

                        <div className="check-icon"></div>
                      </label>
                    </div>
                  );
                })}
              </div>

              <div className="gender_wrapper d-md-none">
                {genderOption.map((item, index) => {
                  return (
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "12px",
                      }}
                      key={"gen" + index}
                    >
                      <label
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "8px",
                        }}
                        onClick={() =>
                          setValue(apiConstants.gender, item.value)
                        }
                      >
                        {watch(apiConstants.gender) === item.value ? (
                          <img src="/static/onboarding/radioA.svg" alt="" />
                        ) : (
                          <img src="/static/onboarding/radio.svg" alt="" />
                        )}
                        <span
                          style={{
                            fontSize: "14px",
                            lineHeight: "20px",
                            fontWeight: "400",
                            // minWidth: "66px",
                          }}
                        >
                          {item?.label}
                        </span>
                      </label>
                    </div>
                  );
                })}
              </div>
              {errors?.gender && (
                <span className="text-danger mp_error">
                  {errors?.gender?.message}
                </span>
              )}
            </div>
          )}
          {!userTypeData?.isBusiness && (
            <div className="mp_section">
              <label className="mp_section_sub_label mb-2">Date of birth</label>
              <DobField watch={watch} setValue={setValue} isLabel={false} />

              {/* <div className="dob_wrapper another-dob">
              <DateSelecter
                name={apiConstants.dob}
                errors={errors}
                control={control}
                dobError={dobError}
                dobTwoError={dobTwoError}
              />
            </div> */}
            </div>
          )}
          <div className="mp_section">
            <InputField
              type={"textarea"}
              name={apiConstants.bio}
              control={control}
              errors={errors}
              inputLabel={"Bio"}
              className={"floating_input"}
              // className={"textarea-label floating_input"}
              placeholder={"Share your fitness passion"}
              maxlength="300"
              setValue={setValue}
              getValues={getValues}
            />
          </div>

          <div className="mp_section"></div>
          <div className="mp_section"></div>
        </div>
      </div>
      <div
        style={{
          zIndex: "6",
          backgroundColor: "white",
          display: "flex",
          justifyContent: "center",
          width: "100%",
          position: "sticky",
          bottom: "0px",
          padding: "30px 0px",
          boxShadow: "0px 0px 4px 0px #00000040",
        }}
      >
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            maxWidth: "372px",
          }}
        >
          {/* <Button
                btnTxt="Save & continue"
                className={"btn-member mx-auto free-trial-btn"}
                onClick={() => {
                  // setShowAddCardPopup(true);
                }}
              /> */}
          <Button
            btnTxt="Save & continue"
            onClick={() => {
              handleSubmit();
            }}
            className={"submit_btn"}
            isLoading={isLoading}
          />
        </div>
      </div>
      {cropperOpen && (
        <CropperImage
          circular
          imageFile={imageFile}
          setResultImage={setCroppedImage}
          handleClose={() => setCropperOpen(false)}
        />
      )}
      {cropperCoverOpen && (
        <CropperImage
          cover
          imageFile={imageCoverFile}
          setResultImage={setCroppedCoverImage}
          handleClose={() => setCropperCoverOpen(false)}
        />
      )}
      {showOtpModal && (
        <VerifyEmailModal
          showOtpModal={showOtpModal}
          handleNotShowOtpModal={() => setShowOtpModal(false)}
          email={auth?.moreUserDetails?.email}
        />
      )}
    </div>
  );
};

export default UserDetails;
