import {useEffect, useRef, useState} from "react";
import SearchFilter from "../ConnectComponents/SearchFilter";
import NoFavourites from "./NoFavourite";
import {makeGetCall, makeNextPageCall} from "../../../utils/Requests";
import {apiConstants} from "../../../utils/Constants";
import {scrollToTop, showToast} from "../../../utils";
import ConnectUserCard from "../ConnectComponents/ConnectUserCard";
import {CONNECTION_TYPE} from "../ConnectConstants";
import ConnectMapContainer from "../ConnectComponents/ConnectMapContainer";
import useCheckDeviceScreen from "../../../hooks/useCheckScreen";
import NoSearchedFilter from "../ConnectComponents/NoSearchedFilter";
import LoadOnScroll from "../../Reusable/LoadOnScroll";
import ConnectUserCardShimmer from "../../Reusable/Shimmer/ConnectUserCardShimmer";

const ConnectFavourites = () => {
  const [search, setSearch] = useState("");
  const [showMap, setShowMap] = useState(false);
  const [serachData, setSearchData] = useState<any>();
  const [isSearchMode, setIsSearchMode] = useState(false);
  const [count, setCount] = useState(0);
  const [totalFavourites, setTotalFavourites] = useState(0);
  const [favouriteConnectionsList, setFavouriteConnectionsList] = useState([]);
  const [userCurrentLocation, setUserCurrentLocation] = useState({lat: 0,long: 0});
  const [limit] = useState<number>(12);
  const [isLoading, setIsLoading] = useState(true);
  const [offset, setOffset] = useState<number>(0);
  const {isMobile} = useCheckDeviceScreen();
  const [nextUrl, setNextUrl] = useState<any>(null);
  const [fetchingMoreData, setFetchingMoreData] = useState(false);
  const lastFetchedUrl = useRef(null);

  const handleClearClick = () => {
    setSearch("");
    setIsLoading(true);
    setIsSearchMode(false);
    setNextUrl(null);
  };

  const handleSuggestionSearch = (value: any) => {
    setSearch(value);
  };

  const handleSearchedSeeAllClick = () => {
    setIsSearchMode(true);
    setIsLoading(true);
    getFavouriteConnections();
  };
  const handleViewToggle = () => {
    setShowMap((prev) => !prev);
  };

  const handleUnfavouriteClick = (id: any) => {
    if (favouriteConnectionsList?.length > 0) {
      const filteredList = favouriteConnectionsList?.filter((item) => {
        if (item.id !== id) {
          return item;
        }
      });
      if (filteredList) {
        setFavouriteConnectionsList(filteredList);
        setTotalFavourites((prev) => prev - 1);
        if (isSearchMode) {
          setCount((prev) => prev - 1);
        }
      }
    }
  };
  const renderConnectUserCardShimmer = (length = 12) =>
    Array.from({length: length}).map((_, index) => (
      <ConnectUserCardShimmer key={"000" + index} />
    ));

  const handleLoadMore = () => {
    if (nextUrl) {
      if (nextUrl === lastFetchedUrl.current) {
        // If the nextUrl is the same as the last fetched one, return early
        return;
      }
      // Update the last fetched URL
      lastFetchedUrl.current = nextUrl;

      setFetchingMoreData(true);
      makeNextPageCall({url: nextUrl})
        .then((res) => {
          if (res?.next) {
            setNextUrl(res.next);
          } else {
            setNextUrl(null);
          }
          /*  if(isSearchMode){
              setSearchData((prev) => [...prev, ...res.results]);
            }else{ */
          setFavouriteConnectionsList((prev) => [...prev, ...res.results]);
          // }
          setFetchingMoreData(false);
        })
        .catch((err) => {
          showToast(err, "error");
        });
    }
  };

  const getFavouriteConnections = (url = apiConstants.get_my_favourites) => {
    const params = {limit, offset , version:"v2"};
    const mapParams = search ? {search: search} : {};
    makeGetCall({
      url,
      params: showMap
        ? mapParams
        : search
        ? {search: search, ...params}
        : params,
    })
      .then((res) => {
        if (res.status.code === 200) {
          const data = res.results;

          if (!isSearchMode) {
            setTotalFavourites(res.count);
          } else {
            setCount(res.count);
          }
          if (data) {
            setFavouriteConnectionsList(data);
          }
          if (res?.next) {
            setNextUrl(res.next);
          } else {
            setNextUrl(null);
          }

          setIsLoading(false);
        }
      })
      .catch((err: any) => {
        showToast(err, "error");
      });
  };

  const getSearchedSuggestion = () => {
    const params = {
      point: `${userCurrentLocation.long},${userCurrentLocation.lat}`,
      limit: 8,
      search: search,
      version:"v2"
    };
    makeGetCall({
      url: apiConstants.get_my_favourites,
      params,
    })
      .then((res) => {
        if (res.status.code === 200) {
          const data = res.results;
          setSearchData(data);
          setNextUrl(null);
          lastFetchedUrl.current = null;
        }
      })
      .catch((err: any) => {
        showToast(err, "error");
      });
  };
  useEffect(() => {
    scrollToTop();
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setUserCurrentLocation({
            lat: position.coords.latitude,
            long: position.coords.longitude,
          });
          // getFavouriteConnections();
        },
        (err) => {
          showToast(
            "We kindly ask for your location to enhance your app experience; however, we regret to inform you that without this information, we are unable to proceed further.",
            "error",
            "connect"
          );
        }
      );
    } else {
      showToast(
        "It looks like geolocation is not available in your browser.",
        "error"
      );
    }
  }, []);

  useEffect(() => {
    if (search !== "") {
      setIsSearchMode(true);
      getSearchedSuggestion();
    }
    if (search === "") {
      setIsSearchMode(false);
      getFavouriteConnections();
    }
  }, [search]);

  return (
    <div style={{width: "100%"}}>
      {!isMobile && (
        <div className="connect-page-heading font_f">{`Favourites (${totalFavourites})`}</div>
      )}
      {(favouriteConnectionsList.length !== 0 || isSearchMode) && (
        <SearchFilter
          showMap={showMap}
          handleViewToggle={handleViewToggle}
          handleSearchChange={handleSuggestionSearch}
          serachData={serachData}
          handleSeeAll={handleSearchedSeeAllClick}
          handleClearSearch={handleClearClick}
        />
      )}
      {isSearchMode && (
        <div
          style={{
            fontSize: "14px",
            lineHeight: "24px",
            fontWeight: "500",
            margin: "21px 0px 12px 0px",
          }}
        >{`Search results (${count})`}</div>
      )}
      {!showMap ? (
        favouriteConnectionsList.length === 0 && !isLoading ? (
          isSearchMode ? (
            <NoSearchedFilter searchTerm={search} />
          ) : (
            <NoFavourites />
          )
        ) : (
          <div>
            <div className="content-title"></div>
            <div className="content-style">
              {!isLoading &&
                favouriteConnectionsList?.map((connection) => (
                  <ConnectUserCard
                    connectionData={connection}
                    key={connection.id}
                    mode={CONNECTION_TYPE.FAVOURITES}
                    isScrollable={false}
                    handleUnfavouriteClick={handleUnfavouriteClick}
                  />
                ))}
              {fetchingMoreData && nextUrl && renderConnectUserCardShimmer()}
              {isLoading && renderConnectUserCardShimmer(8)}
              {!isLoading && !fetchingMoreData && nextUrl && (
                <LoadOnScroll
                  nextUrl={nextUrl}
                  onLoadMore={handleLoadMore}
                  loaderComponent={false}
                />
              )}
            </div>
          </div>
        )
      ) : (
        <div className="inner-contet">
          <div className="content-title"></div>
          <ConnectMapContainer
            eventMap={favouriteConnectionsList}
            latitude={userCurrentLocation.lat}
            longitude={userCurrentLocation.long}
            mode={CONNECTION_TYPE.FAVOURITES}
          />
        </div>
      )}
    </div>
  );
};

export default ConnectFavourites;
