import React, { useEffect, useState } from "react";
import GroupDetailsMemberItem from "./GroupDetailsMemberItem";
import { makeGetCall } from "../../utils/Requests";
import endpoints from "../../utils/endpoints";
import { showToast } from "../../utils";
import { Room_type } from "../../utils/Constants";

const GroupDetails = ({
  isMobile,
  openChat,
  setShowGroupDetails,
  setFlagModalOpen,
  setShowExitGroupModal,
  setShowDeleteChatModal,
  roomId,
  flagUserData,
  setFlagUserData,
  chatStatus,
}: any) => {
  const [showOptions, setShowOptions] = useState(-1);
  const [groupDetailsData, setGroupDetailsData] = useState<any>(null);

  useEffect(() => {
    makeGetCall({ url: `${endpoints.room_details}/${roomId}` })
      .then((res) => {
        setGroupDetailsData(res?.data);
      })
      .catch((err) => {
        showToast(err, "error");
      });
  }, [roomId]);

  function isVideo(data) {
    return /\.(mp4|mov|avi)$/.test(data?.toLowerCase());
  }

  return (
    <div
      className={`main-section-left${openChat && isMobile ? "-hidden" : ""}`}
    >
      <div className="group-details-heading">
        <img
          alt=""
          src="/static/arrow-back.svg"
          style={{ cursor: "pointer", position: "absolute", left: 5 }}
          onClick={() => {
            setShowGroupDetails(false);
          }}
        />
        Group Details
      </div>
      <div
        className="group-details-container"
        style={{
          justifyContent: "center",
          alignItems: "center",
          display: "flex",
          marginTop: 24,
          flexDirection: "column",
        }}
      >
        <div className="group-details-chat-wrap">
          {groupDetailsData?.roomType === Room_type.L || groupDetailsData?.roomType === Room_type.E ? (
            <img
                alt=""
                src={groupDetailsData?.roomImage?.[0]?.media || groupDetailsData?.roomImage?.[0]?.profilePicture || "/static/connect/avatar.svg"}
                className="user-img"
              />
          ) : (
            groupDetailsData?.roomImage?.map((item, index) => {
              return(
                <img
                alt=""
                src={item?.media || item?.profilePicture || "/static/connect/avatar.svg"}
                className={`user-img-${index + 1}`}
              />
              )
            })
          )}
        </div>
        <div className="group-details-name">{groupDetailsData?.name}</div>
        <div className="member-count">
          {groupDetailsData?.participants?.length} listers
        </div>
      </div>

      <div className="group-details-members">
        <div className="heading">View Listers</div>
        {groupDetailsData?.participants?.map((t, index) => {
          return (
            <GroupDetailsMemberItem
              setShowOptions={setShowOptions}
              showOptions={showOptions}
              setFlagModalOpen={setFlagModalOpen}
              setFlagUserData={setFlagUserData}
              flagUserData={flagUserData}
              item={t}
              index={index}
            />
          );
        })}
      </div>
      <div className="button-container">
        {!(chatStatus === "CCM" || groupDetailsData?.status === "CCM") && (
          <div
            className="option"
            onClick={() => {
              setShowExitGroupModal(true);
            }}
          >
            <img
              style={{ marginRight: 10 }}
              src="/static/ExitRedIcon.svg"
              alt=""
            />
            Exit Group
          </div>
        )}
      </div>
    </div>
  );
};

export default GroupDetails;
