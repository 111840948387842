import * as React from "react";
import dayjs, { Dayjs } from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { MobileTimePicker } from "@mui/x-date-pickers";
import { InputAdornment, TextField } from "@mui/material";
import AccessTimeIcon from "@mui/icons-material/AccessTime";

export default function SingleTimePicker({
  label = "",
  name = "",
  watch = (props: any) => null,
  handleSetValue = (key: any, val: any) => null,
  onOpen = () => null,
  onDone = () => null,
  focused = true,
  defaultValue = null,
  placeholder = "",
  error = false,
  minTime = null,
  shouldDisableTime = null,
}) {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <MobileTimePicker
        name={name}
        value={watch(name) ? dayjs(watch(name)) : null}
        onAccept={(newValue) => {
          const adjustedValue = dayjs(newValue).isBefore(minTime)
            ? minTime.toISOString()
            : newValue.toISOString();

          handleSetValue(name, adjustedValue);
          onDone();
        }}
        // defaultValue={defaultValue || dayjs()}
        sx={{
          width: "100%",
          fontFamily: "Open Sans !important",
        }}
        minTime={minTime}
        format="hh:mm A"
        shouldDisableTime={shouldDisableTime}
        label={
          <span>
            {error && (
              <span>
                <img
                  style={{
                    width: "14px",
                    height: "14px",
                    marginRight: "5px",
                  }}
                  src="/static/common/warning.svg"
                  alt=""
                />
              </span>
            )}
            <span>{label}</span>
          </span>
        }
        onOpen={() => {
          onOpen();
        }}
        onClose={() => {
          onDone();
        }}
        minutesStep={1}
        slots={{
          textField: (props) => (
            <TextField
              {...props}
              focused={focused}
              sx={{
                width: "100%",
                ".MuiInputLabel-root": {
                  fontFamily: "Open Sans !important",
                  color: "#green !important",
                  "&.Mui-focused": {
                    fontSize: "0.93rem",
                    color: error ? "#FF3B30 !important" : "#25272D !important",
                  },
                },
                ".MuiOutlinedInput-root": {
                  borderRadius: "10px",
                  "& fieldset": {
                    border: error ? "1px solid #FF3B30" : "1px solid #DCDCDC",
                  },
                  "&:hover fieldset": {
                    border: error ? "1px solid #FF3B30" : "1px solid #DCDCDC",
                  },
                  "&.Mui-focused fieldset": {
                    border: error ? "1px solid #FF3B30" : "1px solid #DCDCDC",
                  },
                },
                ".MuiInput-input": {
                  fontFamily: "Open Sans !important",
                  fontSize: "16px",
                  fontWeight: 500,
                  lineHeight: "24px",
                  padding: "13px !important",
                  borderRadius: "10px !important",
                  color: "#25272D",
                },
                ".MuiInput-input:focus": {
                  outline: "none",
                  borderRadius: "10px !important",
                },
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end" sx={{ cursor: "pointer" }}>
                    <AccessTimeIcon
                      sx={{
                        color: error ? "#FF3B30" : "#EE7830",
                      }}
                    />
                  </InputAdornment>
                ),
                placeholder: placeholder || "hh:mm",
              }}
            />
          ),
        }}
        slotProps={{
          layout: {
            sx: {
              color: "#000000",
              borderRadius: "6px",
              borderWidth: "0px",
              borderColor: "transparent",
              border: "none",
              ".MuiPickersDay-root:hover": {
                backgroundColor: "rgba(238, 120, 48, 0.2)",
                color: "#000000",
              },
              ".MuiPickersDay-root.Mui-selected": {
                backgroundColor: "rgba(238, 120, 48, 1) !important",
                color: "#ffffff !important",
                fontWeight: "600",
              },
              ".MuiPickersDay-root:focus ": {
                backgroundColor: "transparent",
              },
              ".MuiPickersDay-root.MuiPickersDay-today:not(.Mui-selected)": {
                color: "#000000",
                border: "1px solid #EE7830",
              },
              ".MuiPickersDay-root.MuiPickersDay-today.Mui-disabled:not(.Mui-selected)":
                {
                  color: "rgba(0, 0, 0, 0.38)",
                },
              ".MuiDialogActions-root .MuiButton-root.MuiButton-text": {
                color: "rgba(238, 120, 48, 1)",
                "&:hover": {
                  background: "rgba(238, 120, 48, 0.1)",
                },
              },
              ".MuiClock-pin": {
                backgroundColor: "rgba(238, 120, 48, 1)",
              },
              ".MuiClockPointer-root": {
                backgroundColor: "rgba(238, 120, 48, 1)",
              },
              ".MuiClockPointer-thumb": {
                backgroundColor: "rgba(238, 120, 48, 1)",
                borderColor: "rgba(238, 120, 48, 1)",
              },
            },
          },
        }}
      />
    </LocalizationProvider>
  );
}
