import {
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  styled,
} from "@mui/material";
import React, { Fragment } from "react";
import DialogModal from "../../../DialogModal";
import Button from "../../../../global/Button";
import DatesPreviewCard from "./DatesPreviewCard";
import "./style.scss";

type Props = {
  open: boolean;
  onClose: any;
  data: any;
};

const CustomDialogTitle = styled(DialogTitle)({
  fontFamily: "Open sans, sans-serif",
  fontSize: "20px",
  fontWeight: 600,
  lineHeight: "44px",
  textAlign: "center",
  color: "#25272D",
  borderBottom: "1px solid #6C6D6F",
  padding: "20px 56px",
  position: "relative",
  display: "flex",
  alignItems: "center",
  "& .title_text": {
    paddingLeft: "44px",
    width: "calc(100% - 44px)",
  },
  "& .close_modal_icon": {
    cursor: "pointer",
  },
  ".close_modal_resp_icon": {
    display: "none",
    cursor: "pointer",
  },
  "@media screen and (max-width:768px)": {
    padding: "20px",
    "& .title_text": {
      paddingLeft: "0px",
      paddingRight: "24px",
      width: "calc(100% - 24px)",
    },
    ".close_modal_icon": {
      display: "none",
    },
    ".close_modal_resp_icon": {
      display: "flex",
    },
  },
});

const PreviewScheduleDates = ({
  open = false,
  onClose = () => null,
  data = [],
}: Props) => {
  return (
    <DialogModal
      open={open}
      onClose={onClose}
      disableBackdropClick
      maxWidth={"1084px"}
    >
      <Fragment>
        <CustomDialogTitle>
          <img
            className="close_modal_resp_icon"
            src="/static/arrow/backArrowGrey.svg"
            alt=""
            onClick={onClose}
          />
          <div className="title_text">{"Scheduled listing preview"}</div>
          <img
            className="close_modal_icon"
            src="/static/close/closePrimary.svg"
            alt=""
            onClick={onClose}
          />
        </CustomDialogTitle>

        <DialogContent className="preview_schedule_dates_dialog_content">
          <Grid
            container
            rowSpacing={{ xs: 1, sm: 2 }}
            columnSpacing={{ xs: 1, sm: 1.5 }}
            columns={20}
          >
            {data?.map((val, index) => (
              <Fragment key={index}>
                <DatesPreviewCard data={val} />
              </Fragment>
            ))}
          </Grid>
        </DialogContent>

        <DialogActions className="custom_dialog_action">
          <Button
            btnTxt="Close"
            style={{
              maxWidth: "350px",
            }}
            onClick={onClose}
          />
        </DialogActions>
      </Fragment>
    </DialogModal>
  );
};

export default PreviewScheduleDates;
