import AddIcon from "@mui/icons-material/Add";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import VerifiedIcon from "@mui/icons-material/Verified";

export const MUI_ICONS = {
  Add: ({ active = false, size = "24px", ...others }) => (
    <AddIcon
      style={{
        color: active ? "#EE7830" : "#25272D",
        fontSize: size,
      }}
      {...others}
    />
  ),
  ArrowForward: ({ active = false, size = "24px", ...others }) => (
    <ArrowForwardIosIcon
      style={{
        color: active ? "#EE7830" : "#6C6D6F",
        fontSize: size,
      }}
      {...others}
    />
  ),
  MemberOrangeTick: ({ sx = {} }) => (
    <VerifiedIcon
      sx={{
        position: "absolute",
        bottom: "8px",
        right: "8px",
        color: "#EE7830",
        fontSize: "30px",
        animation: "fadeInOut 1s infinite alternate",
        "@keyframes fadeInOut": {
          from: { scale: 0.5 },
          to: { scale: 1 },
        },
        ...sx,
      }}
    />
  ),
};
