import React, { useMemo } from "react";
import { apiConstants } from "../../../utils/Constants";
import { LABELS, STRING_CONSTANTS } from "../../../utils/StringConstant";
import InputField from "../../../global/InputField";
import CustomNumberInput from "../../CustomNumberInput";

const AgePreferenceSection = React.memo(
  ({
    watch,
    handleSetValue,
    control,
  }: {
    watch: (field: string) => any;
    handleSetValue: (field: string, value: any) => void;
    control: any;
  }) => {
    const isSpecificAge = watch(apiConstants.is_specific_age);
    const minAge = watch(apiConstants.min_age);

    const radioButtons = useMemo(() => {
      return [
        {
          checked: !isSpecificAge,
          label: STRING_CONSTANTS.all_age_listing,
          onClick: () => handleSetValue(apiConstants.is_specific_age, false),
        },
        {
          checked: isSpecificAge,
          label: STRING_CONSTANTS.specific_age_listing,
          onClick: () => handleSetValue(apiConstants.is_specific_age, true),
        },
      ];
    }, [isSpecificAge, handleSetValue]);

    return (
      <>
        <div className="main-had" style={{ marginTop: "24px" }}>
          <h3 className="headis">{STRING_CONSTANTS.age_prefrence}</h3>
        </div>
        <div className="all-radio-button mb_24">
          {radioButtons.map((radio, index) => (
            <div key={index} className="form_radio_button">
              <img
                className="cursor-pointer"
                src={
                  radio.checked
                    ? "/static/radiocheck.svg"
                    : "/static/radiouncheck.svg"
                }
                alt=""
                onClick={radio.onClick}
              />
              <label
                className={`form-check-label cursor-pointer ${
                  radio.checked ? "active" : ""
                }`}
                onClick={radio.onClick}
              >
                {radio.label}
              </label>
            </div>
          ))}
        </div>
        {isSpecificAge && (
          <div className="two-row no_cross ageFieldWrap mb-3">
            <CustomNumberInput
              name={apiConstants.min_age}
              watch={watch}
              handleSetValue={handleSetValue}
              // min={1}
              max={99}
              placeholder={LABELS.MIN_AGE}
              label={LABELS.MIN_AGE}
            />
            <CustomNumberInput
              name={apiConstants.max_age}
              watch={watch}
              handleSetValue={handleSetValue}
              // min={1}
              max={99}
              placeholder={LABELS.MAX_AGE}
              label={LABELS.MAX_AGE}
              isDisabled={!minAge}
              />
          </div>
        )}
      </>
    );
  }
);

export default AgePreferenceSection;
