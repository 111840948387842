import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import GlobalHeader from "../Dashboard/GlobalHeader";
import { EventListingType, EventListingTypeGuest } from "../../utils/Constants";
import { LISTING_TYPE } from "../../types";
import DraftCard from "./DraftCard";
import InfiniteScroll from "react-infinite-scroll-component";
import ApiLoader from "../../global/ApiLoader";
import { getItemFromStore, showToast } from "../../utils";
import {
  makeDeleteCall,
  makeGetCall,
  makeNextPageCall,
} from "../../utils/Requests";
import endpoints from "../../utils/endpoints";
import Button from "../../global/Button";
import moment from "moment";
import Notavailable from "../../assets/img/Nodataimg.svg";
import GlobalHeader2 from "../Dashboard/GlobalHeader2";
import { ROUTE_CONSTANTS } from "../../utils/RouteConstants";

const Drafts = () => {
  const Navigate = useNavigate();
  const [listingType, setListingType] = useState(LISTING_TYPE.S);
  const [eventListingType, setEventListingType] = useState(
    getItemFromStore("userProfile")?.userType !== "Guest"
      ? EventListingType
      : EventListingTypeGuest
  );
  const [draftList, setDraftList] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [nextUrl, setNextUrl] = useState<string | null>(null);
  const [showDeletePopup, setShowDeletePopup] = useState<boolean>(false);
  const [deleteId, setDeleteId] = useState<string | null>(null);

  const getDraftData = () => {
    setDraftList([]);
    const params = { type: listingType , version:"v2" };
    setIsLoading(true);
    makeGetCall({ url: endpoints.draft_listing, params })
      .then((res) => {
        if (res.status.code === 200) {
          setDraftList(res.results);
          setNextUrl(res.next || null);
        } else {
          showToast(res.status.message, "error");
        }
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        showToast(err, "error");
      });
  };

  const deleteDraftItem = () => {
    if (deleteId) {
      makeDeleteCall({
        url: `${endpoints.listing}${deleteId}?delete_listing=True`,
      })
        .then((res) => {
          if (res.status.code === 200) {
            showToast(res.status.message, "success");
            getDraftData();
          } else {
            showToast(res.status.message, "error");
          }
        })
        .catch((err) => {
          showToast(err, "error");
        });
    }
    setDeleteId(null);
    setShowDeletePopup(false);
  };

  useEffect(() => {
    getDraftData();
  }, [listingType]);

  const fetchMoreEvents = () => {
    if (nextUrl) {
      makeNextPageCall({ url: nextUrl })
        .then((res) => {
          setDraftList((prevDraftList) => [...prevDraftList, ...res.results]);
          setNextUrl(res.next || null);
        })
        .catch((err) => {
          showToast(err, "error");
        });
    }
  };

  return (
    <>
      <GlobalHeader2 />
      <div className="page-draft">
        <div className="wrap-box">
          <div className="title-box">
            <img
              src="/static/leftarrow.svg"
              alt="Back"
              className="back_arrow"
              onClick={() => Navigate(ROUTE_CONSTANTS.create_listing)}
            />
            <h3>Drafts</h3>
          </div>

          <div>
            <ul className="select_listing_type">
              {eventListingType?.map((item: any, index: number) => (
                <li
                  key={index}
                  onClick={() => setListingType(item.type)}
                  className={listingType === item.type ? "active" : ""}
                >
                  <img
                    src={
                      listingType === item.type
                        ? item.inactiveImg
                        : item.activeImg
                    }
                    alt={item.title_draft}
                  />
                  <p className="mt-1">{item.title_draft}</p>
                </li>
              ))}
            </ul>
          </div>

          {/* Conditionally render this section */}
          {draftList.length === 0 && (
            <div className="Notavailabledata-section">
              <img
                className="Notavailableimg"
                src={Notavailable}
                alt="No data"
              />
              <div className="description">
                <div>
                  <span className="description-title">
                    No drafts to show yet
                  </span>
                </div>
                <div>
                  <span className="description-details">
                    There are no drafts to show here
                  </span>
                </div>
              </div>
            </div>
          )}

          <div className="tooltip-box">
            <div className="info_box">
              <img src="/static/greyinfo.svg" alt="Info" />
              <div>
                <div className="tip-title">
                  You can only access drafts created within the past 30 days.
                  <br />
                </div>
                <div className="tip-subtitle">
                  You can only edit one draft listing at a time.
                </div>
                <div className="tip-subtitle">
                  Please publish or discard this listing before creating another
                  draft.
                </div>
              </div>
            </div>
          </div>

          <div className="card-list-results">
            <InfiniteScroll
              dataLength={draftList.length}
              next={fetchMoreEvents}
              hasMore={!!nextUrl}
              loader={<ApiLoader />}
            >
              {isLoading ? (
                <div className="page_loader">
                  <ApiLoader />
                </div>
              ) : (
                <div className="listings">
                  {draftList.map((item: any, index: number) => (
                    <DraftCard
                      key={item.id}
                      onClick={() => Navigate(`/edit-listing/${item.id}`)}
                      title={item.title}
                      extension={item.extension}
                      url={item?.imageThumbnail || item.image}
                      date={moment(item.createdAt).format("ll")}
                      onDeleteClick={(e) => {
                        e.stopPropagation();
                        setDeleteId(item.id);
                        setShowDeletePopup(true);
                      }}
                    />
                  ))}
                </div>
              )}
            </InfiniteScroll>
          </div>

          {showDeletePopup && (
            <div className="modal show">
              <div className="modal-section log_out_modal">
                <div className="modal-top-part">
                  <h5>Are you sure?</h5>
                </div>
                <div className="button_option">
                  <Button
                    btnTxt="Delete"
                    className="backtologin"
                    onClick={deleteDraftItem}
                  />
                  <Button
                    btnTxt="Cancel"
                    className="backtologin cancel"
                    onClick={() => setShowDeletePopup(false)}
                  />
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default Drafts;
