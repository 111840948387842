import React from "react";
import { apiConstants } from "../../../utils/Constants";
import { getListingTypeDescription } from "../../../constants/helperScheduleDates";
import { Props } from "./EventCardSection";

export const EventCardSimple = ({
  item, handleEventEditClick, onDeleteEvent, index, watch,
}: Props) => {

  return (
    <div className="simple_card_wrap" onClick={handleEventEditClick}>
      <div className="header_card">
        <div className="heading_1">{item.title}</div>
        <div className="action_card">
          <img
            src="/static/createListingIcon/editPencil.svg"
            alt=""
            onClick={(e) => {
              e.stopPropagation();
              handleEventEditClick(item, index);
            }} />
          <img
            src="/static/createListingIcon/deleteTheme.svg"
            alt=""
            onClick={(e) => {
              e.stopPropagation();
              onDeleteEvent(index);
            }} />
        </div>
      </div>

      {watch(apiConstants.listing_event_type) !== "V" &&
        item?.address && (
          <div className="body_1">
            <img src="/static/createListingIcon/location.svg" alt="" />
            <div>{item?.address}</div>
          </div>
        )}

      {watch(apiConstants.listing_event_type) === "V" &&
        watch(apiConstants.multiple_virtual_links_allowed) === true &&
        watch(apiConstants.virtual_links_single) && (
          <div className="body_1">
            <img src="/static/createListingIcon/linkGrey.svg" alt="" />
            <div>{watch(apiConstants.virtual_links_single) || "-"}</div>
          </div>
        )}

      <div className="d-flex gap-4 align-items-center">
        <div className="body_1">
          {getListingTypeDescription(item?.schedulePackage)}
        </div>
      </div>
    </div>
  );
};
