import { useEffect, useRef, useState } from "react";
import { connectSidebarData, FITNESSMATES_REQUEST } from "../ConnectConstants";
import IconMenuExpand from "../../../assets/img/connectIcons/icon-arrow-down.png";
import { Link, useLocation } from "react-router-dom";
import { UseClickOutside } from "../../Dashboard/Event/UseClickOutside";

const ConnectDropdownMenu = ({ connectUserProfile, isOpen=false, setIsOpen }) => {
  // const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(connectSidebarData[0]);
  const menuBarRef = useRef();
  const [overlayOpacity, setOverlayOpacity] = useState(0);
  const location = useLocation();
  const closeDropdown = () => setIsOpen(false);
  UseClickOutside(menuBarRef,closeDropdown);
  const toggleDropdown = () => {
    setIsOpen(!isOpen);
    setOverlayOpacity(isOpen ? 0 : 0.8);
  };

  const handleSelect = (item: any) => {
    setIsOpen(false);
    setOverlayOpacity(isOpen ? 0 : 0.8);
    setSelectedOption(() => item);
  };
  const renderPendingRequestCount = () => {
    return (
      <div className="request-count">
        <span className="count-number">
          {connectUserProfile.pendingConnectionRequests}
        </span>
      </div>
    );
  };

  useEffect(() => {
    let pathName = location.pathname;
    if(pathName){
      const activeTab = connectSidebarData.find((item) => item.path === pathName);
      setSelectedOption(activeTab);

    }
    
  }, [location]);
  return (
    <div className="dropdown-top-bar" ref={menuBarRef}>
      {/* <div
        className="overlay"
        style={{ opacity: overlayOpacity }}
      ></div> */}
      <div className="top-bar" onClick={toggleDropdown} >
        <div style={{ display: "flex", alignItems: "center" }}>
          <div className="menu-icon" style={{ position: "relative" }}>
            <img
              src={selectedOption?.activeIcon}
              className="icon-sidebar"
              height="24px"
              width="24px"
              alt=""
            />
            {selectedOption?.title === FITNESSMATES_REQUEST &&
              connectUserProfile?.pendingConnectionRequests !== 1 &&
              renderPendingRequestCount()}
          </div>

          <span className="selected-title font_f">{selectedOption?.title}</span>
        </div>
        <div className="icon-menu-expand">
          <img
            src={IconMenuExpand}
            className="icon-sidebar"
            height="6.5"
            width="12px"
            alt=""
          />
        </div>
      </div>

      {isOpen && (
        <div className="dropdown-content">
          {connectSidebarData.map((data) => {
            return (
              <div
                key={data.title}
                onClick={() => handleSelect(data)}
                className="dropdown-item"
              >
                <Link to={data.path} style={{ textDecoration: "none" }}>
                  {" "}
                  <span
                    style={{
                      height: "20px",
                      width: "20px",
                      position: "relative",
                    }}
                  >
                    {" "}
                    <img
                      src={data.icon}
                      className="icon-sidebar"
                      height="20px"
                      width="20px"
                      alt=""
                    />{" "}
                    {data.title === FITNESSMATES_REQUEST &&
                      connectUserProfile?.pendingConnectionRequests !== 0 &&
                      renderPendingRequestCount()}
                  </span>
                  <span className="menu-title font_f"> {data.title}</span>{" "}
                </Link>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default ConnectDropdownMenu;
