import React from "react";
import { useNavigate } from "react-router-dom";
import IconHeart from "../../../assets/img/connectIcons/heart.png";
import { shortenName } from "../ConnectConstants";

const FavCard = ({ connectionData }) => {
  const navigate = useNavigate();
  const handleRedirection = () => navigate(`/our-mates/${connectionData?.id}`);
  return (
    <div className="fav-card-wrap" >
      <div className="fav-img-wrap" onClick={handleRedirection}>
        {connectionData?.profilePictureExtension?.includes("video") ? (
          <video
            autoPlay
            playsInline
            loop
            muted
            poster={connectionData?.thumbnail || undefined }
            src={ connectionData?.profilePictureThumbnail || connectionData?.profilePicture}
            className="profile-img-style vid"
          >
            <source src={connectionData?.profilePictureThumbnail || connectionData?.profilePicture } type="video/mp4" />
          </video>
        ) : (
          <img
            src={connectionData?.profilePictureThumbnail || connectionData?.profilePicture || "/static/dummyimg.svg" }
            className="profile-img-style"
            alt="profile-img"
          />
        )}
        <img src={IconHeart} style={{position:"absolute", top:11.45, right:6 }}/>
      </div>
      <div className="fav-text font_os" onClick={handleRedirection}>{shortenName(connectionData.name , 10)}</div>
    </div>
  );
};

export default React.memo(FavCard);
