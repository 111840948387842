import React, { useState, useCallback, useLayoutEffect } from "react";
import Cropper from "react-easy-crop";
import { convertBlobToFile, getCroppedImg } from "./actions";
import PrimaryButton from "../Button/PrimaryButton";
import SecondaryButton from "../Button/SecondaryButton";
import "./cropperStyle.scss";

interface CropperImageProps {
  circular?: boolean;
  square?: boolean;
  rectangle?: boolean;
  cover?: boolean;
  imageFile?: any;
  handleClose?: any;
  setResultImage?: any;
}

const CropperImage: React.FC<CropperImageProps> = ({
  circular = false,
  square = false,
  rectangle = false,
  cover = false,
  imageFile,
  handleClose,
  setResultImage,
}) => {
  const [image, setImage] = useState<string | null>(null);
  const [crop, setCrop] = useState<{ x: number; y: number }>({ x: 0, y: 0 });
  const [zoom, setZoom] = useState<number>(1);
  const [rotation, setRotation] = useState<number>(0);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState<{
    width: number;
    height: number;
    x: number;
    y: number;
  } | null>(null);
  const [croppedImage, setCroppedImage] = useState<any>(null);

  const onCropComplete = useCallback(
    (croppedArea: any, croppedAreaPixels: any) => {
      setCroppedAreaPixels(croppedAreaPixels);
    },
    []
  );

  const handleFileChange = (file) => {
    if (file) {
      const reader = new FileReader();
      reader.onload = () => setImage(reader.result as string);
      reader.readAsDataURL(file);
    }
  };

  useLayoutEffect(() => {
    handleFileChange(imageFile);
  }, []);

  const handleCrop = async () => {
    try {
      if (image && croppedAreaPixels) {
        const croppedImage = await getCroppedImg(
          image,
          croppedAreaPixels,
          rotation
        );
        setCroppedImage(croppedImage);
      }
    } catch (e) {
      console.error(e);
    }
  };

  const handleSave = async (croppedFile) => {
    const file = convertBlobToFile(croppedFile, "cropped_file.jpeg");

    setResultImage(file);
    setTimeout(() => {
      handleClose();
    }, 0);
  };

  const cropShape = circular ? "round" : "rect";
  const aspect = square ? 1 : rectangle ? 2 / 1 : cover ? 4 / 1 : 1;

  return (
    <div className="modal show">
      <div
        className="modal-section zoom_cropper_modal_wrap"
        style={{ padding: "0" }}
      >
        <div className="zoom_cropper_inner_wrap">
          {image && !croppedImage && (
            <div className="zoom_cropper_image_container">
              <Cropper
                image={image}
                crop={crop}
                zoom={zoom}
                rotation={rotation}
                aspect={aspect}
                cropShape={cropShape}
                onCropChange={setCrop}
                onZoomChange={setZoom}
                onCropComplete={onCropComplete}
              />
            </div>
          )}

          {croppedImage && (
            <div className="zoom_cropper_image_container">
              <img
                src={URL.createObjectURL(croppedImage)}
                alt="Cropped"
                style={{
                  borderRadius: circular ? "50%" : "0",
                  maxHeight: "100%",
                }}
              />
            </div>
          )}

          <div className="zoom_action_wrap">
            <SecondaryButton
              onClick={() => {
                if (croppedImage) {
                  setCroppedImage(null);
                } else {
                  handleClose();
                }
              }}
              sx={{
                minWidth: {
                  xs: "100%",
                  sm: "180px",
                },
              }}
            >
              {croppedImage ? "Back" : "Cancel"}
            </SecondaryButton>
            <PrimaryButton
              onClick={() => {
                if (croppedImage) {
                  handleSave(croppedImage);
                } else {
                  handleCrop();
                }
              }}
              sx={{
                minWidth: {
                  xs: "100%",
                  sm: "180px",
                },
              }}
            >
              {croppedImage ? "Save" : "Crop"}
            </PrimaryButton>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CropperImage;
