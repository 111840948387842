import React, { useMemo } from "react";
import moment from "moment";
import { ACTIVITY_TYPE, apiConstants } from "../../../utils/Constants";
import { DATE_FORMAT } from "../../../constants/helperScheduleDates";
import InputField from "../../../global/InputField";
import InfoCard from "../InfoCard";
import GooglePlacesSearchBar from "../../../global/GooglePlaceSelector";
import Button from "../../../global/Button";
import { HEADINGS, LABELS, MESSAGE } from "../../../utils/StringConstant";

const PackageAddEventSidebar = React.memo(
  ({
    showAddEventSidebar,
    setShowAddEventSidebar,
    setEditEvent,
    editEvent,
    setValue,
    control,
    watch,
    setPackageLocation,
    handleLocation,
    setMultipleScheduleListing,
    handleSetValue,
    isLocationAccessible,
    setIsLocationAccessible,
    handleUpdateOrSaveEvent,
  }: {
    showAddEventSidebar: boolean;
    setShowAddEventSidebar: (value: boolean) => void;
    setEditEvent: (value: boolean) => void;
    editEvent: boolean;
    setValue: (field: string, value: any) => void;
    control: any;
    watch: any;
    setPackageLocation: (location: any) => void;
    handleLocation: Function;
    setMultipleScheduleListing: (value: boolean) => void;
    handleSetValue: (field: string, value: any) => void;
    isLocationAccessible: boolean;
    setIsLocationAccessible: (value: boolean) => void;
    handleUpdateOrSaveEvent: any;
  }) => {
    // Memoized reset handler
    const handleReset = useMemo(
      () => () => {
        setShowAddEventSidebar(false);
        setEditEvent(false);
        const resetFields = [
          "event_title",
          "city",
          "state",
          "country",
          "postCode",
          "address",
          "listing_location",
          "event_start_date",
          "event_start_time",
          "event_end_time",
          "event_end_date",
          "schedule_date",
          "event_date",
          "virtual_links_package",
        ];
        resetFields.forEach((field) => setValue(field, ""));
        setPackageLocation(null);
      },
      []
    );

    // Memoized schedule date content
    const scheduleDateContent = useMemo(() => {
      const eventDate = watch(apiConstants.event_date);
      const startTime = watch(apiConstants.event_start_time);
      const endTime = watch(apiConstants.event_end_time);

      if (eventDate) {
        return (
          <>
            <p>
              {moment(eventDate, DATE_FORMAT.DATE_MONTH_FORMAT).format(
                "MMMM Do, YYYY"
              )}
            </p>
            <small>{`${moment(startTime).format("hh:mm A")} - ${moment(
              endTime
            ).format("hh:mm A")}`}</small>
          </>
        );
      }

      return (
        <p style={{ color: "#8F939A" }}>Schedule your event date and time.</p>
      );
    }, [watch]);

    if (!showAddEventSidebar) return null;

    return (
      <div className="fixed-sidebar-left">
        <div className="sidebar-box-event">
          <div className="event-title">
            <img
              src="/static/cross-black.svg"
              onClick={handleReset}
              className="close-btn d-none d-md-block"
              alt="Close"
            />
            <span
              className="d-md-none"
              onClick={() => setShowAddEventSidebar(false)}
              style={{ cursor: "pointer" }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
              >
                <path
                  d="M8.73464 16.7917C9.03557 17.0763 9.51026 17.0631 9.79489 16.7621C10.0795 16.4612 10.0663 15.9865 9.76536 15.7019L4.52632 10.7468L17.25 10.7468C17.6642 10.7468 18 10.411 18 9.99678C18 9.58256 17.6642 9.24678 17.25 9.24678L4.52405 9.24678L9.76536 4.28952C10.0663 4.00489 10.0795 3.53021 9.79489 3.22927C9.51026 2.92834 9.03557 2.91512 8.73464 3.19974L2.31741 9.26918C2.15112 9.42647 2.05112 9.62658 2.01741 9.83537C2.00601 9.88736 2 9.94136 2 9.99678C2 10.0544 2.00649 10.1104 2.01878 10.1643C2.05377 10.3701 2.15331 10.567 2.31741 10.7222L8.73464 16.7917Z"
                  fill="#25272D"
                />
              </svg>
            </span>
            <div className="filter-heading pe-3">
              {editEvent ? HEADINGS.UPDATE_EVENT : HEADINGS.ADD_EVENT}
            </div>
          </div>

          <div className="inner-contet">
            <InputField
              type="text"
              className="floating_input mb-4"
              name={apiConstants.event_title}
              inputLabel="Event Name"
              placeholder="Type event name"
              control={control}
              errors={{}}
              setValue={setValue}
            />

            {watch(apiConstants.listing_event_type) === ACTIVITY_TYPE.VIRTUAL &&
              watch(apiConstants.multiple_virtual_links_allowed) === false && (
                <>
                  <InputField
                    className="floating_input mb-3"
                    type="text"
                    name={apiConstants.virtual_links_package}
                    inputLabel="Add Meeting URL"
                    placeholder="Enter URL"
                    control={control}
                    errors={{}}
                    setValue={setValue}
                    // style={{ marginBottom: "12px" }}
                  />
                  <InfoCard title={MESSAGE.WEB_URLS_REQUIRED} />
                </>
              )}

            <div className="mb-4">
              {watch(apiConstants.listing_event_type) ===
                ACTIVITY_TYPE.PHYSICAL && (
                <GooglePlacesSearchBar
                  setIsLocationAccessible={setIsLocationAccessible}
                  isLocationAccessible={isLocationAccessible}
                  hideMap={true}
                  onChangeLocation={(
                    lat: string,
                    lng: string,
                    address: any,
                    address_components: any
                  ) => {
                    const location = handleLocation(
                      lat,
                      lng,
                      address,
                      address_components
                    );
                    setPackageLocation(address);
                    handleSetValue(apiConstants.address, address);
                    handleSetValue(apiConstants.lat, lat);
                    handleSetValue(apiConstants.lng, lng);
                    handleSetValue(apiConstants.postCode, location.zipcode);
                    handleSetValue(apiConstants.state, location.state);
                    handleSetValue(apiConstants.city, location.locality);
                    handleSetValue(apiConstants.country, location.country);
                  }}
                  setValue={setValue}
                  handleLocation={handleLocation}
                  control={control}
                  name={apiConstants.address}
                  label={LABELS.SEARCH_LOCATION}
                  placeholder={LABELS.INCLUDE_LOCATION}
                />
              )}
            </div>
          </div>

          <div className="form_group with-button">
            <div className="input_box">
              <span className="input_label">Schedule Dates</span>
              <div
                className="schedule_date_input"
                onClick={() => {
                  setShowAddEventSidebar(false);
                  setMultipleScheduleListing(true);
                }}
              >
                {scheduleDateContent}
              </div>
            </div>
          </div>

          <div>
            <Button
              btnTxt={editEvent ? HEADINGS.UPDATE_EVENT : HEADINGS.ADD_EVENT}
              className="save_btn d-none d-md-block"
              onClick={handleUpdateOrSaveEvent}
            />
            <Button
              btnTxt={editEvent ? HEADINGS.UPDATE_EVENT : HEADINGS.ADD_EVENT}
              className="d-md-none"
              onClick={handleUpdateOrSaveEvent}
            />
          </div>
        </div>
      </div>
    );
  }
);

export default PackageAddEventSidebar;
