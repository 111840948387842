import React from "react";
import laugh from "../../assets/img/signupWelcome/laugh.svg";
import checked from "../../assets/img/signupWelcome/checked.svg";
import dismiss from "../../assets/img/signupWelcome/dismiss.svg";
import dumbbell from "../../assets/img/signupWelcome/dumbbell_new.svg";
type Props = {
  compareData?: any;
  guestData?: any;
  memberData?: any;
};

const ResponsiveCompareTable = ({
  compareData = [],
  guestData = null,
  memberData = null,
}: Props) => {
  return (
    <div className="resp_table">
      <div className="header_resp">
        <div className="guest_heading">
          <img src={laugh} alt="" />
          Browsers
        </div>
        <div className="special member_heading guest_heading">
          <img src={dumbbell} alt="" />
          Lister
        </div>
      </div>
      <div className="container_comparison">
        {compareData.map((item, id) => (
          <div className="content_comparison">
            <div className="text-center name_resp">{item.name}</div>
            <div className="row justify-content-between">
              <div className="col-4 text-center feature">
                {guestData?.hasOwnProperty(item.key) ? (
                  guestData[item.key] === true ? (
                    <img src={checked} alt="Yes" />
                  ) : guestData[item.key] === false ? (
                    <img src={dismiss} alt="No" />
                  ) : (
                    guestData[item.key]
                  )
                ) : (
                  "-"
                )}
              </div>
              <div className="col-4 text-center feature">
                {memberData?.hasOwnProperty(item.key) ? (
                  memberData[item.key] === true ? (
                    <img src={checked} alt="Yes" />
                  ) : memberData[item.key] === false ? (
                    <img src={dismiss} alt="No" />
                  ) : (
                    memberData[item.key]
                  )
                ) : (
                  "-"
                )}
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
export default ResponsiveCompareTable;
