import React, { useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import useDebounce from "../../../utils/useDebounce";
import { showToast } from "../../../utils";
import { makeGetCall } from "../../../utils/Requests";
import apiConfig from "../../../utils/endpoints";
import CardView from "../../../assets/img/icons/CardView";
import ListView from "../../../assets/img/icons/ListView";
import Button from "../../../global/Button";
import LoadingLayer from "../../ConnectUserProfile/LoaderLayer";
import { LeftArrowStickIcon } from "../../../utils/Icons/svgs";
import "./style.scss";
import { BUTTON_TEXT } from "../../../utils/StringConstant";

type Props = {
  [key: string]: any;
};

const SelectInterestComponentFilter = (props: Props) => {
  const { interestPicked, handleAdd, handleBackClick } = props;
  const [isLoading, setIsLoading] = useState<any>(false);
  const [searchData, setSearchData] = useState("");
  const debouncedSearchTerm = useDebounce(searchData, 500);
  const [selectedInterest, setSelectedInterest] = useState<any>([...interestPicked]);
  const [isListView, setIsListView] = useState(false);
  const [filter, setFilter] = useState("A");
  const [interestList, setInterestList] = useState([]);

  const getInterestList = () => {
    setIsLoading(true);

    let params = { want_paginated: false };

    if (filter === "F") {
      params["featured"] = true;
    }

    if (searchData) {
      params["search"] = searchData;
    }

    makeGetCall({ url: apiConfig.interest_list, params })
      .then((res) => {
        setInterestList(res?.data);

        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        showToast(err, "error");
      });
  };

  useEffect(() => {
    getInterestList();
  }, [debouncedSearchTerm, filter]);

  const handleFilterData = (type = "all") => {
    setFilter(type);
  };

  const handleIntrest = (intrest: any) => {
    let index = selectedInterest.findIndex(
      (intr: any) => intr.id === intrest.id
    );
    if (index > -1) {
      selectedInterest.splice(index, 1);
    } else {
      selectedInterest.push(intrest);
    }

    setSelectedInterest([...selectedInterest]);
  };

  const handleNext = () => {
    handleAdd(selectedInterest);
  };

  const ListViewData = () => {
    return (
      <div className="interest_list_wrapper">
        {interestList?.map((val) => (
          <div
            className={`interest_list ${
              selectedInterest.some((intr: any) => intr.id === val.id)
                ? "selected"
                : ""
            }`}
            onClick={() => {
              handleIntrest(val);
            }}
          >
            <div>
              <img
                className="list_image"
                src={val?.icon || "/static/handball.svg"}
                alt=""
              />
            </div>
            <div className="list_title">{val?.title || ""}</div>
          </div>
        ))}
      </div>
    );
  };
  const CardViewData = () => {
    return (
      <div className="interest_data_wrapper">
        {interestList?.map((val) => (
          <div
            className={`interest_cards ${
              selectedInterest.some((intr: any) => intr.id === val.id)
                ? "selected"
                : ""
            }`}
            onClick={() => {
              handleIntrest(val);
            }}
          >
            <div>
              <img
                className="card_image"
                src={val?.image || "/static/dance.png"}
                alt=""
              />
            </div>
            <div className="card_title">{val?.title || ""}</div>
          </div>
        ))}
      </div>
    );
  };

  return (
    <div
      className="interest_container_full"
      style={{
        padding: isMobile ? "10px" : "0px",
      }}
    >
      {isLoading && <LoadingLayer />}
      <div style={{ display: "flex" }}>
        <div
          className="map-back-icon"
          style={{ height: "24px", width: "24px", cursor: "pointer" }}
          onClick={handleBackClick}
        >
          <LeftArrowStickIcon />
        </div>
        <div
          className="heading_listing_interest font_f"
          style={{
            margin: "0px",
            display: "flex",
            justifyContent: "center",
            width: "100%",
          }}
        >
          Add Interest for your listing
        </div>
      </div>
      <div className="bi_wrapper">
        <div className="bi_container">
          <div className="search_header">
            <div className="search_input_container">
              <div className="search_input_wrapper">
                <div className="search_label">Search Interests</div>
                <input
                  className="input_search_text"
                  type="text"
                  value={searchData}
                  placeholder="Search your interests"
                  onChange={(e) => {
                    setSearchData(e.target.value);
                  }}
                />
                {searchData && (
                  <img
                    src="/static/cross-circle-gray-dark.svg"
                    className="clear_data"
                    alt=""
                    onClick={() => setSearchData("")}
                  />
                )}
              </div>
            </div>
            <div className="search_view">
              <CardView
                active={!isListView}
                className="cursor-pointer"
                onClick={() => setIsListView(false)}
              />
              <ListView
                active={isListView}
                className="cursor-pointer"
                onClick={() => setIsListView(true)}
              />
            </div>
          </div>
          <div className="filter_container">
            <Button
              btnTxt={BUTTON_TEXT.ALL}
              onClick={() => {
                handleFilterData("A");
              }}
              className={`all_filter_btn ${filter !== "A" ? "inactive" : ""}`}
            />

            <Button
              btnTxt={BUTTON_TEXT.FEATURED}
              onClick={() => {
                handleFilterData("F");
              }}
              className={`all_filter_btn ${filter !== "F" ? "inactive" : ""}`}
            />
          </div>
          <div className="body_interest">
            {interestList?.length < 1 && (
              <div style={{ textAlign: "center" }}>No interest found!</div>
            )}
            {isListView ? ListViewData() : CardViewData()}
          </div>

          <div className="btn_footer footer_button_filter_unique">
            <Button
              style={{ maxWidth: "550px" }}
              btnTxt={BUTTON_TEXT.ADD}
              className={"btn_continue"}
              isLoading={isLoading}
              onClick={handleNext}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SelectInterestComponentFilter;
