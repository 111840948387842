import React, { useEffect, useRef, useState } from "react";
import {
  GoogleMap,
  Marker,
  useJsApiLoader,
  MarkerClusterer,
} from "@react-google-maps/api";
import { googleKey } from "../../../utils/Constants";
import { showToast } from "../../../utils";
import { getGeolocation } from "../../../constants/helperConnectUser";
import MapViewMultiCardLayout from "./MapViewMultiCardLayout";

const ConnectMapContainer = ({ eventMap, latitude, longitude, mode }) => {
  const [showMultipleEvent, setShowMultipleEvent] = useState(false);
  const [multipleEventData, setMultipleEventData] = useState([]);

  const mapRef = useRef(null);
  const [zoom, setZoom] = useState(8);
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: googleKey,
  });
  const [, setMap] = React.useState(null);
  const [center, setCenter] = useState<any>({
    lat: -31.397,
    lng: 151.644,
  });

  const [cordNew, setCordsNew] = useState([]);
  const [enableSelfMarker, setEnableSelfMarker] = useState(true);

  const onLoad = React.useCallback(function callback(map) {
    setMap(map);
    mapRef.current = map;
  }, []);

  const onUnmount = React.useCallback(function callback(map) {
    setMap(null);
    mapRef.current = null;
  }, []);

  // Function to calculate zoom level to fit 50 meters from top
  /* const calculateZoomLevel = (distanceMeters) => {
  const earthCircumference = 40008000; // Earth's circumference in meters
  const pixelsPerMeter = 256 / (2 * Math.PI * (6378137)); // Meters per pixel at zoom level 0
  const metersPerPixel = pixelsPerMeter * Math.pow(2, 10); // Adjust zoom level here
  return Math.log2(earthCircumference / (distanceMeters * metersPerPixel));
}; */

  const fetchCurrentLocation = async (isRequest = false) => {
    try {
      const userLocationData: any = await getGeolocation();
      if (userLocationData?.currentLat && userLocationData?.currentLng) {
        setCenter({
          lat: userLocationData?.currentLat,
          lng: userLocationData?.currentLng,
        });
        // const zoomLevel = calculateZoomLevel(500);
        setZoom(20);
        if (mapRef.current) {
          mapRef?.current?.setZoom(18);
          setZoom(18); // Update the zoom state
        }
      } else {
        if (!userLocationData?.isLocationAccessible && isRequest) {
          showToast(
            "User's current location is not available.",
            "error",
            "current_location"
          );
        }
      }
    } catch (error) {
      showToast(
        "We kindly ask for your location to enhance your app experience; however, we regret to inform you that without this information, we are unable to proceed further.",
        "error"
      );
    }
  };

  useEffect(() => {
    let temp_array = [];
    let temp_storage = [];
    setCenter({
      lat: latitude || -25.397,
      lng: longitude || 134.644,
    });

    /* in eventMap we are getting all data, like nearby, interes based , expert and the other suggestion.
     A suggestion can be in multiple category so to filter out for unique data set; */
    const UNIQ_SUGGESTION_DATA = Array.from(
      new Set(eventMap?.map((obj: any) => JSON.stringify(obj)) || [])
    )?.map((str: any) => JSON.parse(str));

    UNIQ_SUGGESTION_DATA?.map((item: any, index: any) => {
      if (item.location) {
        const str = item.location;
        const coordinates = str
          .replace("SRID=4326;POINT (", "")
          .replace(")", "");
        const [longitude, latitude] = coordinates
          .split(" ")
          ?.map((coord: any) => parseFloat(coord));
        if (temp_storage.includes(longitude + "-" + latitude)) {
          let temp_item = { ...item };
          var lat = longitude;
          var lng = latitude;
          temp_item.coordinates = [lat, lng];
          temp_array.push(temp_item);
        } else {
          temp_storage.push(longitude + "-" + latitude);
          let temp_item = { ...item };
          var lat = longitude;
          var lng = latitude;
          temp_item.coordinates = [lat, lng];
          temp_array.push(temp_item);
        }
      }
    });
    setCordsNew([...temp_array]);
    fetchCurrentLocation();
  }, [latitude, eventMap, longitude]);

   const handleClusterClick = (cluster) => {
     const markers = cluster.getMarkers();
     const markerData = markers?.map((marker) => marker.get("customData")); 
     setMultipleEventData(markerData);
     setTimeout(() => {
      setShowMultipleEvent(true)
     }, 0);
   };

   const handleSingleClusterClick = (item) => {
     setMultipleEventData([item]);
     setTimeout(() => {
       setShowMultipleEvent(true);
     }, 0);
   };

  return (
    <>
      {isLoaded ? (
        <>
          <div
            style={{ position: "relative" }}
            className="events-map-container"
          >
            <div
              className="create-listing-btn-2"
              style={{
                position: "absolute",
                bottom: "30px",
                display: "flex",
                justifyContent: "flex-end",
                right: "0",
                left: "0",
                margin: "0 auto",
                width: "300px",
                // width: "fit-content",
                zIndex: "10",
                cursor: "pointer",
              }}
              onClick={() => fetchCurrentLocation(true)}
            >
              <img src="/static/GoCurrentLocation.svg" alt="" />
            </div>
            <GoogleMap
              mapContainerStyle={{
                width: "100%",
                height: "calc(100vh - 100px)",
              }}
              zoom={zoom}
              onLoad={onLoad}
              onUnmount={onUnmount}
              center={center}
              options={{
                mapTypeControl: false,
                fullscreenControl: false,
                zoomControl: true,
                panControl: false,
                rotateControl: false,
                streetViewControl: false,
                zoomControlOptions: {
                  position: google.maps.ControlPosition.TOP_LEFT,
                },
                minZoom: 2,
                maxZoom: 18,
              }}
            >
              <MarkerClusterer
                minimumClusterSize={3}
                onClick={handleClusterClick}
                options={{ zoomOnClick: false }}
              >
                {(clusterer) => (
                  <div>
                    {enableSelfMarker && (
                      <Marker
                        key={"selft"}
                        position={{
                          lat: center.lat,
                          lng: center.lng,
                        }}
                        icon={{
                          url: "static/CurentMarker.svg",
                          scaledSize: new window.google.maps.Size(36, 36),
                        }}
                      />
                    )}
                    {cordNew?.map(
                      (item, index) =>
                        item.coordinates && (
                          <Marker
                            clusterer={clusterer}
                            key={index}
                            position={{
                              lat: item.coordinates[1],
                              lng: item.coordinates[0],
                            }}
                            onClick={() => {
                              handleSingleClusterClick(item)
                            }}
                            icon={{
                              url: "/static/chat-theme-2.svg",
                              scaledSize: new window.google.maps.Size(36, 36),
                            }}
                            onLoad={(marker) => {
                              marker.set("customData", item);
                            }}
                          />
                        )
                    )}
                  </div>
                )}
              </MarkerClusterer>
            </GoogleMap>

            {showMultipleEvent && (
              <MapViewMultiCardLayout
                multipleEventData={multipleEventData}
                mode={mode}
                show={showMultipleEvent}
                hide={setShowMultipleEvent}
              />
            )}

          </div>
        </>
      ) : (
        <div>Loading....</div>
      )}
    </>
  );
};

export default ConnectMapContainer;
