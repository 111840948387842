import React from "react";
import { CrossIcon, ErrorInfo } from "../../../utils/Icons/svgs";

export default function VerifyEmailAlert({ handleVerifyClick = () => {} }) {
  return (
    <div className="infoguestlisting emailAlertWrap">
      <div className="dl-email-verified_listing verifyEmailAlert">
        <div className="d-flex gap-2 align-items-center">
          <div>
            <ErrorInfo />
          </div>
          <div>
            <p className=" dl-title mb-0">
              You need to verify your email to proceed further.
            </p>
            <p
              className="dl-subtitle mb-0 cursor-pointer"
              onClick={() => handleVerifyClick()}
            >
              Click here to verify your email.
            </p>
          </div>
        </div>
        {/* <div
          className="cursor-pointer"
          onClick={() => {
            // setGuestInfoVisibility(false);
          }}
        >
          <CrossIcon width="16" height="16" fill="white" />
        </div> */}
      </div>
    </div>
  );
}
