import moment from "moment";
import { SpClockIcon } from "../../utils/Icons/svgs";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { showToast } from "../../utils";

export default function PackageBookingCard({ item , isLinkVisible=false, customClass= '' }) {

  const renderVirtualLinks = () => {
    return (
      <div className="content-items" onClick={(e) => e.stopPropagation()}>
        <div
          className="open-maps-box-booking-detail d-flex gap-2"
          style={{
            cursor: "pointer",
          }}
        >
          <img alt="" src="/static/meet.svg" />
          <span
            className="meet-link"
            onClick={(e) => {
              e.stopPropagation();
              window.open(item.virtualLink);
            }}
          >
            Meet Link
          </span>
          <CopyToClipboard
            onClick={(e) => e.stopPropagation()}
            onCopy={() => showToast("Text copied!", "success")}
            text={item.virtualLink}
          >
            <img alt="" src="/static/copyblue.svg" className="copy-btn" />
          </CopyToClipboard>
        </div>
      </div>
    );
  };
  return (
    <div className={`sp_date_container_new col-12 ${customClass ? customClass : "col-md-5"} package-new-card position-relative flex-grow-0 ` + customClass}>
      <div className="em_date_first sp_info">
        <div className="em_actual_date sp_title">
          <div className="mb-0">
            {moment(item?.date).format("dddd")}, {moment(item?.date).date()}{" "}
            {moment(item?.date).format("MMMM")}
          </div>
        </div>
        {/* <hr /> */}

        {isLinkVisible && renderVirtualLinks()}

        <div className={"sp_info sp_info_new"}>

          <div className="sp_time flex-row">
            <span className="d-sm-none d-inline">
              <img src="/static/Calendar22.svg" />{" "}
              {moment(item?.date).format("DD MMM, YYYY")}
            </span>
            {"  "} <SpClockIcon />{" "}
            {moment(item?.startTime?.substring(0, 5), ["HH:mm"]).format(
              "hh:mmA"
            )}
            -{" "}
            {moment(item?.endTime?.substring(0, 5), ["HH:mm"]).format("hh:mmA")}
          </div>
        </div>
      </div>
    </div>
  );
}
