import {useEffect, useState} from "react";
import {Link, useLocation, useNavigate} from "react-router-dom";
import moment from "moment";
import {
  getItemFromStore,
  isUserAuthenticated,
  notAValidUser,
  setLocalStorage,
  showToast,
} from "../../utils";
import {ROUTE_CONSTANTS} from "../../utils/RouteConstants";
import {STRING_CONSTANTS} from "../../utils/StringConstant";
import {USER_TYPE} from "../../types";
import useCheckDeviceScreen from "../../hooks/useCheckScreen";
import {useAppSelector} from "../../store/store";
import { SVG_ICON } from "../../assets/svg";
import apiConfig from "../../utils/endpoints";

const GlobalHeader2 = (props: any) => {
  const auth = useAppSelector((state) => state.auth);
  const navigate = useNavigate();
  const location = useLocation();
  const {isMobile} = useCheckDeviceScreen();
  const [profileImage, setProfileImage] = useState("");
  const [profileImageExtension, setProfileImageExtension] = useState("");
  const [showMenu, setShowMenu] = useState(false);
  const [unReadNotificationCount, setUnreadNotificationCount] =
    useState<any>(null);

  // for unread chat count
  const [unreadChatCount, setIsUnreadChatCount] = useState(0);
  const headerOptions = [
    {path: ROUTE_CONSTANTS.landing, label: "Browse Events"},
    {path: ROUTE_CONSTANTS.connect, label: "Meet Mates"},
    {path:ROUTE_CONSTANTS.CMS_HOW_ITS_WORKS, label:"How it works"},
    {path: ROUTE_CONSTANTS.promotion, label: "Promotions & News"},
  ];

  useEffect(() => {
    window.onpopstate = () => {
      let currentPath = window.location.pathname;
      if (
        currentPath === ROUTE_CONSTANTS.manage_profile ||
        currentPath === ROUTE_CONSTANTS.upload_document ||
        currentPath === ROUTE_CONSTANTS.business_intrest ||
        currentPath === ROUTE_CONSTANTS.manage_expertise
      ) {
        navigate(ROUTE_CONSTANTS.dashboard);
      }
    };
  }, [navigate]);

  useEffect(() => {
    let decoUserData: any;
    // setUserNameRes(auth?.moreUserDetails?.name);
    setUnreadNotificationCount(auth?.moreUserDetails?.unreadNotificationCount);
    if (auth?.moreUserDetails?.unreadMessagesCount > 0) {
      setIsUnreadChatCount(auth?.moreUserDetails?.unreadMessagesCount);
    }
    if (auth) {
      let data = {
        ...auth.moreUserDetails,
        ...auth.businessDetail?.[0],
      };

      const pathname = window.location.pathname;
      if (data) {
        //Just added fot avoiding redirection

        if (pathname === "/user-profile") {
          navigate(ROUTE_CONSTANTS.user_profile);
        } else if (pathname === "/events") {
          navigate(ROUTE_CONSTANTS.events);
        } else if (pathname.startsWith("/events/")) {
          navigate(pathname, {replace: true});
        } else if (!data.name) {
          if (window.location.pathname !== ROUTE_CONSTANTS.welcome) {
            // navigate(ROUTE_CONSTANTS.manage_profile);
          }
        } else {
          decoUserData = {
            ...data,
            businessContactNumber: data.businessContactNumber?.replace("+", ""),
            dob: moment(data.dob, "YYYY-MM-DD")?.toDate(),
          };

          setProfileImage(decoUserData?.profilePicture);
          setProfileImageExtension(decoUserData?.profilePictureExtension);
          setLocalStorage("userProfile", decoUserData);
        }
      }
    } else {
      showToast(STRING_CONSTANTS.wrong_data_message, "error");
    }
  }, [props.isUserProfileChange, auth]);

  /*   useEffect(() => {
    setUnreadNotificationCount(
      getItemFromStore("userProfile")?.unreadNotificationCount
    );
  }, [getItemFromStore("userProfile")]); */

  useEffect(() => {
    const user_token = localStorage.getItem("access_token");
    if (!user_token) {
      return;
    }

    const ws = new WebSocket(
      `${apiConfig.webSocketUrl}ws/unread-messages/?user=${JSON.parse(
        user_token
      )}`
    );

    ws.onopen = () => {
      // on open operation
    };

    // Event listener for incoming messages
    ws.onmessage = (event) => {
      try {
        const data = JSON.parse(event.data);
        console.log('data: ', data);
        if (data.unread_messages_count !== undefined) {
          setIsUnreadChatCount(data?.unread_messages_count || 0);
        }
      } catch (error) {
        console.error("Error parsing WebSocket message:", error);
      }
    };

    // Event listener for errors
    ws.onerror = (error) => {
      console.error("WebSocket error:", error);
    };

    ws.onclose = () => {
      // on close operation
    };

    return () => {
      ws.close();
    };
  }, []);

  return (
    <div className="header-container ">
      <div className=" new-header-container">
        <div className="new-header">
          <div className="'left-logo d-none d-md-block">
            <Link to={ROUTE_CONSTANTS.landing}>
              <img src="/static/fitness-logo.svg" alt="" />
            </Link>
          </div>
          <div className=" d-md-none">
            <Link to={ROUTE_CONSTANTS.landing}>
              <img src="/static/logo_small.svg" alt="" />
            </Link>
          </div>
          <div className="middle-bar">
            <div className={`menus d-none d-md-block`}>
              <ul>
                {headerOptions?.map((option) => (
                  <li key={option.label}>
                    <Link
                      to={option.path}
                      className={
                        location.pathname === option.path
                          ? "active font_f"
                          : "inactive font_f"
                      }
                    >
                      {option.label}
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
          </div>
          <div className="right-bar ">
            {(getItemFromStore("userProfile")?.userType === USER_TYPE.GUEST ||
              !isUserAuthenticated()) &&
              !isMobile && (
                <Link
                  to={ROUTE_CONSTANTS.CMS_BECOME_A_LISTER}
                  style={{textDecoration:"none"}}
                  >
                <div
                  className="join-as-a-member"
                >
                  Become a Lister
                </div>
                </Link>
              )}
            {getItemFromStore("userProfile")?.userType === USER_TYPE.GUEST &&
              !isMobile && (
                <div
                  style={{
                    height: "33px",
                    border: "1px solid #D9D9D9",
                    borderRadius: "20px",
                    marginInline: "10px 20px",
                  }}
                ></div>
              )}
            {/* for chat icon */}
            {isUserAuthenticated() && (
              <div className="d-none d-md-block notification-icon">
                <Link to={ROUTE_CONSTANTS.chat}>
                  {location.pathname === ROUTE_CONSTANTS.chat ? (
                    <SVG_ICON.ChatPrimary />
                  ) : (
                    <SVG_ICON.ChatSecondary />
                  )}
                </Link>
                {unreadChatCount > 0 && (
                  <img
                    alt=""
                    src="/static/unreaddot.svg"
                    className="active-dot"
                  />
                )}
              </div>
            )}
            {isUserAuthenticated() && (
              <div
                className="notification-icon cursor-pointer"
                onClick={() => navigate(ROUTE_CONSTANTS.notifications)}
              >
                {location.pathname === ROUTE_CONSTANTS.notifications ? (
                  <SVG_ICON.AlertPrimary />
                ) : (
                  <SVG_ICON.AlertSecondary />
                )}
                {unReadNotificationCount > 0 && (
                  <img
                    alt=""
                    src="/static/unreaddot.svg"
                    className="active-dot"
                  />
                )}
              </div>
            )}
            {!isUserAuthenticated() && (
              <div className="login-signup-wrapper">
                <button
                  className="login-btn font_f"
                  onClick={() => navigate(ROUTE_CONSTANTS.login)}
                >
                  Login
                </button>
                <button
                  className="sign-up-btn font_f"
                  onClick={() => navigate(ROUTE_CONSTANTS.register)}
                >
                  Sign up
                </button>
              </div>
            )}

            {isUserAuthenticated() && window.location.pathname !== "/policy" ? (
              <div className="icon-menu ">
                <ul>
                  <li className={`menu-des d-none d-md-block`}>
                    <div
                      className="inner"
                      onClick={() =>
                        isUserAuthenticated()
                          ? navigate(ROUTE_CONSTANTS.personal_details)
                          : notAValidUser(navigate)
                      }
                    >
                      <img
                        src="/static/navigation2.png"
                        className="menu-icon"
                        alt=""
                      />
                      {profileImageExtension?.includes("video") ? (
                        <video
                          className="profile"
                          autoPlay
                          playsInline
                          loop
                          muted
                          src={profileImage}
                        >
                          <source src={""} type="video/mp4" />
                        </video>
                      ) : (
                        <img
                          src={profileImage || "/static/dummyimg.svg"}
                          className="profile"
                          alt=""
                        />
                      )}
                    </div>
                  </li>

                  <li className="menu-des  d-md-none">
                    <div
                      className="inner"
                      onClick={() =>
                        isUserAuthenticated()
                          ? navigate(ROUTE_CONSTANTS.profile_menu)
                          : setShowMenu(true)
                      }
                    >
                      <img
                        onClick={() =>
                          isUserAuthenticated()
                            ? navigate(ROUTE_CONSTANTS.profile_menu)
                            : setShowMenu(true)
                        }
                        src="/static/navigation2.png"
                        className="menu-icon"
                        alt=""
                      />
                      {profileImageExtension?.includes("video") ? (
                        <video
                          className="profile"
                          autoPlay
                          playsInline
                          loop
                          muted
                          src={profileImage}
                          onClick={() =>
                            isUserAuthenticated()
                              ? navigate(ROUTE_CONSTANTS.profile_menu)
                              : setShowMenu(true)
                          }
                        >
                          <source src={""} type="video/mp4" />
                        </video>
                      ) : (
                        <img
                          src={profileImage || "/static/dummyimg.svg"}
                          className="profile"
                          alt=""
                          onClick={() =>
                            isUserAuthenticated()
                              ? navigate(ROUTE_CONSTANTS.profile_menu)
                              : setShowMenu(true)
                          }
                        />
                      )}
                    </div>
                  </li>
                </ul>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default GlobalHeader2;
